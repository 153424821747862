import { Injectable } from '@angular/core';
import {Autoregister, Service} from 'ngx-jsonapi';
import {Professor} from '../models/professor';

@Injectable({
  providedIn: 'root'
})
 // @Autoregister()
export class ProfessorsService  extends Service<Professor> {
  public resource = Professor;
  public type = 'professors';


  constructor() {
    super();
    this.register();
  }
}
