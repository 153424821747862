<a
  style="cursor: pointer;"
  [routerLink]="['modules',index]"
  routerLinkActive="active"
  class="list-group-item clearfix">
  <div class="pull-left">
    <h4 class="list-group-item-heading">{{ module.id }}</h4>
    <h4 class="list-group-item-text">{{ module.attributes.title }}</h4>
    <p class="list-group-item-text">{{ module.attributes.title }}</p>
  </div>
</a>

