<mat-form-field class="example-full-width">
  <input #textInput type="text"
         placeholder="Pick one"
         aria-label="Number"
         matInput
         name="user"
         [(ngModel)]="textValue"
         [matAutocomplete]="auto"
  >
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addSelection($event)">
    <mat-option *ngFor="let option of options" [value]="option">
      {{name(option)}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<div>
  <div *ngFor="let user of selected">{{name(user)}}
    <button mat-button (click)="remove(user)">remove</button>
  </div>
</div>
<button *ngIf="selected.length" (click)="create()"  mat-raised-button>Create Channel</button>
