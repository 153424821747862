<!--<app-header></app-header>
<div class="container">
  <div class="row">
    <div class="col-md-10">
    </div>
    <div class="sidebar">
      <app-sidebar></app-sidebar>
    </div>
  </div>
</div>
<app-footer></app-footer>-->
<router-outlet></router-outlet>


<div id="myForm" *ngIf="show; else button" class="related">

    <ng-templat *ngIf="channel!=null">
        <div class="reduce">

            <p style="color:#1da9c2;display: flex;"><img class="size" src="/assets/img/chatbulle.png " alt="">
                <span style="margin-left: 5px; font-size: 20px;margin-top: 4px;">Chat Support</span>
            </p>
            <i class="fas fa-minus matta" aria-hidden="true" (click)="closeForm()" data-toggle="tooltip" data-placement="top" title="Réduire"></i>
            <i *ngIf="conversationEndedForHotline" (click)="restart()" class="fas fa-plus matta" data-toggle="tooltip" data-placement="top" title="Commancer un nouveau sujet"></i>
        </div>
        <app-chat-window-support class=" chat-box " [channel]="channel "></app-chat-window-support>
    </ng-templat>

    <ng-templat *ngIf="channel==null " class="notyet ">
        <div class="row align-items-center justify-content-center " style="margin-top:20% ">
            <img src="/assets/img/chatbulle.png " style="height:60px ">

        </div>
        <div class="row align-items-center justify-content-center " style="margin-top:4% ">
            <h5 style='color:white;font-size: 18px;'>Chat Support Hotline</h5>
        </div>
        <div class="row align-items-center justify-content-center " style="margin-top:12% ">
            <div class="form-group ">
                <input type="text " class="form-control " id="subject " name="subject " [(ngModel)]="subject " placeholder="Votre sujet ici " style="padding-left: 20px;padding-right: 20px;text-align: center; ">
            </div>
        </div>
        <div class="row align-items-center justify-content-center " style="margin-top:4% ">

            <button (click)="createTicket() " type="button " class="btn btn-primary " style="background-color: #1da9c2; padding:5px;border-color: #1da9c2; ">
                <span *ngIf="creating " class="spinner-border spinner-border-sm "  style="color: white "></span>
                Commancer le chat
            </button>
        </div>
        <div class="row align-items-center justify-content-center ">
            <a (click)="openForm()" style="cursor:pointer;font-size: 12px; ">Fermer la fenêtre</a>
        </div>
    </ng-templat>

</div>
<ng-template #button>
    <button class="open-button  " (click)="openForm() "><img  class="siz " src="/assets/img/chatbulle.png "/> {{'Chat with us'| translate}}</button>
</ng-template>


<!--<app-lesson-list></app-lesson-list>-->

<!--<app-modulelist></app-modulelist>-->
