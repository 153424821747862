import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Channel} from 'twilio-chat/lib/channel';
import {TwilioV2Service} from '../../../services/twilio-v2.service';
import {ActivatedRoute} from '@angular/router';
import {filter, switchMap} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-channels-list',
  templateUrl: './channels-list.component.html',
  styleUrls: ['./channels-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChannelsListComponent implements OnInit {

  channels: Channel[];
  @Input() selected = null;
  @Output() itemClicked = new EventEmitter();
  loading = true;

  constructor(
    private twilioV2Service: TwilioV2Service,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {

  }

  ngOnInit(): void {
    this.twilioV2Service
      .channels.subscribe(list => {
      this.loading = false;
      this.channels = list.sort(function (a, b) {return b.dateUpdated - a.dateCreated;});
      this.changeDetectorRef.markForCheck();
    });

    this.route.paramMap
      .pipe(
        filter(params => params.has('id')),
        switchMap(
          params => {
            return this.twilioV2Service.getPrivateChannel(params.get('id'));
          }
        )).subscribe(channel => {
      this.selected = channel;
      this.itemClicked.emit(this.selected);
      this.changeDetectorRef.markForCheck();
    }, err => {
      console.error('cannot fetch channel', err);
      //this.toastr.error(marker('Cannot fetch selected discussion thread'));
    });
  }

}
