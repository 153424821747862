<ng-container *ngIf="messages">
  <div class="tail-flex tail-p-4  tail-flex-col-reverse tail-overflow-scroll"
       style="height: 90%; scroll-behavior: smooth"
       #box>
    <ng-container *ngFor="let message of messages">
      <app-message
        [children]="null"
        [message]="message">
      </app-message>
    </ng-container>
  </div>
  <div class="tail-flex">
    <mat-form-field (keyup.enter)="send(box); " class="tail-w-3/4" style="height: 10%">
      <input matInput name="message" [(ngModel)]="message">
    </mat-form-field>
    <button mat-raised-button class="tail-w-1/4" (click)="send(box)">Send</button>
  </div>


</ng-container>
