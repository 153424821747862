import {Resource, DocumentCollection, DocumentResource} from 'ngx-jsonapi';
import {Document} from './document';
import {Podcast} from './podcast';
import {Video} from './video';
import {Exam} from './exam';
import {Test} from './test';
import {GroupWork} from "./group-work";

export class File extends Resource{
  public attributes = {
    name: '',
    typefile: '',
    url: '',
    size: '',
    isFavorite: ''
};
  public relationships = {
    document : new DocumentResource<Document>(),
    podcast : new DocumentResource<Podcast>(),
    video : new DocumentResource<Video>(),
    exam : new DocumentResource<Exam>(),
    test : new DocumentResource<Test>(),
    groupWork : new DocumentResource<GroupWork>(),
  };
}
