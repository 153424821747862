import { Injectable } from '@angular/core';
import {Service} from 'ngx-jsonapi';
import {McqQuestion} from '../models/mcq-question';

@Injectable({
  providedIn: 'root'
})
export class McqQuestionsService extends Service<McqQuestion> {
  public resource = McqQuestion;
  public type = 'mcq-questions';

  constructor() {
    super();
    this.register();
  }
}
