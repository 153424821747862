import {Component, Input, OnInit} from '@angular/core';
import {Module} from '../../../../models/module';
import {ModulesService} from '../../../../services/modules.service';
import {LessonsService} from '../../../../services/lessons.service';

@Component({
  selector: 'app-module-item',
  templateUrl: './module-item.component.html',
  styleUrls: ['./module-item.component.scss']
})
export class ModuleItemComponent implements OnInit {
  module: Module;
  @Input() index: number;
  constructor(private  modulesService: ModulesService, lessonsService: LessonsService) {
    lessonsService.register();
  }

  ngOnInit() {
    this.modulesService.get(String(this.index), {include: ['lessons']}).subscribe(
      module => (this.module = module),
    );
  }

}
