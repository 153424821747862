<mwl-calendar-utils-calendar-header class="calendar-header"
                                    [locale]="currentLang"
                                    [(view)]="view"
                                    [(viewDate)]="viewDate"
                                    (viewDateChange)="eventdata"
                                    (viewChange)="eventdata">
</mwl-calendar-utils-calendar-header>

<ng-template #loading>
  <div class="text-center">
    <i class="fa fa-spin fa-spinner fa-5x"></i> <br/>
    {{"Loading events..."|translate}}
  </div>
</ng-template>

<div *ngIf="eventdata else loading; ">
  <div [ngSwitch]="view">
    <mwl-calendar-month-view
      [locale]="currentLang"
      *ngSwitchCase="'month'"
      [viewDate]="viewDate"
      [events]="eventdata"
      [activeDayIsOpen]="activeDayIsOpen"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="eventClicked($event.event)">
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      [locale]="currentLang"
      *ngSwitchCase="'week'"
      [viewDate]="viewDate"
      [events]="eventdata"
      (eventClicked)="eventClicked($event.event)">
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      [locale]="currentLang"
      *ngSwitchCase="'day'"
      [viewDate]="viewDate"
      [events]="eventdata"
      (eventClicked)="eventClicked($event.event)">
    </mwl-calendar-day-view>
  </div>
</div>
