import {Injectable} from '@angular/core';
import {Autoregister, Service} from 'ngx-jsonapi';
import {Course} from '../models/course';
import {ModulesService} from './modules.service';

@Injectable({
  providedIn: 'root'
})
// @Autoregister()
export class CoursesService extends Service<Course> {
  public resource = Course;
  public type = 'courses';

  constructor(private modulesServices: ModulesService) {
    super();
    this.register();
  }
}
