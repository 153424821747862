import {Component, Inject, OnInit} from '@angular/core';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogModuleComponent} from '../modules/dialog-module/dialog-module.component';

@Component({
  selector: 'app-not-participant-dialog',
  templateUrl: './not-participant-dialog.component.html',
  styleUrls: ['./not-participant-dialog.component.scss']
})
export class NotParticipantDialogComponent implements OnInit {

  message = marker('You\'re not a participant in this course');

  constructor(
    public dialogRef: MatDialogRef<DialogModuleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data?.message) {
      this.message = data?.message;
    }

  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

}
