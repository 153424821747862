import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CoursesService} from '../../../services/courses.service';
import {LessonsService} from '../../../services/lessons.service';
import {ProfessorsService} from '../../../services/professors.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ModulesService} from '../../../services/modules.service';
import {ExamsService} from '../../../services/exams.service';
import {TestsService} from '../../../services/tests.service';
import {GroupWorksService} from '../../../services/group-works.service';
import {Module} from '../../../models/module';
import {Lesson} from '../../../models/lesson';

@Component({
  selector: 'app-dialog-module',
  templateUrl: './dialog-module.component.html',
  styleUrls: ['./dialog-module.component.scss']
})
export class DialogModuleComponent implements OnInit {
  module: Module;

  constructor(
    public dialogRef: MatDialogRef<DialogModuleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    coursesService: CoursesService,
    lessonsService: LessonsService,
    professorsService: ProfessorsService,
    private route: ActivatedRoute,
    private router: Router,
    private modulesService: ModulesService,
    testsService: TestsService,
    groupWorksService: GroupWorksService
  ) {

  }

  ngOnInit(): void {
    //console.log('this is the module id :', this.data.idModule);
    this.modulesService.get(String(this.data.idModule), {include: ['lessons', 'exam', 'tests', 'groupWorks']}).subscribe(
      module => {
        this.module = module;
        //console.log('module :::', this.module.relationships.tests.data.length);
      },
      () => {
        //console.log('tests...');
      },
      () => {
        //console.log('module :::', this.module);
      }
    );
  }

  close() {
    this.dialogRef.close();
  }

  lessons() {
    return this.module?.relationships.lessons.data ?? [];
  }

  exams() {
    return this.module?.relationships.exams.data ?? [];
  }

  tests() {
    return this.module?.relationships.tests.data ?? [];
  }

  groupWorks() {
    return this.module?.relationships.groupWorks.data ?? [];
  }

  type(lesson:Lesson){
    return lesson.type
  }
}
