import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TwilioService} from '../../services/twilio.service';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss']
})
export class ChatBoxComponent implements OnInit {

  private channelList = null;
  selectedChannel = null;
  channelCount = 0;
  constructor(
    private router: Router,
    private twilioService: TwilioService
  ) {
  }

  ngOnInit() {
    this.twilioService.getClient().subscribe((client: any) => {
      client.getPublicChannelDescriptors().then(console.log);
    });



    this.twilioService.getChannel('test').subscribe(console.log, console.error);

    this.fetchChannels();
  }

  private fetchChannels() {
    this.twilioService.channels$.subscribe((res: any) => {
      this.channelCount++;
      if (!res) {
        return;
      }
      this.channelList = res;
    });
  }

  public get channels() {
    if (!this.channelList) {
      return [];
    }
    return this.channelList;

  }




}



