import { Injectable } from '@angular/core';
import {Service} from 'ngx-jsonapi';
import {Podcast} from '../models/podcast';

@Injectable({
  providedIn: 'root'
})
export class PodcastsService extends Service<Podcast> {
  public resource = Podcast;
  public type = 'podcast-lessons';
}
