import { Injectable } from '@angular/core';
import {Service} from 'ngx-jsonapi';
import {Exam} from '../models/exam';
import {QuestionsService} from './questions.service';
import {McqQuestionsService} from './mcq-questions.service';
import {FileQuestion} from '../models/file-question';
import {FileQuestionsService} from './file-questions.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExamsService extends Service<Exam> {
  public resource = Exam;
  public type = 'exams';

  constructor(
    private questionsService: QuestionsService,
    private mcqService: McqQuestionsService,
    private fileQuestionService : FileQuestionsService,
    private httpClient : HttpClient
  ) {
    super();
    this.register();
  }

 
}
