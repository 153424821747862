import {Component, Input, OnInit} from '@angular/core';
import {Course} from '../../models/course';
import {Router} from '@angular/router';

@Component({
  selector: 'app-course-teaser',
  templateUrl: './course-teaser.component.html',
  styleUrls: ['./course-teaser.component.scss']
})
export class CourseTeaserComponent implements OnInit {

  @Input() active = false;
  @Input() course: Course;

  constructor(
    private router: Router
  ) {
  }

  ngOnInit(): void {
  }


  go() {
    if (!this.course) {
      return;
    }

    this.router.navigate(['/courses/' + this.course.id]);
  }


  get modules() {
    return this.course?.relationships.modules.data ?? [];
  }
}
