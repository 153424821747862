import {DocumentCollection, DocumentResource, Resource} from 'ngx-jsonapi';
import {Module} from './module';
import {File} from './file';
import {Question} from './question';
import {McqQuestion} from './mcq-question';
import {FileQuestion} from './file-question';

export class Test  extends Resource {
  public attributes = {
    title: '',
    description: '',
    start_date : '',
    end_date : '',
    rules : '',
    duration: 0,
    typeTest: ''
  };
  public relationships = {
    module: new DocumentResource<Module>(),
    files : new DocumentCollection<File>(),
    questions : new DocumentCollection<Question>(),
    fileQuestions : new DocumentCollection<FileQuestion>(),
    mcqQuestions : new DocumentCollection<McqQuestion>(),
  };
}
