import { Component, OnInit } from '@angular/core';

import {LessonsService} from '../../../services/lessons.service';
import {DocumentCollection} from 'ngx-jsonapi';
import {Lesson} from '../../../models/lesson';
import {PodcastsService} from '../../../services/podcasts.service';
import {VideosService} from '../../../services/videos.service';
import {LiveLessonsService} from '../../../services/live-lessons.service';
import {DocumentsService} from '../../../services/documents.service';
import {FilesService} from "../../../services/files.service";
import {ModulesService} from "../../../services/modules.service";


@Component({
  selector: 'app-lesson-list',
  templateUrl: './lesson-list.component.html',
  styleUrls: ['./lesson-list.component.scss']
})
export class LessonListComponent implements OnInit {
  private lessons: DocumentCollection<Lesson>;

  constructor(private lessonsService: LessonsService, private modulesService: ModulesService,podcastLesson: PodcastsService,
              videoService: VideosService, liveLessonService: LiveLessonsService,
              documentService: DocumentsService, filesService: FilesService) {
    podcastLesson.register();
    videoService.register();
    liveLessonService.register();
    documentService.register();
    filesService.register();
    lessonsService.all({ include: [  'lessonable' ]}).subscribe(
      lessons => (this.lessons = lessons),
      () => {
      },
    () => {
        //console.log('lessons data::, ', lessonsService.get('2',{ include: [  'lessonable.file' ]}));
    }
    );
  }


  ngOnInit(): void {
  }

}
