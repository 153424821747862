<div class="row">
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
            
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            to apply in this course, please login
              <div class="modal-footer">
                  <button type="button" class="btn btn-primary-yes">login</button>
                  <button type="button" class="btn btn-secondary-no" data-dismiss="modal">register</button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>