import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {NotificationsService} from '../../services/notifications.service';
import {LanguageService} from "../../services/language.service";
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  idUser;
  private data;
  private ans;
  notifications;
  public countUnreadNotification;
  notification;
  lang;
  public currentUser;

  constructor(private notificationsService: NotificationsService, private httpClient: HttpClient, private authService: AuthService, private router: Router,
              private languageService: LanguageService,) {
  }

  ngOnInit(): void {

    const url = environment.base_url ;
    this.authService.currentUser
    .pipe(
      switchMap(user => {
        this.currentUser = user;
        //console.log('current user', user);
        return of(user);
        // return this.fetchParticipation();
      })
    ).subscribe(res => {
    //console.log('loaded', res);
  });

    ////console.log("id " ,this.currentUser.id);


    this.httpClient.get<any>(url+'/api/v1/users/'+this.currentUser.id).subscribe(res => {
      this.notifications = this.getNotifications(res.data.attributes.notifications);
      this.countUnreadNotification=this.countRead(this.notifications);
      //console.log("ahwa les notifs jdod" ,this.notifications);

      //console.log("ahwa 9adeh men notif jdida " ,this.countUnreadNotification);

    })
    // this.notificationsService.getNotifications().subscribe(
    //   (data) => (this.data = data),
    //   () => //console.log('error'),
    //   () => {
    //     this.notifications = this.getNotifications(this.data);
    //     this.countUnreadNotification = this.data.countUnread;
    //     //console.log('data events ::', this.data);
    //   }
    // );



  }

  countRead(data)
  {

    let count=0 ;
    for (let notification of data) {
      //console.log("les reead" ,notification.read);
      if (!notification.read)
      {
        count=count +1;
      }
    }
    return count ;
  }
  getNotifications(data) {

    let notifications = [];
    for (let notification of data) {
      ////console.log(notification.seen);


      if (notification.type=="Test")
      {

        notifications.push({
          title: notification.type,
          message :"Un nouveau test est disponibile dans l'unité d'enseignement "+notification.description,
          read: notification.seen,
          id : notification.id ,
          typeid: notification.type_id
        });
      }
      else if (notification.type=="GroupeWork")
      {

        notifications.push({
          title: notification.type,
          message :"Un nouveau groupe de travail est disponibile dans l'unité d'enseignement "+notification.description,
          read: notification.seen,
          id : notification.id ,
          typeid: notification.type_id
        });
      }
      else
      {

        notifications.push({
          title: notification.type,
          message :"L'examen final de l'unité d'enseignement "+notification.description+" est planifié le "+notification.date_begin,
          read: notification.seen,
          id : notification.id ,
          typeid: notification.type_id
        });
      }


    }
    return notifications;
  }

  parseNotification(notification) {
    const lang = this.languageService.getCurrentLanguage()
    return {
      title: notification['data']['category'], message: notification['data']['message'],
    }
  }
  makeAsViewed(event) {

    this.authService.updateNotificationStatus(event.id)
    .subscribe(res => {
        //console.log(res);
       // this.ngOnInit();
       if (event.title=="Test")
       {
         this.router.navigate(['/test/'+parseInt(event.typeid)]);
       }
       else if (event.title=="GroupeWork")
       {
         this.router.navigate(['/group-work/'+parseInt(event.typeid)]);
       }
       else
       {
         this.router.navigate(['/exam/'+parseInt(event.typeid)]);
       }



      },
      err => {
        //console.log(err);
      });



    // this.notificationsService.markNotifAsRead(event.id).subscribe(
    //   (notification) => (this.notification = notification),
    //   () => //console.log('error'),
    //   () => {
    //     //console.log('notiification zzz :: ', this.notification);
    //     if (!event.read) {
    //       event.read = true;
    //       this.countUnreadNotification = this.countUnreadNotification - 1;
    //     }
    //     this.redirectTo(this.notification);
    //   }
    // );
  }

  redirectTo(notification: {}) {
    let notif = notification['notification'];
    switch (notif['data']['type']) {
      case 'course':
        this.router.navigate(['courses', notif['data']['course_id']]);
        break;
      case 'lesson':
        this.router.navigate(['courses', notif['data']['course_id'], 'modules',
          notif['data']['module_id'], 'lessons', notif['data']['lesson_id']]);
        break;
      case 'evaluation':
        //console.log('test our evalution route............');
        this.router.navigate([notif['data']['evaluation_type'], notif['data']['eval_id']]);
        break;
      case 'result':
        this.router.navigate(['profile/my-results/course', notif['data']['course_id']]);
        break;
    }
  }
}

