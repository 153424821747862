import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-secure-tab-switcher',
  templateUrl: './secure-tab-switcher.component.html',
  styleUrls: ['./secure-tab-switcher.component.scss']
})
export class SecureTabSwitcherComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
