import { Injectable } from '@angular/core';
import { Service } from 'ngx-jsonapi';
import { Page } from '../models/page';

@Injectable({
  providedIn: 'root'
})
export class PageService extends Service<Page>{

  public resource=Page;
  public type='pages';

  constructor() {
    super()
   }
}
