<h2 mat-dialog-title>{{module.attributes.title}}</h2>

<mat-dialog-content>
<div class="row">
  <h1>{{'Lessons'|translate}} :</h1>
  <div class="col-md-3"  *ngFor="let lessonEl of lessons">
    {{lessonEl.attributes.title}} <span class="{{type(lessonEl)}}"></span>
  </div>
</div>
  <div class="row">
    <h1>Evaluation :</h1>
    <div class="col-md-4" *ngIf="module.relationships.exam.data">
      <ul>
        <li>{{module.relationships.exam.data.attributes.title}}</li>
      </ul>
    </div>
    <div class="col-md-4" *ngIf="module.relationships.tests.data.length">
      <ul *ngFor="let test of module.relationships.tests.data">
        <li>{{test.attributes.title}}</li>
      </ul>
    </div>
    <div class="col-md-4" *ngIf="module.relationships.groupWorks.data.length">
      <ul *ngFor="let groupWork of module.relationships.groupWorks.data">
        <li>{{groupWork.attributes.title}}</li>
      </ul>
    </div>
  </div>

</mat-dialog-content>

<mat-dialog-actions>
  <button class="mat-raised-button" (click)="close()">Close</button>
</mat-dialog-actions>
