<app-header></app-header>
<app-title-block
  image="/assets/img/banner-section.png"
  category="{{'calendar'|translate}}"
>
</app-title-block>

<div class="container-fluid py-5">
  <!-- <div class="banner-section mb-5">
    <div class="row h-100">
    <div class="col col-6 banner-img my-auto">
        <img class="ml-auto pos-mobile banner " src="/assets/img/home-banner.png" alt="">
    </div>
    <div class="col col-6 bloch-center my-auto">
        <div class="title-banner">
        <div class="first-title">
            <h2 class="title">{{'calendar'|translate}}</h2>
        </div>
        </div>
    </div>
    </div>
</div> -->

  <div class="row calendar-section">
    <div class="col-md-8 calendar-elements">
      <div class="note mb-5">
        <h1 class="title">{{'CALENDAR'|translate}}</h1>
      </div>
      <app-calendar [events]="eventLessons" (onDatePicked)="doSomething($event)"></app-calendar>
    </div>
    <div class="col-md-4 description-section" >
      <div class="side-description my-3">
        <h1 class="title">DETAILS</h1>
        <div *ngFor="let detail of l ;let i = index"   >
          <div *ngFor="let detaildate of detail.attributes.lesson_dates ;let j = index"   >
            <div *ngIf="(detaildate.date_begin | date:'yMMdd') == (click | date:'yMMdd')">

              <button (click)="navigateLesson(detail)">
                <p class="title1"  >
                  <!--[innerHtml]="pageCalendar && pageCalendar.attributes && pageCalendar.attributes.body ? pageCalendar.attributes.body['value'] : ''"-->
                  {{'Lesson'|translate}} {{i}}  : {{  detail.attributes.title }}
                </p>
              </button>
                <p class="module-description">
                  {{'Module'|translate}} :  {{ m[i] }}
                </p>
                <p class="side-description1 ">{{'Start Date'|translate}} : {{ detaildate.date_begin | date:'dd/MM/yyyy HH:mm' }}</p>
                <p class="side-description1 ">
                  {{'End Date'|translate}} : {{ detaildate.date_end | date:'dd/MM/yyyy HH:mm'}}
                </p>
                <p class="side-description1 ">
                  {{'Salle'|translate}} : {{ detaildate.room }}
                </p>

                  <!--
                  <mat-chip-list>
                    <mat-chip    color="blue" >
                      <p class="side-description1 ">
                        {{ detail.attributes.date_begin | date:'yyyy-MM-dd' }}
                      </p>
                    </mat-chip>
                    <mat-chip    color="red" >
                      <p class="side-description1 ">
                        {{  detail.attributes.date_end | date:'yyyy-MM-dd'}}
                      </p>
                    </mat-chip>
                </mat-chip-list>
                -->
                <hr>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

