import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private  httpClient: HttpClient) {

  }

  getNotifications() {
    const url = environment.base_url + '/api/user/notifications';
    return this.httpClient.get(url,{});
  }

  markNotifAsRead(eventId) {
    const url = environment.base_url + '/api/user/notifications/'+eventId;
    return this.httpClient.get(url,{});
  }

  getUserNotifications(id) {
    const url = environment.base_url + '/api/user_notifications/'+id;
    return this.httpClient.get(url);
  }

}
