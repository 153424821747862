import {Component, Input, OnInit} from '@angular/core';
import {Subscription, throwError} from 'rxjs';
import {ParticipationService} from '../../services/participation.service';
import {Course} from '../../models/course';
import {environment} from 'src/environments/environment';
import {MatDialog} from '@angular/material';
import {LoadingDialogComponent} from '../loading-dialog/loading-dialog.component';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {filter, switchMap} from 'rxjs/operators';
import {TwilioV2Service} from '../../services/twilio-v2.service';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { TwilioService } from 'src/app/services/twilio.service';
import {TranslateService} from "@ngx-translate/core";
import { of } from 'rxjs/internal/observable/of';

@Component({
  selector: 'app-participants-block',
  templateUrl: './participants-block.component.html',
  styleUrls: ['./participants-block.component.scss']
})
export class ParticipantsBlockComponent implements OnInit {

  course: Course = null;
  subscription: Subscription = null;
  loading = true;
  participants ;
  public professors;
  users = [];
  avatarUrl = null;
  current_user= null;
  public chatList =[] ;
  public MailList =[] ;
  user;
  listchannels;
  public currUser ;
  public currentUser = null;
  search="";
  defaultavatargirl = "/assets/img/defaultgirl.png"
  defaultavatarman = "/assets/img/defaultman.png"

  constructor(
    private participationService: ParticipationService,
    private dialogService: MatDialog,
    private twilioService: TwilioService,
    private chatService: TwilioV2Service,
    private router: Router,
    private authService: AuthService,
    private httpClient : HttpClient,
    private httpClient1 : HttpClient,
    private translateService: TranslateService,

  ) {
  }

  ngOnInit(): void {

    this.authService.currentUser
    .pipe(
      switchMap(user => {
        this.currentUser = user;
        this.chatService.getUser(this.currentUser.id)
        .subscribe(user1 => {
          this.currUser= user1 ;

        });
        //console.log('current user', user);
        this.twilioService.GetUsers(this.currentUser)
        .subscribe(res => {
          //this.participants =res ;
          this.participants =this.getUsers(res);
          //console.log("ahwa getUsers" , res)
        });

        return of(user);
        // return this.fetchParticipation();
      })
    ).subscribe(res => {
    //console.log('loaded', res);
    });


    this.avatarUrl = environment.avatars + '/';
    // const url = environment.base_url ;
    // this.httpClient.get<any>(url+'/api/v1/professors/').subscribe(ren => {
    //   this.professors = ren.data;
    // }
    // )
    // this.httpClient1.get<any>(url+'/api/v1/users/').subscribe(re => {
    //   this.users = re.data;
    //   this.participants =this.getUsers(this.users);

      //console.log("partic" , this.participants);

      // this.authService.getCurrentUser().subscribe(({current_user}) => {
      //   this.current_user=current_user;
      //   current_user && this.participants.sort(function(x,y){ return x.id == current_user.id ? -1 : y.id == current_user.id ? 1 : 0; });
      // });
      //this.loading = false;

    //})
  }
  verif(photo)
  {
    if(photo[0]=="h")
    {
      return photo ;
    }
    else
    {
      return this.avatarUrl+photo ;
    }
  }
  switch (gender)
  {
    //console.log(gender);
    if ((gender=="male") || (gender=="M"))
    {
      return this.defaultavatarman ;
    }
    else
    {
      return this.defaultavatargirl ;
    }

  }
  getUsers(users)
  {
    let particp =[];
    for (let user of users)
      {     ////console.log(this.currentUser.grade_id);



          if (user.id!=this.currentUser.id)
          {
            ////console.log(user);
            particp.push(user)
          }


      }

      //console.log("ahwma users without actual user", particp) ;
      return particp ;
  }
  // @Input('course')
  // set _course(course) {
  //   if (this.subscription) {
  //     this.subscription.unsubscribe();
  //   }
  //   this.course = course;
  //   this.loading = true;

  //   if (!course) {
  //     return;
  //   }
    // this.subscription = this.participationService.getParticipants(this.course.id).subscribe(({participants}: any) => {
    //   this.participants = participants;
    //   this.authService.getCurrentUser().subscribe(({current_user}) => {
    //     this.current_user=current_user;
    //     current_user && this.participants.sort(function(x,y){ return x.id == current_user.id ? -1 : y.id == current_user.id ? 1 : 0; });
    //   });
    //   this.loading = false;
    // }, err => {
    //   this.loading = false;
    // });

    // const url = environment.base_url ;
    // this.httpClient.get<any>(url+'/api/v1/users/').subscribe(re => {
    //   this.participants = re.data;
      // this.authService.getCurrentUser().subscribe(({current_user}) => {
      //   this.current_user=current_user;
      //   current_user && this.participants.sort(function(x,y){ return x.id == current_user.id ? -1 : y.id == current_user.id ? 1 : 0; });
      // });
      //this.loading = false;

  //   })
  // }
  onCheckboxChange(participant,e) {
    if (e.target.checked) {
      //console.log("ahwa participant grade lkol" , participant.grade)
      if (participant.grade!=-1)
      {
        this.chatService.getUser(participant.id)
        .subscribe(user => {
          this.user = user;
          this.chatList.push(this.convertState(this.user.state,"prof"));
          //console.log("ahwa l user " ,this.user)
          this.MailList.push(this.convertState(this.user.state,"prof"));

          //console.log("ahwa l user " ,this.user)

        });
      }else
      {
        this.chatService.getUser(participant.email)
        .subscribe(user => {
          this.user = user;
          this.chatList.push(this.convertState(this.user.state,"stud"));
          //console.log("ahwa l user " ,this.user)
          this.MailList.push(this.convertState(this.user.state,"stud"));

          console.log("ahwa l user " ,this.user)

        });
      }


      //console.log(this.chatList)

    }
    else
    {
      var index = 0 ;
      for (let l of this.chatList)
      {
        if (participant.grade!=-1)
        {
          if (l.identity==participant.id)
          {
            ////console.log(l)
            this.chatList.splice(index,1);
            this.MailList.splice(index,1);

            //break ;
          }
        }
        else
        {
          if (l.identity==participant.email)
          {
            ////console.log(l)
            this.chatList.splice(index,1);
            this.MailList.splice(index,1);

            //break ;
          }
        }

        index = index=1;
      }
      //console.log(this.chatList)
    }

  }
  createChannel()
  {
    //console.log("list create" , this.MailList)
    console.log(this.chatList.length)
    if(this.chatList.length)
    {
      Swal.fire({
        title: this.translateService.instant("Enter a name for the roomchat"),
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        cancelButtonText: 'annuler',
        confirmButtonText: this.translateService.instant("Create"),
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
          if (login === false) return false;

        if (login === "") {
          Swal.showValidationMessage(this.translateService.instant("You need to write room chat name!"));
          return false
        }
          //console.log(login)
          this.chatService.verifCh(login).subscribe(
            (res: any) => {

              if (res.status=="notfound")
              {
                Swal.fire(this.translateService.instant('Please wait'))
                Swal.showLoading()
                this.chatList.unshift(this.convertState(this.currUser.state,"stud"));
                  //console.log(this.chatList);
                var list = {users: this.chatList, roomname:login,MailList:this.MailList,sender :this.convertState(this.currUser.state,"stud")};
                  console.log(this.chatList);
                  this.chatService.createChannel(list).subscribe(res => {
                  //console.log(res)

                  this.router.navigate(['/profile/chat', this.currentUser.id]);
                  });

              }
              else
              {
                Swal.fire({
                  title: this.translateService.instant("this room chat name already exist"),
                  text:  '',
                  icon: 'error',
                  //showCancelButton: true,
                  confirmButtonText: 'OK',

                })
                  return false
              }


              });


        },
        allowOutsideClick: () => !Swal.isLoading()

      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     // Swal.fire({
      //     //   title: `${result.value.login}'s avatar`,
      //     //   imageUrl: result.value.avatar_url
      //     // })

      // }
      })
    }
    else
    {
      Swal.fire({
        title: this.translateService.instant("you need to select users to chat with"),
        text:  '',
        icon: 'error',
        //showCancelButton: true,
        confirmButtonText: 'OK',

      })
    }




  }
  convertState(user,type)
  {
    if (type=="prof")
    {
     return {
      'id' : user['attributes']['id'],
      'identity' : user['identity'],
      'email' : user['attributes']['email'],
      'friendlyName' : user['friendlyName'],
      'first_name' : user['attributes']['first_name'],
      'last_name' : user['attributes']['last_name'],
      'photo' : user['attributes']['photo'],
      'grade' : -1,
      'gender' : user['attributes']['gender'],
      'formation' : -1,
      'checked' : false
     };
    }
    else {
      return {
        'id' : user['attributes']['id'],
        'identity' : user['identity'],
        'email' : user['attributes']['email'],
        'friendlyName' : user['friendlyName'],
        'first_name' : user['attributes']['first_name'],
        'last_name' : user['attributes']['last_name'],
        'photo' : user['attributes']['photo'],
        'grade' : user['attributes']['grade_id'],
        'gender' : user['attributes']['gender'],
        'formation' : user['attributes']['formation_id'],
        'checked' : false
       };

    }
  }
  convert(string)
  {
    let upperCaseStr = string.toLowerCase();
    return upperCaseStr.charAt(0).toUpperCase() + upperCaseStr.slice(1) ;
  }
  chat(user) {
    //(click)="chat(participant)"
    if(this.current_user && user.id === this.current_user.id) {
      return;
    }
    const d = this.dialogService.open(LoadingDialogComponent);

    this.authService.currentUser.pipe(
      filter(current => !!current),
      switchMap((current: any) => {
        if (user.id.toString() === current.id.toString()) {
          d.close();
          //console.log('you cannot chat with yourself', current);
          return throwError('you cannot chat with yourself');
        }
        return this.chatService.createChannelApi(user.id);
      })
    ).subscribe(res => {
      //console.log('this is the created channel', res);
      d.close();
      this.router.navigate(['/profile/chat', user.id,'?refresh=1']);
    }, err => {
      d.close();
      //console.log('error creating channel', err);
    });


  }

}
