import {Component, Input, OnInit} from '@angular/core';
import {Course} from '../../../../models/course';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-course-item',
  templateUrl: './course-item.component.html',
  styleUrls: ['./course-item.component.scss']
})
export class CourseItemComponent implements OnInit {
  @Input() course: Course;
  @Input() index: number;
  constructor(private router:Router,
              private httpClient: HttpClient ) { }
  grade ;
  count ;
  countModules;
  countLessons;
  countInfo ;
  //[routerLink]="'/modules/'+course.id"
  ngOnInit() {


    const url = environment.base_url ;
    this.httpClient.get<any>(url+'/api/countModules').subscribe(res => {

      this.countInfo = res ;
      for (let info of this.countInfo )
      {
        if (info.idCourse==this.index)
        {
          this.countModules =info.moduleCount;
          this.countLessons = info.lessonCount;
        }
      }

    }) ;
  }




  test(i,l)
  { let tr : boolean = false ;


    if (l.length==1)
    {
      if (i==l)
      {
        tr= true ;
      }
    }
    else
     {
       if ( l.includes(i) )
       {
         tr = true ;
       }
     }
     return tr ;
  }

  doStuff(a){
    this.router.navigate(['/modules/'+a]);  }
}
