import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';

@Component({
  selector: 'app-test-result-components',
  templateUrl: './test-result-components.component.html',
  styleUrls: ['./test-result-components.component.scss']
})
export class TestResultComponentsComponent implements OnInit {

  @Input('title') participation = marker('Thank you for your participation');
  @Input('greeting') mention = marker('congratulations!');
  @Input('description') detailsmention = marker('you have successfully completed the evaluation');
  @Output() repeatExam = new EventEmitter();

  @Input() note = 0;
  @Input() auto = false;
  @Input() link = null;
  @Input() success = true;
  @Input() repeat = false;
  @Input() repeatAt = null;

  @Input('evaluationType') typeeval = 'test';

  constructor() {
  }

  ngOnInit(): void {
  }

  get canRepeat() {
    const m =  moment.utc(this.repeatAt);
    //console.log({m});
    return m.local().isBefore(moment());
  }

  get repeatTime(){
    return moment.utc(this.repeatAt).local().toDate().getTime();
  }
}
