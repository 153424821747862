import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnInit} from '@angular/core';
import {TwilioV2Service} from '../../../services/twilio-v2.service';
import {Channel} from 'twilio-chat/lib/channel';
import LastMessage = Channel.LastMessage;
import {Message} from 'twilio-chat/lib/message';
import * as moment from 'moment';

@Component({
  selector: 'app-channel-item',
  templateUrl: './channel-item.component.html',
  styleUrls: ['./channel-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChannelItemComponent implements OnInit {
  selected = false;
  public channel: Channel = null;
  lastMessage: Message = null;
  member = null;
  unreadCount = 0;

  constructor(
    private twilioService: TwilioV2Service,
    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
  }

  ngOnInit(): void {
  }

  @Input('selected')
  set _selected(selected) {
    this.selected = selected;
    if (selected) {
      this.unreadCount = 0;
      this.changeDetectorRef.markForCheck();
      if (this.lastMessage) {
        this.channel.advanceLastConsumedMessageIndex(this.lastMessage.index)
          .then(console.log);
      }
    }
  }

  @Input('channel')
  set _channel(channel) {
    //console.log('setting', channel);
    const old = this.channel;
    this.channel = channel;
    if ((channel?.uniqueName === old?.uniqueName)) {
      return;
    }
    if (old) {
      old.removeAllListeners();
    }
    if (channel) {
      this.fetchLastMessage();
      this.listenForChanges();
      this.getMember();
      this.channel.getUnconsumedMessagesCount().then(count => {
        this.unreadCount = count;
        //console.log("count1" ,this.unreadCount);
        this.changeDetectorRef.markForCheck();
      });
    }
    this.changeDetectorRef.markForCheck();
  }

  listenForChanges() {
    this.channel.on('messageAdded', (message) => {
      this.lastMessage = message;
      //console.log('[channel] recieved message:', message);
      if (!this.selected) {
        this.unreadCount += 1;
        //console.log("count2" ,this.unreadCount);
      }
      this.changeDetectorRef.markForCheck();
    });
  }


  get friendlyName() {
      if (this.channel?.uniqueName?.startsWith('private')) {
        return this?.member?.friendlyName || 'Loading';

      }

      return this.channel?.friendlyName ?? 'Loading';
  }


  get pic() {
    return this?.member?.attributes?.photo;
  }


  fetchLastMessage() {
    if (this.channel?.lastMessage?.index > -1) {
      this.channel
        .getMessages(1, this.channel.lastMessage.index)
        .then(page => {
          if (page.items.length) {
            this.lastMessage = page.items[0];
            this.changeDetectorRef.markForCheck();
          }
        });
    }
  }


  private getMember() {
    if (!this.channel) {
      return;
    }

    this.twilioService.getMembers(this.channel, true)
      .subscribe((res: any[]) => {
        this.member = res.length ? res[0] : {friendlyName: 'John Doe'};
        this.changeDetectorRef.markForCheck();
        this.ngZone.run(() => {

        });
      });
  }
  forNow(time)
  { //console.log(time);
    moment.locale('fr');
    return moment(time).fromNow();
  }
}
