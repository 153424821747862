import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TestSubmissionService {

  constructor(
    private httpClient: HttpClient
  ) {

  }

  getSubmission(test) {
    return this.httpClient
      .get(environment.get_test_submission.replace('{test}', test))
      .pipe(map(this.updateDates));
  }

  updateDates({submission}) {
    if (submission) {
      const date = moment.utc(submission.endsAt).local();
      submission.endsAt = date.valueOf();
      submission.ended = date.isBefore(moment());

      console.log('submission', submission);
    }
    return {submission};
  }

  uploadAnswerFile(file) {

    const data = new FormData();

    data.set('file', file);

    return this.httpClient
      .post(
        environment.upload_answer_file,
        data,
        /*{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }*/
      );

  }


  submitAnswer(test, data, ended = false) {

    return this.httpClient.post(
      environment.submit_answers.replace('{test}', test),
      {data, ended}
    ).pipe(map(this.updateDates));

  }

  submitExamAnswer(test, data, ended) {

    return this.httpClient.post(
      environment.submit_exams_answers.replace('{exam}', test),
      {data, ended}
    ).pipe(map(this.updateDates));

  }

  submitGroupWorkAnswer(test, data, ended = false) {

    return this.httpClient.post(
      environment.submit_group_work_answers.replace('{group-work}', test),
      {data, ended}
    );
  }

  startTest(test) {
    return this.httpClient
      .get((environment.get_test_submission + '/start').replace('{test}', test))
      .pipe(map(this.updateDates));

  }

  startExam(test) {
    return this.httpClient
      .get((environment.get_exam_submission + '/start').replace('{exam}', test))
      .pipe(map(this.updateDates));

  }

  repeatExam(test) {
    return this.httpClient
      .post((environment.repeat_exam).replace('{exam}', test), {})
      .pipe(map(this.updateDates));

  }

  getExamSubmission(test) {
    return this.httpClient
      .get(environment.get_exam_submission.replace('{exam}', test))
      .pipe(map(this.updateDates));
  }

  getGroupWorkSubmission(groupWork) {
    return this.httpClient
      .get(environment.get_group_work_submission.replace('{group-work}', groupWork))
      .pipe(map(this.updateDates));
  }

  updateGroupWork(groupWork, data) {
    return this.httpClient
      .post(environment.update_group_work.replace('{group-work}', groupWork), {data});
  }


}
