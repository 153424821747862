import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-profile-tabs',
  templateUrl: './profile-tabs.component.html',
  styleUrls: ['./profile-tabs.component.scss']
})
export class ProfileTabsComponent implements OnInit {
  selected = 'Module';

  constructor( private router :Router) { }

  ngOnInit(): void {

  }
  myFunction()
  {
    //console.log("ani n'affichi");

    document.getElementById("myDropp").classList.toggle("show");

  }
   openDrop() {
    document.getElementById("myDropdown").classList.toggle("show");
    document.getElementById("myDropdownItems").addEventListener('click', function (event) {
      event.stopPropagation();
      window.onclick = function(event) {
        if (!event.target.matches('.dropbutton')) {

            var dropdowns =
            document.getElementsByClassName("dropdownmenu-content");

            var i;
            for (i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains('show')) {
                    openDropdown.classList.remove('show');
                }
            }
        }
    }
  });
    this.router.navigate(['/profile/mesmodules']);
  }

}
