import {Component, Input, OnInit, ChangeDetectorRef} from '@angular/core';
import {UsersService} from '../../services/users.service';
import {HttpClient} from '@angular/common/http';
import {colors} from '../calendar/calendar-utils/colors';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../services/auth.service';
import {CalendarService} from '../../services/calendar.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  // @Input('idUser')
  idUser = 1;
  private data;
  events1;
  lessons;
  val ;
  currentUser = null;
  m ;
  modules;
  click;

  constructor(
    private calendarService: CalendarService,
    private authService: AuthService,
    private httpClient: HttpClient ,
    private cd: ChangeDetectorRef

  ) {
  }




  ngOnInit(): void {

    this.authService.currentUser
    .pipe(
      switchMap(user => {
        this.currentUser = user;
        //console.log('current user', user);
        return of(user);
        // return this.fetchParticipation();
      })
    ).subscribe(res => {
    //console.log('loaded', res);
    });

    const url = environment.base_url ;
    this.httpClient.get<any>(url+'/api/v1/lessons').subscribe(res => {
      this.val= res.data ;
      this.lessons = this.getLessons(this.val);
      //console.log("ahwma les lessons" , this.lessons);
      this.events1 = this.getEvents(this.lessons);
      //console.log("ahwma les eventdata" , this.events1);
      this.modules= this.getEventModules(this.lessons);
      //console.log("ahwma les modules" , this.modules);
    }) ;

 

   /* this.calendarService.getPersonalCalendar().subscribe(
      (data) => (this.data = data),
      () => //console.log('error'),
      () => {
        this.events1 = this.getEvents(this.data);
        //console.log('data events ::', this.events1);
      }
    );
*/
  }

    getLessons(lessons) {
      let m =[];
      for (let lesson of lessons)
      {
        if (lesson.attributes.grades.includes(this.currentUser.grade_id)==true)
        {
          m.push(lesson)
        }
      }
      return m ;


    }

    getEventModules(lessons){
      const url = environment.base_url ;
      let m =[];
      for( let lesson of lessons){
        this.httpClient.get<any>(url+'/api/v1/lessons/'+ lesson.id+'/module').subscribe(res1 => {
          m.push( res1.data.attributes.title);
          //console.log("ahwma les modules " ,this.m);
        })
        }
        return m ;
    }

    public doSomething(date: any):void {
      this.click = date ;
      //console.log('Picked date ahya: ', date);
      this.cd.detectChanges();

    }

  getEvents(lessons) {
    let eventLessons =[]
    for( let lesson of lessons){
      let styledTitle=(lesson.attributes.title[0].toUpperCase()+lesson.attributes.title.slice(1).toLowerCase()).bold();
      eventLessons.push(
        {
          title: 'Début : '+styledTitle, start : new Date(lesson.attributes.date_begin) , color : colors.blue, allDay: true ,

        },
        {
          title: 'Fin : '+styledTitle, start : new Date(lesson.attributes.date_end) , color : colors.red, allDay: true
        },

      );
    }
    return eventLessons;
  }

  calendarColor(color) {
    switch (color) {
      case 'blue':
        return colors.blue;
        break;
      case 'green':
        return colors.green;
        break;
      case 'red':
        return colors.red;
        break;
      case 'yellow':
        return colors.yellow;
        break;
    }
  }

}
