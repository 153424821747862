<!-- <ng-template #loadingBlock>
  <div class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<div class="block-listing-modules" *ngIf="!loading  ; else loadingBlock">
  <div class="title my-3">
    {{'Other Modules From This Course'|translate}}
  </div>
  <div class="d-flex flex-column module-list container-fluid" >
    <ng-container *ngFor="let module of modules; let index = index" >
      <div (click)="navigate(module)" class="module-item p-2 border info-detail my-1 row" *ngIf="module.id !== currentModule;" >
        <div class="col-4">
          <div class="lesson-number">{{'Module'|translate}} {{index + 1}}</div>
        </div>
        <div class="col-8">
          <div class="lesson-title">{{module.attributes.title}}</div>
        </div>
      </div>
    </ng-container>

  </div>
</div> -->
<!-- <div class="block-listing-modules">
  <div class="title my-3">
<p>Modules</p>
  </div>
  <div class="d-flex flex-column module-list container-fluid" >
    <ng-container *ngFor="let module of moduless" >
      <div  class="module-item p-2 border info-detail my-1 row">
        <div class="col-4">
          <div class="lesson-number">{{'Module'|translate}} </div>
        </div>
        <div class="col-8">
          <div class="lesson-title">{{module.attributes.title}}</div>
        </div>
      </div>
    </ng-container>

  </div>
</div> -->

<div class="container-fluid">
<div class=" how-participate">
<h1 class=" course-title head-title title">{{'Modules'|translate}}</h1>

</div>
    <div class="row contain" style="background-color:white">
      <div class=" col-sm content" *ngFor="let module of moduless" >
        <div  class="course-teaser">
          <div class="card">
            <div class="picture-special">
              <div class="play">
                <img *ngIf="!module.attributes.video" #i [src]="dp_baseUrl+module.attributes.image_path" (error)="i.src = '/assets/img/close-up.png'" class="card-img-top" alt="picture course">
                <video *ngIf="module.attributes.video"  [src]="module.attributes.video" autoplay loop [muted]="true" class="card-img-top" alt="picture course" ></video>
              </div>
            </div>
            <div class="card-body">

              <button *ngFor="let item  of [1,2,3,4,5] ; let i = index " class="btn btn-category mb-3 ml-2">
                <div *ngIf ="test(item,module.attributes.grades); else emptygrade" >
                  {{ item }}
                </div>
                <ng-template #emptygrade >
                 <p style="color: transparent;">A</p>
                </ng-template>
              </button>
              <pre></pre>

              <div class=""  *ngIf="module.attributes.field_annee">
                  <p  >{{module.attributes.field_annee}}</p>
              </div>
              <h5 class="card-body-title" [line-truncation]="2">{{module.attributes.title}}</h5>
              <!-- <p class="card-text-course"
              [line-truncation]="3"
              [innerHTML]="module.attributes.description">
              </p> -->
              <div class="row mt-3 btn-more" >
                <div class="col col-9 display-icon">
                  <i class="category-icon icon-certificate-black" *ngIf="module.attributes.certificate"></i>
                  <i class="category-icon icon-five-modules"></i>
                  <span class="module-number"><span>{{module.length}}</span> {{'Modules'|translate}}</span>
                </div>
                <div class="col col-3" *ngIf="moduless">
                  <button class="btn btn-apply mt-1" (click)="go(module)">{{'More'|translate}}</button>
                </div>
              </div>
            </div>
            <div class="line-card-bottom"></div>
          </div>
         <div class="line-cards-bottom"></div>
        </div>
      </div>
    </div>
  </div>
