<div *ngIf="!loading && isParticipated !== null; else l">
    <div class="verif-block custom-display" *ngIf="!isParticipated && !outOfApplication && course && course.attributes">
      <p class="text text-medium">{{'course'| translate}} {{course.id}} - {{course.attributes.title}}</p>
      <button (click)="participate()" class="btn btn-apply">{{'apply now'|translate}}</button>
    </div>
    <ngx-smart-modal #applicationCourseSuccess identifier="applicationCourseSuccess" [customClass]="'confirmation-modal-delete'" [closable]="false">
      <h1>{{'congratualations!'| translate }}</h1>
      <h2>{{'Your application is being processed, you will be shortly notified of the result'| translate}}</h2>
      <div class="block-buttons">
        <button type="button" routerLink="/home">{{'homepage'| translate}}</button>
        <button type="button" routerLink="/profile/mesmodules">{{'my courses'| translate}}</button>
      </div>
    </ngx-smart-modal>
</div>
<ng-template #l></ng-template>
