import {Component, Input, OnInit} from '@angular/core';
import {Course} from '../../models/course';
import {LanguageService} from 'src/app/services/language.service';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-more-courses-block',
  templateUrl: './more-courses-block.component.html',
  styleUrls: ['./more-courses-block.component.scss']
})
export class MoreCoursesBlockComponent implements OnInit {


  courses: Course[] = [];
  coursesss:[];
  @Input() title = 'Courses';

  constructor(
    public languageService: LanguageService,
    private httpClient: HttpClient,
    private router: Router,


  ) {
  }

  ngOnInit(): void {
    const url = environment.base_url ;
    this.httpClient.get<any>(url+'/api/v1/courses').subscribe(res => {
    this.coursesss = res.data.sort((x,y) => x.attributes['created-at'] < y.attributes['created-at']).slice(0,3);

})
    
  }

  @Input('courses')
  set _courses(courses) {
    //console.log('setting', courses);
    this.courses = courses;


  }


  get courseList() {
    if (this.courses) {
      if (this.languageService.currentLang === 'fr') {
        return this.courses.filter(c => c.attributes.language === 'fr');
      } else if (this.languageService.currentLang === 'en') {
        return this.courses.filter(c => c.attributes.language === 'en');
      }
      return  this.courses.filter(c => c.attributes.language === 'ar');
    }
    return [];

  }
  navigate(a){
    this.router.navigate(['/modules/'+a]);  
  }
    test(i,l)
    { let tr : boolean = false ; 
      if (l.length==1)
      {
        if (i==l)
        {
          tr= true ;
        }
      }
      else 
       {
         if (l.includes(i) )
         {
           tr = true ; 
         }
       }
       return tr ; 
    }
  
}
