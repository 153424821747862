import {Injectable} from '@angular/core';
import {Autoregister, Service} from 'ngx-jsonapi';
import {Test} from '../models/test';
import {QuestionsService} from './questions.service';
import {McqQuestionsService} from './mcq-questions.service';
import {FileQuestionsService} from './file-questions.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TestsService extends Service<Test> {
  public resource = Test;
  public type = 'tests';

  constructor(
    private questionsService: QuestionsService,
    private mcqService: McqQuestionsService,
    private fileQuestionsService: FileQuestionsService,
    private httpClient : HttpClient
  ) {
    super();
    this.register();
  }


}
