import {Injectable} from '@angular/core';
import {Autoregister, Service} from 'ngx-jsonapi';
import {Module} from '../models/module';
import {ProfessorsService} from './professors.service';


@Injectable({
  providedIn: 'root'
})
export class ModulesService extends Service<Module> {
  public resource = Module;
  public type = 'modules';


  constructor(
    private professorsService: ProfessorsService
  ) {
    super();
    this.register();
  }
}
