<!-- <ng-template #loadingBlock>
  <div class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<div class="block-listing-lessons" *ngIf="!loading  ; else loadingBlock">
  <div class="title my-3">
    {{'Other Lessons From This module'|translate}}
  </div>
  <div class="d-flex flex-column module-list container-fluid" >
    <ng-container *ngFor="let lesson of lessons; let index = index" >
      <div
        (click)="navigate(lesson)"
        class="module-item p-2 border info-detail my-1 row" *ngIf="lesson.id !== current;" >
        <div class="col-3">
          <div class="lesson-number">{{'Lesson'|translate}} {{index + 1}}</div>
        </div>
        <div class="col-9">
          <div class="lesson-title">{{lesson.attributes.title}}</div>
        </div>
      </div>
    </ng-container>

  </div>
</div>  -->


<div class="container-fluid">
<div class=" how-participate">
<h1 class=" course-title head-title title">{{'Lessons'|translate}}</h1>
</div>
    <div class="row contain"style="background-color:white">
      <div class=" col-sm content" *ngFor="let lesson of lessonss" >
        <div  class="course-teaser">
          <div class="card" (mouseover)="onmouse('/assets/img/online-learning.svg', lesson.id)" (mouseout)="onmouse('/assets/img/document-2.svg', lesson.id)">
            <div class="picture-special">
              <div class="play">
                <img #i [id]="'teaser-img-' + lesson.id" [src]="image" class="card-img-top picture-course" alt="picture course" >
              </div>
            </div>
            <div class="card-body">
              <h5 class="card-body-title mt-3" [line-truncation]="2">{{lesson.attributes.title}}</h5>
              <!--  <p class="card-text-course"
              [line-truncation]="3"
              [innerHTML]="lesson.attributes.description">
              </p>  -->
              <div class="row mt-3  btn-more">
                <div class="col col-9 display-icon">
                  <i class="category-icon icon-certificate-black" *ngIf="lesson.attributes.certificate"></i>
                  <i class="category-icon icon-five-modules"></i>
                  <span class="module-number"><span>{{lesson.length}}</span> {{'Lessons'|translate}}</span>
                </div>
                <div class="col col-3" *ngIf="lessonss">
                  <button class="btn btn-apply mt-1"(click)="navigate(lesson)">{{'More'|translate}}</button>
                </div>
              </div>
            </div>
            <div class="line-card-bottom"></div>
          </div>
          <div class="line-cards-bottom"></div>
        </div>
      </div>
    </div>
  </div>
