import { Injectable } from '@angular/core';
import { Service } from 'ngx-jsonapi';
import { Student } from '../models/student';

@Injectable({
  providedIn: 'root'
})
export class StudentService extends Service<Student>{
  public resource = Student;
  public type = 'students';
  constructor() {
    super();
    this.register();
   }
}
