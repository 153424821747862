<ng-template #loadingBlock>
  <app-loading-dialog></app-loading-dialog>
</ng-template>

<div class="participants-block">


  <div class="title my-3">{{'Participants'|translate}}</div>
    <div class="row center" >
      <div class="col-sm">
        <div class="input">
          <input class="input__content" type="search"  [(ngModel)]="search" placeholder="Rechercher">
        </div>
      </div>
      <div class="col-sm unseen ">
        <div class="avatar">
          <img #i src='/assets/img/SEARCH.svg'  >
        </div>
      </div>

      <div class="col-sm">
          <div class="button">
            <button class="btn-search-course" (click)="createChannel()">{{'start chat'|translate}}</button>
          </div>
      </div>
    </div>

    <div class="row" >

      <div *ngFor="let participant of participants | usersfilter:search " class="col-md-6 " [ngClass]="current_user && current_user.id === participant.id ? 'current-user' : ''" >
        <div class="students">
          <div class="students__logo">
            <img class=""  *ngIf="!participant.photo" [src]="switch(participant.gender)" alt="profile">
            <img  class="" *ngIf="participant.photo"  [src]="verif(participant.photo)" alt="profile">
          </div>
          <div class="students__name">
            <div class="students__name__content">
              <span>{{ convert(participant.first_name) }} {{ convert(participant.last_name) }}</span>
            </div>
          </div>
          <div class="students__check">
            <div class="students__check__content">
                <input type="checkbox"   (change)="onCheckboxChange(participant,$event)"  value="{{participant.id}}">
                  <!-- <input type="checkbox" (change)="onCheckboxChange(participant,$event)"  value="{{participant.id}}" >
                  <label for="viewed"></label>  checkbox checkbox-inline-->
            </div>
          </div>

        </div>



    </div>

    </div>



</div>
