import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {LiveLessonsService} from '../../../services/live-lessons.service';
import {LiveLesson} from '../../../models/live-lesson';
import {ZoomService} from '../../../services/zoom.service';
import * as moment from 'moment';
import {Moment} from 'moment';
import {ToastrService} from 'ngx-toastr';
import {LessonsService} from 'src/app/services/lessons.service';
import {environment} from '../../../../environments/environment';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-live-detail',
  templateUrl: './live-detail.component.html',
  styleUrls: ['./live-detail.component.scss'],
  changeDetection : ChangeDetectionStrategy.OnPush
})
export class LiveDetailComponent implements OnInit {
  @Input('index')
  index;
  live: LiveLesson;
  date;
  endDate: Moment;
  startDate: Moment;
  videoUrls = [];
  timeLeft = 0;

  constructor(
    private liveLessonService: LiveLessonsService,
    private zoomService: ZoomService,
    private lessonsService: LessonsService,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private changeDetectorRef : ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.getLiveSession();
  }

  getLiveSession() {
    this.liveLessonService.get(this.index, {}).subscribe(
      live => {
        this.live = live;
        this.changeDetectorRef.markForCheck();
      },
      error => (//console.log('errors')),
      () => {
        //console.log('completed the video', this.live);
        this.zoomService
          .getUrlVideo(this.live.attributes.meeting_id)
          .subscribe(
            ({urls}) => {
              this.videoUrls = urls;
              this.endDate = moment.utc(this.live.attributes.meeting_start_date).local().add(this.live.attributes.meeting_duration, 'minute');
              this.startDate = moment.utc(this.live.attributes.meeting_start_date).local();
              this.timeLeft = moment.duration(this.startDate.diff(moment())).asSeconds();
              //console.log('diff', this.timeLeft);
              //console.log('started', this.started);
              //console.log('ended', this.ended);
              //console.log('startDate', this.startDate);
              //console.log('endDate', this.endDate);
              this.changeDetectorRef.markForCheck();
            },
            () => {
              //console.log('video url :', this.videoUrls);
            }
          );
      }
    ));
  }


  get ended() {
    if (!this.endDate) {
      return false;
    }

    return this.endDate.isBefore(moment());
  }

  get started() {
    if (!this.startDate) {
      return false;
    }

    return this.startDate.isBefore(moment());
  }


  get stopTime() {
    return this.startDate.toDate().getTime();
  }

  /** not working !! => must get the file id of live video */
  get id() {
    return this.live?.relationships.file?.data?.id;
  }

  get isFavorite() {
    return this.live?.relationships.file?.data?.attributes.isFavorite;
  }

  addFavorite(id) {
    if (id) {
      this.lessonsService.addFavorite({file_id: id})
        .subscribe((res: any) => {
            if (res) {
              this.changeDetectorRef.markForCheck();
              this.getLiveSession();
              this.toastr.success('Live Favorite successfully added');
            }
          },
          err => {
            this.toastr.error('Error while adding live favorite');
            //console.log(err);
          });
    } else {
      this.toastr.error('Error while adding live session favorite');
    }
  }

  deleteFavorite(id) {
    if (id) {
      this.lessonsService.deleteFavorite({_id: id})
        .subscribe((res: any) => {
            if (res) {
              this.getLiveSession();
              this.changeDetectorRef.markForCheck();
              this.toastr.success('Live session favorite successfully deleted');
            }
          },
          err => {
            this.toastr.error('Error while deleting live session favorite');
            //console.log(err);
          });
    } else {
      this.toastr.error('Error while deleting live session favorite');
    }
  }

  get url() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(environment.host + `/zoom/${this.live.attributes.meeting_id}/${this.live.attributes.meeting_password}`);
  }

}
