import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {NgxJsonapiModule} from 'ngx-jsonapi';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from '../environments/environment';
import {SharedModule} from './components/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {IonicStorageModule} from '@ionic/storage';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxLoadingModule} from 'ngx-loading';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserInterceptor} from './interceptors/browser.interceptor';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {MatSelectCountryModule} from '@angular-material-extensions/select-country';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import {NgxPaginationModule} from 'ngx-pagination';
import {ToastrModule} from 'ngx-toastr';
import {CalendarPageModule} from './pages/calendar/calendar-page.module';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CountdownModule} from 'ngx-countdown';
import {MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {registerLocaleData} from '@angular/common';
import localeAr from '@angular/common/locales/ar';
import localeFr from '@angular/common/locales/fr';
import { MatFormFieldModule, MatSelect, MatSelectModule } from '@angular/material';
registerLocaleData(localeAr);
registerLocaleData(localeFr);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

export class CoaMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return params.key;
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    MatFormFieldModule,
    NgxJsonapiModule.forRoot({
      url: environment.api_url
    }),
    NgxPaginationModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    MatSelectModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    IonicStorageModule.forRoot({
      name: '__coa'
    }),
    CountdownModule,
    FormsModule,
    ReactiveFormsModule,
    NgxLoadingModule.forRoot({}),
    NgxSmartModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    MatSelectCountryModule,
    CalendarModule.forRoot({provide: DateAdapter, useFactory: momentAdapterFactory}),
    CalendarPageModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      preventDuplicates: true
    }), //
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'fr',
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: CoaMissingTranslationHandler}
    }),

  ],
  exports: [
    MatSelectModule,
  ],
  providers: [
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],

})
export class AppModule {
}
