import {Component, Input, OnInit} from '@angular/core';
import {Course} from '../../models/course';
import {Subscription} from 'rxjs';
import {ModulesService} from '../../services/modules.service';
import {CoursesService} from '../../services/courses.service';
import {Module} from '../../models/module';
import {Router} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';
@Component({
  selector: 'app-more-modules-block',
  templateUrl: './more-modules-block.component.html',
  styleUrls: ['./more-modules-block.component.scss']
})
export class MoreModulesBlockComponent implements OnInit {

  @Input('currentModule') currentModule = null;
  @Input() course: Course;
  subscription: Subscription = null;
  loading = true;
  moduless:any;
  c: any;
  dp_baseUrl=environment.dp_baseUrl+'/';

  //course:[];
  constructor(
    private coursesService: CoursesService,
    private router: Router,
    private httpClient: HttpClient,
    private httpClient1: HttpClient
  ) {

  }

  ngOnInit(): void {
    const url = environment.base_url ;
    this.httpClient.get<any>(url+'/api/v1/modules').subscribe(res => {
    this.moduless = res.data.sort((x,y) => x.attributes['created-at'] < y.attributes['created-at']).slice(0,3);})




  }


/*
  @Input('courses')
  set _course(course: Course) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.loading = true;
    this.subscription = this.coursesService
      .get(course.id, {
        include: ['modules'],
        ttl: 60
      }).subscribe(c => {
        this.loading = false;
        this.course = c;
        //console.log('courrrrrs :::: ', c);
      });
  }


  get modules(): Module[] {
    return this.course.relationships.modules.data ?? [];
  }
*/


  go(module) {

    ////console.log("ahwa module id ",module.id);
    ////console.log("ahwa course id ",this.courses);
    //console.log('/modules info /',module.relationships.course);
    const url = environment.base_url ;
    this.httpClient1.get<any>(url+'/api/v1/modules/'+module.id+'/relationships/course').subscribe(res => {
      this.c = res.data;
      //console.log(this.c);
      this.router.navigate(['/modules/' + this.c.id+ '/matieres/' + module.id]);
    })


  }

  test(i,l)
  { let tr : boolean = false ;
    if (l.length==1)
    {
      if (i==l)
      {
        tr= true ;
      }
    }
    else
     {
       if ( l.includes(i) )
       {
         tr = true ;
       }
     }
     return tr ;
  }

}
