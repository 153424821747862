<div class="liste-courses mt-4" *ngFor="let course of courses">
  <div class="row" *ngIf="!loading; else l">
  <div class="col-md-8 ">
    <div class="information-course">
      <button class="btn btn-categorycourse mb-2">{{course.category}}</button>
      <div class="titlecourse mb-2">{{'Course'|translate}} {{course.id}} - {{course.title}}</div>
      <div class="starday mb-2"><span>{{'Start Date'|translate}} : </span> {{course.course_start_date |date:'dd.MM.yyyy'}}</div>
      <div class="endday mb-2"><span>{{'End Date'|translate}} : </span> {{course.course_end_date |date:'dd.MM.yyyy'}}</div>
      <div class="module mb-2"><span>{{'Modules'|translate}} : </span> {{course.modules_count}}</div>
      <div class="certification" *ngIf="course.certificate"><i class="category-icon icon-certificate-black ng-star-inserted"></i></div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="p-3 text-center">
      <!--<div class="status mb-2">
        {{'status'|translate}}
      </div>-->
      <ng-container *ngIf="!course.closed" [ngSwitch]="data.status">
        <button *ngSwitchCase="1" class="btn btn-acced btn-default-blue">{{'accepted'|translate}}</button>
        <button *ngSwitchDefault class="btn btn-acced btn-default-blue">{{'applied'|translate}}</button>
        <button *ngSwitchCase="3" class="btn btn-acced btn-refus">{{'canceled'|translate}}</button>
        <button *ngSwitchCase="2" class="btn btn-acced btn-refus">{{'refused'|translate}}</button>
      </ng-container>

      <button *ngIf="course.closed" class="btn btn-acced btn-comp">{{'completed'|translate}}</button>


      <div *ngIf="course.closed" class="certif mt-2">
        <!-- <img src="/assets/img/certification.svg" class="float-left" alt="certification"> -->
        <span class="certif-download">{{'certification' |translate}}</span>
        <span class="download">
            <a (click)="downloadCertificate(course.id)"><img src="/assets/img/download.svg" alt="download"></a>
        </span>
      </div>

      <div class="fixed-btn-acced" *ngIf="course.closed; else closed">
        <button [routerLink]="'/profile/my-results/course/'+course.id" class="btn btn-princ-acced">
          {{'access results'|translate}}
        </button>
      </div>

      <ng-template #closed>
        <div class="fixed-btn-acced" *ngIf="data.status === 0">
          <button (click)="cancel()"  class="btn btn-default-white">
            {{'cancel'|translate}}
          </button>
        </div>
        <div class="fixed-btn-acced" *ngIf="data.status === 1">
          <button [routerLink]="'/courses/'+course.id" class="btn btn-default-white">
            {{'access course'|translate}}
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</div>
</div>
<ng-template #l>
  <app-loading-dialog></app-loading-dialog>
</ng-template>
