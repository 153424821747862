<!--
<div class="row lg:tail-w-3/4 tail-m-auto">
  <div class="banner-block col-md-8 col-lg-6">
    <img class="illustration img-fluid" [src]="img" alt="img">
  </div>
  &lt;!&ndash;<div class="banner-title-block hidden-md col-lg-6">&ndash;&gt;
    &lt;!&ndash;<h1 class="banner-title">{{title}}</h1>&ndash;&gt;
  &lt;!&ndash;</div>&ndash;&gt;
  &lt;!&ndash;<div class="title-block col-md-8">&ndash;&gt;
    &lt;!&ndash;<div class="wrapper d-flex flex-column">&ndash;&gt;
      &lt;!&ndash;&lt;!&ndash;<h5 class="category" *ngIf="category">{{category}}</h5>&ndash;&gt;&ndash;&gt;
      &lt;!&ndash;<div class="title" *ngIf="title">&ndash;&gt;
        &lt;!&ndash;&lt;!&ndash;<span class="course-number">COURSE 123:</span>&ndash;&gt;&ndash;&gt;
        &lt;!&ndash;{{title}}&ndash;&gt;
      &lt;!&ndash;</div>&ndash;&gt;
    &lt;!&ndash;</div>&ndash;&gt;
  &lt;!&ndash;</div>&ndash;&gt;
</div>
-->
<div class="banner-section">
  <div class="container">
    <div class="row">
    <div class="col col-12 col-sm-6 ">
      <div class="banner-section__img-section">
        <img class="ml-auto img" [src]="img" alt="">
      </div>
    </div>
    <button (click)="backLink()" class="comeback" *ngIf="backLink" ><img src="/assets/img/icon-back.svg"><span>{{'back'|translate}}</span></button>

    <div class="col col-12 col-sm-6  title-section">
      <div class="title-banner">
        <div class="category-field field" [hidden]="!category">
          <h1 class="category-field__title ok" *ngIf="!link">{{category}}</h1>
          <h1 class="category-field__title ok" *ngIf="link" [routerLink]="link">{{category}}</h1>
        </div>
        <div class="large-title-field field" [hidden]="!largeTitle">
          <h2 class="large-title" [innerHTML]="largeTitle"></h2>
        </div>
        <div class="second-title-field field" [hidden]="!title">
          <h2 class="second-title" [innerHTML]="title"></h2>
        </div>

      </div>
    </div>
  </div>
  </div>
</div>


