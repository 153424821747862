import {Resource, DocumentCollection, DocumentResource} from 'ngx-jsonapi';
import {Lesson} from './lesson';
import {File} from './file';


export class Document  extends Resource {
  public attributes = {
    title: '',
  };
  public relationships = {
    lesson: new DocumentResource<Lesson>(),
    files: new DocumentCollection<File>(),
  };

}
