import { Injectable } from '@angular/core';
import {Service} from 'ngx-jsonapi';
import {File} from '../models/file';

@Injectable({
  providedIn: 'root'
})
export class FilesService extends Service<File> {
  public resource = File;
  public type = 'files';


  constructor() {
    super();
    this.register();
  }


}
