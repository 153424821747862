import {Injectable} from '@angular/core';
import {Service} from 'ngx-jsonapi';
import {QuestionsService} from './questions.service';
import {McqQuestionsService} from './mcq-questions.service';
import {GroupWork} from '../models/group-work';

@Injectable({
  providedIn: 'root'
})
export class GroupWorksService extends Service<GroupWork> {
  public resource = GroupWork;
  public type = 'group-works';

  constructor(
    private questionsService: QuestionsService,
    private mcqService: McqQuestionsService,
  ) {
    super();
    this.register();
  }
}
