<div class="tail-p-4">
  <ng-container *ngIf="question">
    <h2 class="tail-mb-2 tail-text-blue-500 tail-text-md">{{question.attributes.question}}</h2>
    <div>
      <mat-form-field #input aria-label="Select an option">

        <input type="text" matInput name="answer" [value]="value">

      </mat-form-field>
    </div>
  </ng-container>
</div>
