import { Injectable } from '@angular/core';
import {Service} from 'ngx-jsonapi';
import {Video} from '../models/video';

@Injectable({
  providedIn: 'root'
})
export class VideosService extends Service<Video> {
  public resource = Video;
  public type = 'video-lessons';


  constructor() {
    super();
    this.register();
  }
}
