import {Component, Input, OnInit} from '@angular/core';
import {ParticipationService} from '../../services/participation.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-application-card',
  templateUrl: './application-card.component.html',
  styleUrls: ['./application-card.component.scss']
})
export class ApplicationCardComponent implements OnInit {

  @Input() data;
  loading = false;
  organisation=null;
  dateNow = moment().format('YYYY-MM-DD');
  currentUser=null;
  courses ;
  constructor(
    private participationService: ParticipationService,
    private authService: AuthService,
    private toastr: ToastrService,
    private httpClient: HttpClient ,

  ) {
  }

  ngOnInit(): void {
    this.authService.currentUser.subscribe(user => {
      this.currentUser=user;
    });





   /* if(this.data && this.data.organisation_id) {
      this.loading = true;
      this.authService.get_organisation(this.data.organisation_id).subscribe((result: any) => {
        if(result) {
          this.organisation= result.organisation;
        }
        this.loading = false;
      });
    }*/
  }

  get course() {
    return this.data?.course ?? {};
  }

  get isCompleted () {
    if(this.data.course) {
      return  this.dateNow > this.data.course.course_end_date ? true :false;
    }
    else return false;
  }

  get organisation_title() {    
    return this.organisation.title ?? null;
  }

  cancel() {
    this.loading = true;
    this.participationService.cancel(this.course.id)
      .subscribe(res => {
        this.loading = false;
        if (res && !res['error'])
          this.data.status = 3;
        else if (res && res['error'])
          this.toastr.error('Error while cancelling participation in course');
      }, err => {
        this.toastr.error('Error while cancelling participation in course');
        this.loading = false;
      });
  }

  downloadCertificate(course_id) {
    const urlCertif=environment.get_certificate_path.replace('{course}', course_id).replace('{user_id}',this.currentUser?.id)
    const a = document.createElement('a');
    a.href = urlCertif;
    a.download="download";
    a.click();
  }

}
