<div class="title-lesson-document my-2">{{'Links'|translate}}</div>
<div *ngIf="link">
  <div class="container-fluid">
    <div class="row">
      <div *ngFor="let f of files" class="col-md-4 border-primary ">
        <div class="card-links my-3" (click)="navigate(f)" >
          <div class="link-img">
            <a  >
              <img src="/assets/img/links-icon.svg" alt="link">
            </a>
          </div>
          <div class="link-title btn btn-begin">{{'Begin'|translate}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
