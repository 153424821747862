import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ChatListItemComponent} from '../chat-list-item/chat-list-item.component';
import {TwilioService} from '../../services/twilio.service';
import {UsersService} from '../../services/users.service';
import {Professor} from '../../models/professor';
import {User} from '../../models/user';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-chat-window-support',
  templateUrl: './chat-window-support.component.html',
  styleUrls: ['./chat-window-support.component.scss']
})
export class ChatWindowSupportComponent extends ChatListItemComponent {
  room = '';
  message = '';
  replySelected = null;
  channel = null;

  @Input() professor: Professor | User;

  constructor(
    ) {
    super();
  }

  @Input('room')
  set _room(room) {

  }


  send(box) {
    if (!this.message) {
      return;
    }

    const attributes: { parent?: string | null } = {};

    if (this.replySelected) {
      attributes.parent = this.replySelected.sid;
    }

    //console.log("ahywa chaine" ,this.channel);
    this.channel.sendMessage(this.message, attributes).then(res => {
      this.message = '';
      this.replySelected = null;
      box.scrollTop = box.scrollHeight;
    });
  }


  dragEnded($event, message) {
    $event.source.reset();
    this.replySelected = message;
  }

  getChildren(sid) {
    return this.children[sid] ? this.children[sid] : null;
  }

  protected parseMessage(m) {
    if (m.attributes.parent) {
      this.addChild(m.attributes.parent, m);
      return null;
    }
    return m;
  }

  protected fetchMessages(channel, size = 10, anchor = 0) {
    channel.getMessages().then(list => {
      this.messages = list.items.sort((a: any, b: any) => {
        return b.index * 1 - a.index * 1;
      }).map(m => {
        return this.parseMessage(m);
      }).filter(m => !!m);
      //console.log("ahwa messaget",this.messages);

    }
    );

  }


}
