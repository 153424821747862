<div class="message-container px-3" [class.reverse]="isCurrent">
  <div class="tail-break-words message" [class.current-user]="isCurrent" [class.is-child]="isChild">
    <div>{{body}}</div>
    <div class="tail-text-white-500 size-name" >{{name}}</div>
    <ng-container *ngIf="children">
      <ng-container *ngFor="let m of children">
        <app-message  [isChild]="true" [children]="null" [message]="m"></app-message>
      </ng-container>
    </ng-container>
    <div class="message-timestamp">
      {{ forNow(message.timestamp)   }}
      <!--<span class="message-read-tick" *ngIf="isRead">tick</span>-->
    </div>
  </div>
</div>
<!-- (click)="userPopup()" -->
