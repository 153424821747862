<div class="row mt-4">
  <div class="col-md-8 ">
    <div class="information-course">
      <button
        class="btn btn-categorycourse mt-2"
        *ngIf="module"
      >{{module.attributes.category}}</button>
      <div class="titlecourse mt-3">course 71 - lorem ipsum dolorum sit amet consecteur</div>
      <div class="note-resultat mt-3">
        <span>18/20</span>
      </div>
    </div>
  </div>
  <div class="col-md-4 bg-status-course">
    <div class="py-3">
      <div class="certif mt-2">
        <img src="/assets/img/certification.svg" class="float-left" alt="certification">
        <span class="certif-download">certification</span>
        <span class="download">
                                                    <a routerLink="#"><img src="/assets/img/download.svg" alt="download"></a>
                                                </span>
      </div>
      <div class="result-btn-acced mt-3">
        <button class="btn btn-princ-acced">access results</button>
      </div>
    </div>
  </div>
</div>
