import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {User} from '../../models/user';
import {UsersService} from '../../services/users.service';
import {AuthService} from '../../services/auth.service';
import {TwilioV2Service} from '../../services/twilio-v2.service';
import {MatDialog} from '@angular/material';
import {UserCardPopupComponent} from '../user-card-popup/user-card-popup.component';
import {Message} from 'twilio-chat/lib/message';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs/operators';
import * as moment from 'moment';
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnChanges {
  public professors;
  //name ;
  @Input() message : Message;
  @Input() isChild = false;
  @Input() children;
  @Output() replyEvent = new EventEmitter();
  user: any = null;
  currentUser = null;
  //public  profsnames;
  constructor(
    private userService: UsersService,
    private authService: AuthService,
    private twilioV2Service: TwilioV2Service,
    private matDialog: MatDialog,
    private httpClient : HttpClient,
  ) {
  }
  ngOnInit(): void {
    // const url = environment.base_url ;
    // this.httpClient.get<any>(url+'/api/v1/professors/').subscribe(ref => {
    //   this.professors = ref.data;
    //   for (let professor of this.professors)
    //   {
    //     this.profsnames.push(...professor.attributes.email)
    //   }
    //   //console.log("ahwama les prrofs name " ,this.profsnames)
    // });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.message) {
      return;
    }
    /*this.userService.getUser(changes.message.currentValue.author).subscribe(res => {
      this.user = res;
    });*/
    this.twilioV2Service.getUser(changes.message.currentValue.author)
      .subscribe(user => {
        this.user = user;
        //console.log("ahwa l user " ,this.user)
        //  if (this.user.friendlyName==null) {
        //     for (let professor of this.professors)
        //     { ////console.log(professor.attributes.email);
        //       if (professor.attributes.email==this.user.identity)
        //       {
        //         this.name=professor.attributes.first_name + ' ' + professor.attributes.last_name;
        //       }
        //     }
        //   }else {
        //     this.name=this.user.attributes.first_name + ' ' + this.user.attributes.last_name;
        //   }
      });
    this.authService.currentUser.subscribe(current => {
      this.currentUser = current;
    });
  }
  get body() {
    if (!this.message) {
      return '';
    }
    return this.message.body;
  }
  get name ()
  {
    try {
      // if (this.user.identity.indexOf("@")!=-1) {
      //   ////console.log("tout les profss", this.professors);
      //   for (let professor of this.professors)
      //   {
      //     if (professor.attributes.email==this.user.identity)
      //     {
      //       return professor.attributes.first_name + ' ' + professor.attributes.last_name;
      //     }
      //   }
      // }else {
        return this.user.attributes.first_name + ' ' + this.user.attributes.last_name;
     // }
    }
    catch(e) {
      ////console.log('Error:', e);
    }
  }
  // get name() {
  //   if (!this.user) {
  //     //console.log("ahwa l user chat ",this.user)
  //     return "this.user.identity";
  //   }
  //   return this.user.attributes.first_name + ' ' + this.user.attributes.last_name;
  // }
  get isCurrent() {
    if (!(this.currentUser && this.message)) {
      return false;
    }
    return this.currentUser.id === Number.parseInt(this.message.author, 0);
  }
  userPopup() {
    this.matDialog.open(UserCardPopupComponent);
  }
  isRead() {
    return this.message?.channel?.lastConsumedMessageIndex >= this.message?.index;
  }
  forNow(time)
  { //console.log(time);
    moment.locale('fr');
    return moment(time).fromNow();
  }
}
