import {Injectable} from '@angular/core';
import {Autoregister, Service} from 'ngx-jsonapi';
import {User} from '../models/user';
import {of} from 'rxjs';
import {tap} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
//@Autoregister()
export class UsersService extends Service<User> {
  public resource = User;
  public type = 'users';
  private usersCache = new Map<string, User>();


  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }



  getUser(id: string) {

    if (this.usersCache.has(id)) {
      return of(this.usersCache.get(id));
    }
    return this.get(id).pipe(tap(res => {
      this.usersCache.set(id, res);
    }));
  }

  getHotlineUser(user_id,anonym_id){
    return this.httpClient.get(environment+'/api/hotline/getHotlineUser/'+user_id+'/'+anonym_id);
  }
}
