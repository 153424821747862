import {DocumentCollection, DocumentResource, Resource} from 'ngx-jsonapi';
import {Module} from './module';
import {LiveLesson} from './live-lesson';
import {Document} from './document';
import {Podcast} from './podcast';
import {Video} from './video';
import {LinkLesson} from "./link-lesson";

export class Lesson  extends Resource {
  public attributes = {
    title: '',
    description: '',
    duration: 0,
    grades :''
  };



  public relationships = {
    module: new DocumentResource<Module>(),
    'lessonable': new DocumentResource<LiveLesson>(),
    // @ts-ignore
    'lessonable': new DocumentResource<LinkLesson>(),
    // @ts-ignore
    'lessonable': new DocumentResource<Document>(),
    // @ts-ignore
    'lessonable': new DocumentResource<Podcast>(),
    // @ts-ignore
    'lessonable': new DocumentResource<Video>(),
  };
}
