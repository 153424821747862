<div class="main-header">
  <div class="level-header">
<nav class="navbar navbar-expand-lg navbar-light" [class.connected]="connected" [class.notconnected]="!connected">
  <a class="navbar-brand" routerLink="/home">
    <img class="header-logo" src="../../../assets/img/logo_header.svg">
  </a>

  <a class="mobile-login-link icon-login hidden-sm" href="/secure/login"></a>
  <button (click)="toggle()" class="navbar-toggler" type="button" data-toggle="collapse"
          data-target="navbarSupportContent">
    <span class="menu-bar"></span>
    <span class="menu-bar"></span>
    <span class="menu-bar"></span>
  </button>


  <div [class.show]="showMenu" class="collapse navbar-collapse " id="navbarSupportContent">
    <ul class="nav-lang-mobile">
      <li class="nav-item dropdown lang">
        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
           aria-expanded="false">{{languageService.currentLang|uppercase}}<span class="icon-lang-drop-down"></span></a>
        <div class="dropdown-menu select-lang">
          <a [class.active]="languageService.currentLang === 'fr' " class="dropdown-item" (click)="change('fr')">FR</a>
          <a [class.active]="languageService.currentLang === 'en' " class="dropdown-item" (click)="change('en')">EN</a>
          <a [class.active]="languageService.currentLang === 'ar' " class="dropdown-item" (click)="change('ar')">AR</a>
        </div>
      </li>
    </ul>
    <ul class="navbar-nav mobile-first-nav">
      <li class="nav-item">
        <a class="home-icon nav-link active" [routerLink]="'/home'"><i class="icon-home-run"></i></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'/how-it-works'"
           [routerLinkActive]="'active'" >{{'comment ça marche'|translate}}</a>
      </li>
       <li class="nav-item">
         <a class="nav-link"    [routerLink]="'/modules'" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true } ">{{'courses'|translate}}</a>
       </li>
      <li class="nav-item">
         <a class="nav-link" [routerLink]="'/matieres'" [routerLinkActive]="'active'">{{'Modules'|translate}}</a>
       </li>
      <li class="nav-item">
         <a class="nav-link" [routerLink]="'/cours'" [routerLinkActive]="'active'">{{'Lessons'|translate}}</a>
       </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'/calendar'" [routerLinkActive]="'active'"> {{'CALENDAR'|translate}}</a>
      </li>

      <!--<li class="nav-item">-->
        <!--<a class="nav-link" class="nav-link" [routerLink]="'/about-us'" [routerLinkActive]="'active'">-->
          <!--{{'ABOUT'|translate}}</a>-->
      <!--</li>-->
      <!-- <li class="nav-item social-moobile">
        <a class="nav-link" href="#"> <i class="icon-facebook"></i></a>
        <a class="nav-link" href="#"><i class="icon-twitter"></i></a>
        <a class="nav-link" href="#"><i class="icon-instagram"></i></a>
      </li> -->
    </ul>

  </div>
  <div class="nav-left-auto nav-connect" [class.show]="showMenu">
    <ul class="nav navbar-nav secondary-nav">
      <!-- the styles of this part added to header is not yet confirgured in responsive modes-->
      <li *ngIf="connected" class="nav-item profile-item dropdown">
        <a class="nav-link profile-img dropdown-toggle d-flex flex-row-reverse align-items-center"
           data-toggle="dropdown" role="button" aria-haspopup="true"
           aria-expanded="false" href="#">
          <img class="profile-picture profile" [src]="photo" #i (error)="i.src='/assets/img/soustraction_profile.png'"
               alt="profile picture">
          <!--<img class="overlay-logout" src="/assets/img/logout.svg" alt="logout">-->
        </a>
        <div class="dropdown-menu">
          <a class="dropdown-item" routerLink="/profile/my-information">{{'my information'|translate}}</a>
          <a class="dropdown-item" routerLink="/profile/mesmodules">{{'my courses'|translate}}</a>
          <a class="dropdown-item" routerLink="/profile/my-favorites">{{'my favorites'|translate}}</a>
          <a class="dropdown-item" routerLink="/profile/my-calendar">{{'my calendar'|translate}}</a>
          <a class="dropdown-item" routerLink="/profile/my-results">{{'my results'|translate}}</a>
          <a class="dropdown-item" (click)="navigate()" >{{'private messages'|translate}}</a>
          <a class="dropdown-item logout-item" (click)="logout()">{{'Logout'|translate}}</a>
          <span class="dropdown-item close-item" >X</span>
        </div>
        <p *ngIf="current_user"
           class="d-flex flex-row-reverse align-items-center name">{{ current_user.first_name.charAt(0) + '.' + current_user.last_name.charAt(0) }}</p>
        <a class="nav-link d-flex flex-row-reverse align-items-center" (click)="navigate()"><img class="navicon"
                                                                                                       src="/assets/img/notification-nav.svg"
                                                                                                       alt="message"><span></span></a>

        <!-- replace this icon with chat icon-->
        <a class="nav-link d-flex flex-row-reverse align-items-center" routerLink="/profile/my-notifications"><img
          class="navicon" src="/assets/img/bell-nav.svg" alt="notification"><span></span></a>
        <!-- replace this icon with notification icon-->
        <a class="nav-link d-flex flex-row-reverse align-items-center" routerLink="/profile/my-calendar"><img
          class="navicon" src="/assets/img/calendar-nav.svg" alt="agenda"><span></span></a>
        <!-- replace this icon with calendar icon-->
      </li>
      <li *ngIf="!connected" class="nav-item login-item" >
        <a class="nav-link login-link" [routerLink]="['/secure']">{{'LOGIN / REGISTER'|translate}}</a>
      </li>
      <li class="nav-item dropdown lang">
        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
           aria-expanded="false">{{languageService.currentLang|uppercase}}<span class="icon-lang-drop-down"></span></a>
        <div class="dropdown-menu select-lang">
          <a [class.active]="languageService.currentLang === 'fr' " class="dropdown-item" (click)="change('fr')">FR</a>
          <a [class.active]="languageService.currentLang === 'en' " class="dropdown-item" (click)="change('en')">EN</a>
          <a [class.active]="languageService.currentLang === 'ar' " class="dropdown-item" (click)="change('ar')">AR</a>
        </div>
      </li>
    </ul>
  </div>
</nav>
    <app-verification-email-block *ngIf="typeBlock === 'verification-email';else courseApplication">
    </app-verification-email-block>
    <ng-template #courseApplication>
      <app-application-course></app-application-course>
    </ng-template>
  </div>
</div>
