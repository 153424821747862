import { Input, Directive } from '@angular/core';

export type QuestionType = 'input' | 'mcq' | 'file';

export interface Answer {

  question_id: string;
  drupal_reference: string;
  value: string;
  type: QuestionType;
  is_correct: boolean;
  note: string;
  score: number;
}

@Directive()
export abstract class AbstractQuestionComponent {

  initialized = false;
  value = null;

  abstract selectedValue(): Answer;

  @Input('value')
  set _value(value) {
    if (this.initialized) {
      return;
    }
    this.value = value;
  }
}
