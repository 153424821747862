import {Component, Input, OnInit} from '@angular/core';
import {CoursesService} from "../../../services/courses.service";
import {LessonsService} from "../../../services/lessons.service";
import {ProfessorsService} from "../../../services/professors.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ModulesService} from "../../../services/modules.service";
import {ExamsService} from "../../../services/exams.service";
import {TestsService} from "../../../services/tests.service";
import {PodcastsService} from "../../../services/podcasts.service";
import {VideosService} from "../../../services/videos.service";
import {LiveLessonsService} from "../../../services/live-lessons.service";
import {LinkLessonService} from "../../../services/link-lesson.service";

@Component({
  selector: 'app-module-lessons',
  templateUrl: './module-lessons.component.html',
  styleUrls: ['./module-lessons.component.scss']
})
export class ModuleLessonsComponent implements OnInit {
  id: number;
   module;
  constructor(lessonsService: LessonsService,
              private route: ActivatedRoute,
              private router: Router, private modulesService: ModulesService) {
    lessonsService.register();
  }

  ngOnInit(): void {
    this.route.params
      .subscribe(
        (params: Params) => {
          this.id = params.idModule;
          this.modulesService.get(String(this.id), {include: ['lessons']}).subscribe(
            module => {
              this.module = module;
              //console.log('module :::', this.module);
            },
            () => {
              //console.log('tests...');
            },
            () => {
              //console.log('lessons :::', this.module.relationships.lessons);
            }
          );
        }
      );
  }
}
