import {DocumentCollection, DocumentResource, Resource} from 'ngx-jsonapi';
import {Course} from './course';
import {Professor} from './professor';
import {Lesson} from './lesson';
import {Exam} from './exam';
import {Test} from './test';
import {GroupWork} from "./group-work";

export class Module extends Resource {
  public attributes = {
    title: '',
    description: '',
    difficulty: '',
    status: '',
    grades:'',
    goals: '',
    estimation: '',
    duration: '',
    parent_id: null,
    old_id: ''
  };
  public relationships = {
    course: new DocumentResource<Course>(),
    professors: new DocumentResource<Professor>(),
    lessons : new DocumentCollection<Lesson>(),
    exams: new DocumentCollection<Exam>(),
    tests : new DocumentCollection<Test>(),
    groupWorks : new DocumentCollection<GroupWork>()
  };
}

