<div class="d-flex justify-content-center" *ngIf="loading; else result">
  <mat-spinner color="light" class="spinner"></mat-spinner>
</div>
<ng-template #result>

  <!-- <button   (click)="participate()" *ngIf="!isParticipated"
          class="secondary-button" >{{'Apply Now'|translate|uppercase}}</button> -->

  <ng-container *ngIf="participation && participation.status < 3">
    <div class="participation-status" [ngSwitch]="participation.status">
      <span *ngSwitchCase="0">{{'your participation is pending validation'|translate}}</span>
      <span *ngSwitchCase="2">{{'your participation has been denied'|translate}}</span>
      <span *ngSwitchCase="1">{{"you're a participant"|translate}}</span>
    </div>
  </ng-container>
  <button (click)="cancel()" *ngIf="withCancel&&participation && participation.status === 0" class="secondary-button">
    {{'cancel'|translate}}
  </button>
</ng-template>
