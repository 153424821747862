import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {Token} from '../models/token';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public static readonly SKIP_AUTH_HEADER = 'X-COA-SKIP-AUTH';

  constructor(
    private authenticationService: AuthService,
    private router: Router
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with basic auth credentials if available
    if (!request.url.includes(environment.base_url) || request.headers.has(AuthInterceptor.SKIP_AUTH_HEADER)) {
      if (request.headers.has(AuthInterceptor.SKIP_AUTH_HEADER)) {
        request = request.clone({
          headers: request.headers.delete(AuthInterceptor.SKIP_AUTH_HEADER)
        });
      }
      return next.handle(request);
    }
    const currentUser: Token = this.authenticationService.currentToken;

    /*if (Object.values(environment).includes(request.url)) {
      return next.handle(request);
    }*/

    if (currentUser) {
      if (!currentUser.isValid) {
      }
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.access_token}`
        }
      });
      return next.handle(request).pipe(catchError(err => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          this.authenticationService.logout().subscribe(
            (res) => {
              this.router.navigate(['/secure/login']).then(console.log);
            }
          );
        }
        return throwError(err);
      }));
    }
    return next.handle(request);
  }
}
