import {Injectable} from '@angular/core';
import {UsersService} from './users.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BehaviorSubject, of, ReplaySubject, throwError} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import {Storage} from '@ionic/storage';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {TwilioToken} from '../models/twilio-token';

declare var Twilio: any;

@Injectable({
  providedIn: 'root'
})
export class TwilioService {
  static readonly CHAT_TOKEN_KEY = 'chat-token';
  static readonly GENERAL_CHANNEL_UNIQUE_NAME = 'general';
  static readonly GENERAL_CHANNEL_NAME = 'General Channel';
  private accessManager = null;
  private client = null;
  private channels = null;


  public client$ = new ReplaySubject(1);
  public channels$ = new ReplaySubject(1);
  public channelRemoved$ = new BehaviorSubject(null);
  public channelAdded$ = new BehaviorSubject(null);

  constructor(
    private userService: UsersService,
    private httpClient: HttpClient,
    private storage: Storage
  ) {

    // @ts-ignore

    this.client$.subscribe(client => {
      this.getChannels().subscribe(list => {
        this.channels$.next(this.channels);
      });
    });

  }


  fetchFreshToken() {
    return this.httpClient.get(environment.authenticated.chat_auth).pipe(map((res: any) => {
      return new TwilioToken(res.token, res.identity, (new Date().getTime()));
    }));
  }

  fetchFreshTokenAnonym(us) {
    return this.httpClient.post(environment.chat_auth_anonym,us,).pipe(map((res: any) => {
      return new TwilioToken(res.token, res.identity, (new Date().getTime()));
    }));
  }
  getTokenAnonym(us) {
    return fromPromise(this.storage.get(TwilioService.CHAT_TOKEN_KEY)).pipe(
      switchMap((res: TwilioToken) => {
        if (res && res.isValid) {
          return of(res);
        }
        return this.fetchFreshTokenAnonym(us).pipe(tap(t => this.saveToken(t)));
      })
    );
  }

  getClientAnonym(us) {
    if (this.client) {
      return of(this.client);
    }
    return this.getTokenAnonym(us).pipe(
      switchMap(
        token => {
          return fromPromise(Twilio.Chat.Client.create(token.token).then(
            client => {
              this.client = client;
              this.client$.next(this.client);
              this.client.on('tokenAboutToExpire', () => {
                this.fetchFreshTokenAnonym(us).subscribe(t => {
                  this.client.updateToken(t.token).then((res) => {
                  });
                });
              });

              return client;
            }
          ));
        }
      )
    );
  }
  saveToken(token: TwilioToken) {
    return fromPromise(this.storage.set(TwilioService.CHAT_TOKEN_KEY, token));
  }


  getToken() {
    return fromPromise(this.storage.get(TwilioService.CHAT_TOKEN_KEY)).pipe(
      switchMap((res: TwilioToken) => {
        if (res && res.isValid) {
          return of(res);
        }
        return this.fetchFreshToken().pipe(tap(t => this.saveToken(t)));
      })
    );
  }


  getClient() {
    if (this.client) {
      return of(this.client);
    }
    return this.getToken().pipe(
      switchMap(
        token => {
          return fromPromise(Twilio.Chat.Client.create(token.token).then(
            client => {
              this.client = client;
              this.client$.next(this.client);
              this.client.on('tokenAboutToExpire', () => {
                this.fetchFreshToken().subscribe(t => {
                  this.client.updateToken(t.token).then((res) => {
                  });
                });
              });

              return client;
            }
          ));
        }
      )
    );
  }


  getChannels(fresh = false) {
    if (this.channels && !fresh) {
      return of(this.channels);
    }
    return this
      .getClient()
      .pipe(
        switchMap((client: any) => {
            return fromPromise(client.getSubscribedChannels());
          }
        ),
        map((channels: any) => {
          this.channels = this.sortChannels(channels.items);
          this.setupChannelEvents();
          return this.channels;
        })
      );

  }


  sortChannels(items = []) {
    return items.sort((a, b) => {
      if (a.friendlyName === TwilioService.GENERAL_CHANNEL_NAME) {
        return -1;
      }
      if (b.friendlyName === TwilioService.GENERAL_CHANNEL_NAME) {
        return 1;
      }
      return a.friendlyName.localeCompare(b.friendlyName);
    });
  }


  createChannel(channel, isPrivate = false) {
    return this.getClient().pipe(
      switchMap((client: any) => {
          return fromPromise(client.createChannel({
            friendlyName: channel,
            uniqueName: channel,
            isPrivate
          })).pipe(
            catchError(err => {
              console.log('err', err);
              return this.getChannel(channel);
            })
          );
        },
      ),
    );
  }

  getChannelAnonyme(channel,user) {
    return this.getClientAnonym(user).pipe(
      switchMap((client: any) => {
        console.log("d5alt lel getchannel" , channel) ;
        return client.getChannelByUniqueName(channel);
      })
    );
  }

  getChannel(channel) {
    return this.getClient().pipe(
      switchMap((client: any) => {
        console.log("d5alt lel getchannel" , channel) ;
        return client.getChannelByUniqueName(channel);
      })
    );
  }

  setupChannelEvents() {
    /*this.client.on('channelAdded', (channel) => {
      console.log('Channel added: ' + channel.friendlyName);
      this.channels.push(channel);
      this.channelAdded$.next(channel);
    });*/
    this.client.on('channelRemoved', (channel) => {
      this.channels = this.channels.filter(c => {
        return c.uniqueName !== channel.uniqueName;
      });
      this.channelRemoved$.next(channel);
    });
  }

  GetUsers(data)
  {
    return this.httpClient.get(environment.get_users+'/'+data.id).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }
}
