<div class="tail-p-4">

<ng-container *ngIf="question">
  <h2 class="tail-mb-2 tail-text-blue-500 tail-text-md"
  [innerHTML]="question.attributes.question"
  ></h2>
  <div>
    <mat-radio-group [value]="value" class="tail-flex tail-flex-col" #radio aria-label="Select an option">

      <mat-radio-button *ngFor="let answer of answers" value="{{answer}}">{{answer}}</mat-radio-button>

    </mat-radio-group>
  </div>
</ng-container>
</div>
