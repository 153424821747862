import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {CoursesService} from 'src/app/services/courses.service';
import {ParticipationService} from 'src/app/services/participation.service';
import {MatDialog} from '@angular/material';
import {AuthService} from 'src/app/services/auth.service';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';
import {Course} from 'src/app/models/course';
import {tap} from 'rxjs/operators';
import {NotAuthenticatedDialogComponent} from '../not-authenticated-dialog/not-authenticated-dialog.component';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {of} from 'rxjs';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-application-course',
  templateUrl: './application-course.component.html',
  styleUrls: ['./application-course.component.scss']
})
export class ApplicationCourseComponent implements OnInit {


  current_user;
  data;
  dateNow = '';
  course: Course;
  participation = null;
  isParticipated = null;
  loading = false;
  outOfApplication = false;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private coursesService: CoursesService,
    private participationService: ParticipationService,
    private dialogService: MatDialog,
    public ngxSmartModalService: NgxSmartModalService,
    private router: Router,
    private toast: ToastrService
  ) {

    this.dateNow = moment().format('YYYY-MM-DD');
    this.loading = true;
    //console.log('loading', this.loading);
    this.route.params
      .subscribe(
        (params: Params) => {
          this.coursesService.get(String(params.id))
            .pipe(tap(course => {
              //console.log('course block', course);
              this.course = course;
              if (this.course && this.course.attributes) {
                const isBeforeStart = moment().isBefore(moment(this.course.attributes.application_start_date));
                const isAfterStart = moment().isAfter(moment(this.course.attributes.application_end_date));
                if ((this.dateNow < this.course.attributes.application_start_date || this.dateNow > this.course.attributes.application_end_date)) {
                  this.outOfApplication = true;
                }


                this.authService.getCurrentUser().subscribe(({current_user}) => {
                    if (current_user) {
                      this.current_user = current_user;
                      this.fetchParticipation().subscribe();
                    } else {
                      this.isParticipated = false;

                    }
                    this.loading = false;
                  },
                  err => {
                    this.isParticipated = false;
                    this.loading = false;
                    //console.log(err);
                  }
                );

              }

              // } else {
              //   this.loading = false;

              // }


            }))
            .subscribe(data =>
              console.log('data course', data)
            );
        }
      );
    // }
  }

  ngOnInit(): void {
  }


  participate() {
    if (this.course && this.course.id) {
      this.participationService
        .participate(this.course.id)
        .subscribe(
          (res: any) => {
            //console.log('res part', res);
            if (res && res.participation) {
              this.ngxSmartModalService.get('applicationCourseSuccess').open();
              this.isParticipated = true;
              setTimeout(() => {
                if (this.router && this.router.url === '/courses/' + this.course.id) {
                  window.location.reload();
                } else {
                  this.router.navigate(['/courses/' + this.course.id]);
                }
              }, 5000);
            } else {
              this.toast.error('Error while applying in this course');
            }
          },
          err => {
            //console.log('error participating', err);
            if (err.status === 401) {
              this.dialogService.open(NotAuthenticatedDialogComponent, {data: {message: marker('To continue, please log in or register')}});
            } else {
              this.toast.error('Error while applying in this course');
            }
          }
        );
    }
  }

  fetchParticipation() {
    if (!this.course) {
      return of(null);
    }
    return this.participationService
      .getParticipation(this.course.id)
      .pipe(tap(({participation}: any) => {
        if (participation) {
          this.participation = participation;
          if ((this.participation.status === 0 || this.participation.status === 1)) {
            this.isParticipated = true;
          } else {
            this.isParticipated = false;
          }
          this.loading = false;
        } else {
          this.isParticipated = false;
          this.loading = false;
        }
      }));
  }

}
