import {DocumentCollection, DocumentResource, Resource} from 'ngx-jsonapi';
import {Course} from './course';


export class Student extends Resource {
  public attributes = {
    first_name : '',
    last_name : '',
    email : '',
    phone : '',
    photo: '',
    age: '',
  };
  public relationships = {
    courses: new DocumentCollection<Course>(),
  };
}

