import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoursesComponent} from './courses/courses.component';
import {LessonsComponent} from './lessons/lessons.component';
import {ModulesComponent} from './modules/modules.component';
import {ProfessorsComponent} from './professors/professors.component';
import {CourseListComponent} from './courses/course-list/course-list.component';
import {CourseItemComponent} from './courses/course-list/course-item/course-item.component';
import {RouterModule} from '@angular/router';
import {CoursesService} from '../services/courses.service';
import {ModulesService} from '../services/modules.service';
import {ModulelistComponent} from './modules/modulelist/modulelist.component';
import {ModuleItemComponent} from './modules/modulelist/module-item/module-item.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {ChatBoxComponent} from './chat-box/chat-box.component';
import {ChatListItemComponent} from './chat-list-item/chat-list-item.component';
import {ChatWindowComponent} from './chat-window/chat-window.component';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatChipsModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatRadioModule
} from '@angular/material';
import {FormsModule} from '@angular/forms';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {MessageComponent} from './message/message.component';
import {MessageSupportComponent} from './message-support/message-support.component'
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PrivateChatWindowComponent} from './private-chat-window/private-chat-window.component';
import {CreatePrivateChannelComponent} from './create-private-channel/create-private-channel.component';
import {LessonListComponent} from './lessons/lesson-list/lesson-list.component';
import {LessonItemComponent} from './lessons/lesson-list/lesson-item/lesson-item.component';
import {ProfessorItemComponent} from './professors/professor-list/professor-item/professor-item.component';
import {ProfessorListComponent} from './professors/professor-list/professor-list.component';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {PodcastsComponent} from './podcasts/podcasts.component';
import {PodcastListComponent} from './podcasts/podcast-list/podcast-list.component';
import {PodcastItemComponent} from './podcasts/podcast-list/podcast-item/podcast-item.component';
import {VideosComponent} from './videos/videos.component';
import {VideoListComponent} from './videos/video-list/video-list.component';
import {VideoItemComponent} from './videos/video-list/video-item/video-item.component';
import {DocumentsComponent} from './documents/documents.component';
import {DocumentListComponent} from './documents/document-list/document-list.component';
import {DocumentItemComponent} from './documents/document-list/document-item/document-item.component';
import {PodcastDetailComponent} from './podcasts/podcast-detail/podcast-detail.component';
import {DocumentDetailComponent} from './documents/document-detail/document-detail.component';
import {VideoDetailComponent} from './videos/video-detail/video-detail.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {ZoomMeetingComponent} from './zoom-meeting/zoom-meeting.component';
import {MultichoiceQuestionComponent} from './questions/multichoice-question/multichoice-question.component';
import {InputQuestionComponent} from './questions/input-question/input-question.component';
import {FileQuestionComponent} from './questions/file-question/file-question.component';

import {DialogModuleComponent} from './modules/dialog-module/dialog-module.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ModuleLessonsComponent} from './modules/module-lessons/module-lessons.component';
import {LiveCoursesComponent} from './live-courses/live-courses.component';
import {LiveDetailComponent} from './live-courses/live-detail/live-detail.component';
// TODO: PLEASE FIX THE NAMING OF THIS CLASS
import {CalendarModele} from './calendar/calendar.modele';
import {ParticipationButtonComponent} from './participation-button/participation-button.component';
import {DescriptionBlockComponent} from './description-block/description-block.component';
import {TitleBlockComponent} from './title-block/title-block.component';
import {SecureTabSwitcherComponent} from './secure-tab-switcher/secure-tab-switcher.component';
import {UsersComponent} from './users/users.component';
import {TranslateModule} from '@ngx-translate/core';
import {MoreCoursesBlockComponent} from './more-courses-block/more-courses-block.component';
import {CourseTeaserComponent} from './course-teaser/course-teaser.component';
import {LineTruncationLibModule} from 'ngx-line-truncation';
import {NotAuthenticatedDialogComponent} from './not-authenticated-dialog/not-authenticated-dialog.component';
import {NotParticipantDialogComponent} from './not-participant-dialog/not-participant-dialog.component';
import {ProfessorTeaserComponent} from './professor-teaser/professor-teaser.component';
import {LoadingDialogComponent} from './loading-dialog/loading-dialog.component';
import {MoreModulesBlockComponent} from './more-modules-block/more-modules-block.component';
import {ParticipantsBlockComponent} from './participants-block/participants-block.component';
import {MoreLessonsBlockComponent} from './more-lessons-block/more-lessons-block.component';
import {TestResultComponentsComponent} from './test-result-components/test-result-components.component';
import {LockedModuleDialogComponent} from './locked-module-dialog/locked-module-dialog.component';
import {LinkDetailComponent} from './link-detail/link-detail.component';
import {NotificationsComponent} from './notifications/notifications.component';
import {CountdownModule} from 'ngx-countdown';
import { ProfileTabsComponent } from './profile-tabs/profile-tabs.component';
import { ApplicationCardComponent } from './application-card/application-card.component';
import { PopupComponent } from './popup/popup.component';
import { EvaluationCardComponent } from './evaluation-card/evaluation-card.component';
import { PopupApplyComponent } from './popup-apply/popup-apply.component';
import { VerificationEmailBlockComponent } from './verification-email-block/verification-email-block.component';
import { ApplicationCourseComponent } from './application-course/application-course.component';
import { ChannelsListComponent } from './chat/channels-list/channels-list.component';
import { ChannelItemComponent } from './chat/channel-item/channel-item.component';
import { ChannelWindowComponent } from './chat/channel-window/channel-window.component';
import { MessageWindowComponent } from './chat/message-window/message-window.component';
import { MessageItemComponent } from './chat/message-item/message-item.component';
import { NewsletterRecommendationPopupComponent } from './newsletter-recommendation-popup/newsletter-recommendation-popup.component';
import { UserCardPopupComponent } from './user-card-popup/user-card-popup.component';
import {MomentModule} from 'ngx-moment';
import { CourseFilterPipe } from './courses/course-list/coursefilter';
import { UsersfilterPipe } from './participants-block/usersfilter.pipe';
import { ChatWindowSupportComponent } from './chat-window-support/chat-window-support.component';

@NgModule({
  declarations: [
    CoursesComponent,
    LessonsComponent,
    ModulesComponent,
    ProfessorsComponent,
    CourseListComponent,
    CourseItemComponent,
    LinkDetailComponent,
    ModulelistComponent,
    ModuleItemComponent,
    ProfessorListComponent,
    ProfessorItemComponent,
    CourseFilterPipe,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ChatBoxComponent,
    ChatListItemComponent,
    ChatWindowComponent,
    MessageComponent,
    MessageSupportComponent,
    PrivateChatWindowComponent,
    CreatePrivateChannelComponent,
    LessonListComponent,
    LessonItemComponent,
    PodcastsComponent,
    PodcastListComponent,
    PodcastItemComponent,
    VideosComponent,
    VideoListComponent,
    VideoItemComponent,
    DocumentsComponent,
    DocumentListComponent,
    DocumentItemComponent,
    PodcastDetailComponent,
    DocumentDetailComponent,
    VideoDetailComponent,
    ZoomMeetingComponent,
    MultichoiceQuestionComponent,
    InputQuestionComponent,
    FileQuestionComponent,
    DialogModuleComponent,
    ModuleLessonsComponent,
    ParticipationButtonComponent,
    DescriptionBlockComponent,
    TitleBlockComponent,
    LiveCoursesComponent,
    LiveDetailComponent,
    SecureTabSwitcherComponent,
    UsersComponent,
    MoreCoursesBlockComponent,
    CourseTeaserComponent,
    NotAuthenticatedDialogComponent,
    NotParticipantDialogComponent,
    ProfessorTeaserComponent,
    ProfessorTeaserComponent,
    LoadingDialogComponent,
    MoreModulesBlockComponent,
    ParticipantsBlockComponent,
    MoreLessonsBlockComponent,
    TestResultComponentsComponent,
    LockedModuleDialogComponent,
    NotificationsComponent,
    ProfileTabsComponent,
    ApplicationCardComponent,
    EvaluationCardComponent,
    PopupComponent,
    PopupApplyComponent,
    VerificationEmailBlockComponent,
    ChannelsListComponent,
    ChannelItemComponent,
    ChannelWindowComponent,
    MessageWindowComponent,
    MessageItemComponent,
    ApplicationCourseComponent,
    NewsletterRecommendationPopupComponent,
    UserCardPopupComponent,
    UsersfilterPipe,
    ChatWindowSupportComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    DragDropModule,
    MatAutocompleteModule,
    MatChipsModule,
    RouterModule,
    SwiperModule,
    MatRadioModule,
    NgxPaginationModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    CalendarModele,
    TranslateModule,
    LineTruncationLibModule,
    CountdownModule,
    NgxSmartModalModule,
    MomentModule

  ],
  exports: [
    CoursesComponent,
    LessonsComponent,
    ModulesComponent,
    CourseListComponent,
    CourseItemComponent,
    ModulelistComponent,
    ModuleItemComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ChatBoxComponent,
    CreatePrivateChannelComponent,
    LessonListComponent,
    ZoomMeetingComponent,
    MultichoiceQuestionComponent,
    InputQuestionComponent,
    FileQuestionComponent,
    DocumentDetailComponent,
    PodcastDetailComponent,
    VideoDetailComponent,
    ModuleLessonsComponent,
    LiveDetailComponent,
    ParticipationButtonComponent,
    DescriptionBlockComponent,
    TitleBlockComponent,
    SecureTabSwitcherComponent,
    UsersComponent,
    MoreCoursesBlockComponent,
    ProfessorTeaserComponent,
    ProfessorTeaserComponent,
    MoreModulesBlockComponent,
    ChatWindowComponent,
    LinkDetailComponent,
    PrivateChatWindowComponent,
    LoadingDialogComponent,
    ParticipantsBlockComponent,
    MoreLessonsBlockComponent,
    NotificationsComponent,
    MoreLessonsBlockComponent,
    ProfileTabsComponent,
    ApplicationCardComponent,
    PopupComponent,
    PopupApplyComponent,
    TestResultComponentsComponent,
    VerificationEmailBlockComponent,
    ChatListItemComponent,
    ChannelWindowComponent,
    ChatWindowSupportComponent
  ]
  ,
  providers: [
    CoursesService,
    ModulesService
  ],
})
export class SharedModule {
}
