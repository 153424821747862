import {DocumentCollection, DocumentResource, Resource} from 'ngx-jsonapi';
import {Module} from './module';
import {File} from './file';
import {Question} from './question';
import {McqQuestion} from './mcq-question';

export class GroupWork extends Resource {
  public attributes = {
    title: '',
    description: '',
    start_date: '',
    rules: '',
    end_date: '',
    typeGroupWork: ''
  };
  public relationships = {
    module: new DocumentResource<Module>(),
    files: new DocumentCollection<File>(),
    questions: new DocumentCollection<Question>(),
    mcqQuestions: new DocumentCollection<McqQuestion>(),
  };
}
