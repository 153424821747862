export class TwilioToken {
  constructor(
    public readonly token: string,
    public readonly identity: number,
    public readonly createdAt: number,
    public readonly duration: number = 3600
  ) {

  }


  public get isValid() {
    const now = new Date();
    return now.getTime() < this.createdAt + (this.duration * 1000);
  }
}
