import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CalendarView } from 'angular-calendar';

@Component({
  selector: 'mwl-calendar-utils-calendar-header',
  template: `
    <div class="row text-center">
      <div class="col-md-4">
        <div class="btn-group">
          <div
            class="btn btn-primary icon-flesh-left"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="viewDateChange.next(viewDate)"
          >
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
          </div>
          <div
            class="btn btn-primary icon-flesh-right"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="viewDateChange.next(viewDate)"
          >
          <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <h3 class="month-current-calendar">{{ viewDate | calendarDate: view + 'ViewTitle':locale }}</h3>
      </div>
      <div class="col-md-4">
        <div class="btn-group btn-cal-types">
          <div
            class="btn btn-primary btn-months"
            (click)="viewChange.emit('month')"
            [class.active]="view === 'month'"
          >
            Mois
          </div>
          <div
            class="btn btn-primary btn-weeks"
            (click)="viewChange.emit('week')"
            [class.active]="view === 'week'"
          >
            Semaine
          </div>
          <div
            class="btn btn-primary btn-days"
            (click)="viewChange.emit('day')"
            [class.active]="view === 'day'"
          >
            Jour
          </div>
        </div>
      </div>
    </div>
    <br />
  `,
})
export class CalendarHeaderComponent {
  @Input() view: CalendarView | 'month' | 'week' | 'day';

  @Input() viewDate: Date;

  @Input() locale = 'fr';

  @Output() viewChange: EventEmitter<string> = new EventEmitter();

  @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();
}
