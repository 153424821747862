import { Injectable } from '@angular/core';
import {Service} from 'ngx-jsonapi';
import {LiveLesson} from '../models/live-lesson';

@Injectable({
  providedIn: 'root'
})
export class LiveLessonsService extends Service<LiveLesson> {
  public resource = LiveLesson;
public type = 'live-lessons';
}
