<div  class="course-teaser" [class.active]="active" (click)="go()">
  <div class="card">
    <div class="picture-special">
      <div class="play">
        <img *ngIf="!course.attributes.video" #i [src]="course.attributes.photo" (error)="i.src = '/assets/img/close-up.png'" class="card-img-top" alt="picture course">
        <video *ngIf="course.attributes.video"  [src]="course.attributes.video" autoplay loop [muted]="true" class="card-img-top" alt="picture course" ></video>
      </div>
      <!--<div class="redirect-to-course">
        <img src="/assets/img/back.svg" alt="back">
      </div>-->
    </div>
    <div class="card-body">
      <button class="btn btn-category mb-3" >{{course.attributes.category}}</button>
      <h5 class="card-title-course" [line-truncation]="2">{{course.attributes.title}}</h5>
      <p class="card-text-course"
      [line-truncation]="3"
      [innerHTML]="course.attributes.description">
      </p>
      <div class="row mt-3">
        <div class="col col-9 display-icon">
          <i class="category-icon icon-certificate-black" *ngIf="course.attributes.certificate"></i>
          <!--<i class="category-icon icon-five-modules"></i>-->
          <span class="module-number"><span>{{modules.length}}</span> {{'Modules'|translate}}</span>
        </div>
        <div class="col col-3" *ngIf="course">
          <button class="btn btn-apply mt-1">{{'More'|translate}}</button>
        </div>
      </div>
    </div>
    <div class="line-card-bottom"></div>
  </div>
</div>




