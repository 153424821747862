import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-description-block',
  templateUrl: './description-block.component.html',
  styleUrls: ['./description-block.component.scss']
})
export class DescriptionBlockComponent implements OnInit {

  @Input() title = '';
  @Input() text = '';

  constructor() {
  }

  ngOnInit(): void {
  }




}
