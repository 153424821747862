<div class="element mb-1" [ngClass]="{'element-read':!unreadCount,'element-notread':unreadCount, 'selected':selected}">
  <div class="d-flex" >
    <div style="flex-basis: 50px;flex-grow: 0;flex-shrink: 0">
      <div class="avatar">
        <img #i [src]="pic"  (error)="i.src ='/assets/img/teamwork.svg'"  alt="profile picture">
      </div>
    </div>

    <div style="flex: auto; padding: .5rem">
      <div class="desc-info" *ngIf="member; else l" >
        <div class="d-flex justify-content-between">
          <span class="title-message" >{{friendlyName}}</span>
          <ng-container *ngIf="lastMessage">
            <div class="date-message">
              <span class="daily">{{ forNow(lastMessage.timestamp)   }}</span>
            </div>
          </ng-container>
        </div>
        <!-- <ng-container *ngIf="lastMessage">
          <div class="text-message" >
            <p>
              <span><b>{{lastMessage.body}}</b></span>
            </p>
          </div>
        </ng-container> -->
      </div>
    </div>
  </div>
</div>


<ng-template #l>
  {{'loading'|translate}}
</ng-template>
