<div class="window py-4">
  <div class="channel">
    <app-channels-list
      class="d-block scroll"
      [selected]="selected"
      (itemClicked)="itemClicked($event)"
    ></app-channels-list>
  </div>
  <div class="message">
    <!--<app-chat-window class="tail-h-full" *ngIf="selected" [channel]="selected">

    </app-chat-window>-->
    <app-message-window
      class="tail-h-full"
      *ngIf="selected; else empty" [channel]="selected">

    </app-message-window>
  </div>

</div>
<ng-template #empty>
  <div class="w-100 h-100 d-flex flex-column justify-content-center align-items-center ">
    <div>{{'Select a discussion'|translate}}</div>
  </div>
</ng-template>
