import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {Title} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  currentLang: 'fr' | 'en' | 'ar' = 'fr';

  constructor(
    private translate: TranslateService,
    private title: Title
  ) {
    this.currentLang = localStorage.getItem('lang') as 'fr' | 'en' | 'ar' ?? 'fr';
    this.changeLanguage(this.currentLang, false);
  }

  changeLanguage(lang: 'fr' | 'en' | 'ar', reload = true) {
    this.currentLang = lang;
    localStorage.setItem('lang', lang);
    document.dir = lang === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.setAttribute('lang', lang);
    this.translate.use(lang);
    this.title.setTitle(this.translate.instant('ISOGM'));

    if (reload) {
      window.location.reload();
    }

  }


  getCurrentLanguage(){
    return this.currentLang;
}
}
