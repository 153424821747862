import { Component, OnInit } from '@angular/core';
import {ModulesService} from '../../../services/modules.service';
import {CoursesService} from '../../../services/courses.service';
import {ProfessorsService} from '../../../services/professors.service';
import {LessonsService} from '../../../services/lessons.service';
import {DocumentCollection} from 'ngx-jsonapi';
import {Module} from '../../../models/module';
import {ExamsService} from '../../../services/exams.service';
import {TestsService} from '../../../services/tests.service';
import {McqQuestionsService} from '../../../services/mcq-questions.service';

@Component({
  selector: 'app-modulelist',
  templateUrl: './modulelist.component.html',
  styleUrls: ['./modulelist.component.scss']
})
export class ModulelistComponent implements OnInit {
  public modules: DocumentCollection<Module>;

  constructor(private modulesService: ModulesService, coursesService: CoursesService,
              professorsService: ProfessorsService, lessonsServices: LessonsService,
              examsService: ExamsService, testsService: TestsService,
              mcqQuestions: McqQuestionsService) {
    coursesService.register();
    professorsService.register();
    lessonsServices.register();
    examsService.register();
    testsService.register();
    mcqQuestions.register();
    modulesService.all({ include: [ 'tests.mcqQuestions' ]}).subscribe(
      modules => (this.modules = modules),
      () => {
      },
    () => {
        //console.log('modules tests: ', this.modules);
    }
    );
  }

  ngOnInit() {
  }

}
