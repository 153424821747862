import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-chat-list-item',
  templateUrl: './chat-list-item.component.html',
  styleUrls: ['./chat-list-item.component.scss'],
})
export class ChatListItemComponent implements OnInit {

  public channel: any = null;
  messages = [];
  children = {};

  constructor() {
  }

  ngOnInit(): void {


  }

  @Input('channel')
  set _channel(channel) {
    //console.log('setting', channel);
    const old = this.channel;
    this.channel = channel;
    if ((channel?.uniqueName === old?.uniqueName)) {
      return;
    }

    if (old) {
      old.removeAllListeners();
    }

    this.messages = [];
    if (channel) {
      this.join();
    }


  }


  listenForChanges() {
    this.channel.on('messageAdded', (message) => {
      const parsedMessage = this.parseMessage(message);
      if (parsedMessage) {
        this.messages.unshift(parsedMessage);
      }
    });
  }

  protected fetchMessages(channel, size = 1000, anchor = 0) {
    channel.getMessages().then(list => {
      this.messages = list.items.reverse();
    });

  }

  addChild(parent, message) {
    if (!this.children[parent]) {
      this.children[parent] = [];
    }

    this.children[parent].push(message);
  }

  protected parseMessage(m) {
    return m;
  }

  get lastMessage() {
    if (!this.messages || !this.messages.length) {
      return '';
    }
    return this.messages[0].body;
  }

  join() {
    this.channel.join()
      .then(res => {
        //console.log('joined', res);
        this.fetchMessages(this.channel);
        this.listenForChanges();
      })
      .catch(err => {
        //console.log('cannot join', this.channel, err);
        this.fetchMessages(this.channel);
        this.listenForChanges();
      });
  }


}
