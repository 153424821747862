<div class="pod-comp" *ngIf="podcast; else loading">
  <div>
    <div class="title-lesson-document my-4">{{'Podcast'|translate}}</div>
    <div *ngIf="url">
      <div class="card">
        <div  class="playing">
          <img src="/assets/img/Composant 36 – 1.svg" alt="" class="firstcomposant">
          <img  src="/assets/img/Composant 35 – 1.svg" alt="" class="secondcomposant">
          <div class="line-playing">
            <div class="progress-playing" [style.width]="getTime(a)" ></div>
          </div>

          <div >
            <audio  #a (timeupdate)="getTime(a)" controls class="invisible">
              <source [src]="url">
            </audio>

            <div class="icons-media">
              <!--<button (click)="a.play()" class="mx-4"><img src="/assets/img/shuffle.svg" alt=""></button>
              <button (click)="a.pause()" class="mx-4"><img src="/assets/img/arrowheads-pointing-to-the-left.svg" alt=""></button>-->
              <button (click)="a.play()" *ngIf="a.paused" class="p-1"><img src="/assets/img/play-button.svg" alt=""></button>
              <button (click)="a.pause()" *ngIf="!a.paused" class="p-1"><img src="/assets/img/play-button.svg" alt=""></button>
              <!--<button (click)="a.volume-=0.1" class="mx-4"><img src="/assets/img/arrowheads-pointing-to-the-left (1).svg" alt=""></button>
              <button onclick="('player').volume-=0.1" class="mx-4"><img src="/assets/img/volume.svg" alt=""></button>-->
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-10">
          {{ title }}
        </div>
        <div class="col-md-2">
          <button *ngIf="!isFavorite" (click)="addFavorite(id)" class="button-favorite float-right"><img  src="/assets/img/empty-star.png" alt="favorite"></button>
          <button *ngIf="isFavorite" (click)="deleteFavorite(id)" class="button-favorite float-right"><img  src="/assets/img/star.svg" alt="favorite"></button>
        </div>
      </div>

    </div>
    <div *ngIf="!url">
      The audio for this podcast is missing
    </div>
  </div>
</div>
<ng-template #loading>
  <app-dialog-module></app-dialog-module>
</ng-template>

