import {Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-title-block',
  templateUrl: './title-block.component.html',
  styleUrls: ['./title-block.component.scss']
})
export class TitleBlockComponent implements OnInit {
  @Input() image = null;
  @Input() category = null;
  @Input() title = '';
  @Input() link = null;
  @Input() largeTitle = null;

  constructor( private router: Router,
               private _location: Location
    ) {

  }

  ngOnInit(): void {
    if(!this.image) {
      this.image = '/assets/img/login-banner.png';
    }
  }


  get img() {
    return this.image;
  }
  backLink() {
    this._location.back();
  }
}
