<div class="video-comp" *ngIf="video; else loading">

    <!--<iframe class="embed-responsive-item" src="https://www.youtube.com/watch?v=n78fkIboBOs" allowfullscreen></iframe>
    -->

  <div *ngIf="url">


    {{'Video'|translate}}
    <div class="play">
      <video controls class="w-100"  #v [src]="url"></video>
      <i  class="fa fa-play" *ngIf="v.paused" (click)="v.play()"></i>
    </div>

    <div class="row mt-2">
      <div class="col-md-10">
        {{title}}
      </div>
      <div class="col-md-2">
        <button *ngIf="!isFavorite" (click)="addFavorite(id)" class="button-favorite float-right"><img  src="/assets/img/empty-star.png" alt="favorite"></button>
        <button *ngIf="isFavorite" (click)="deleteFavorite(id)" class="button-favorite float-right"><img  src="/assets/img/star.svg" alt="favorite"></button>
      </div>
    </div>

  </div>

  <div *ngIf="!url">The video for this lesson is missing</div>

</div>

<ng-template #loading>
  <app-loading-dialog></app-loading-dialog>
</ng-template>

