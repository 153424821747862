<div class="row">
<!--  <div class="col-xs-12">-->
<!--    <app-lesson-item-->
<!--      *ngFor="let leseonEl of lessons.data;"-->
<!--      [index]="leseonEl.id"></app-lesson-item>-->
<!--  </div>-->
  it work
</div>



