import {Component, Input, OnInit} from '@angular/core';
import {Exam} from '../../models/exam';
import {Test} from '../../models/test';
import {GroupWork} from '../../models/group-work';

@Component({
  selector: 'app-evaluation-card',
  templateUrl: './evaluation-card.component.html',
  styleUrls: ['./evaluation-card.component.scss']
})
export class EvaluationCardComponent implements OnInit {

  @Input() submission;
  @Input() evaluation: Exam | Test | GroupWork;

  constructor() {
  }

  ngOnInit(): void {
  }


  get module() {
    return this?.evaluation?.relationships.module?.data;
  }

}
