import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Storage} from '@ionic/storage';
import {environment} from '../../environments/environment';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {fromPromise} from 'rxjs/internal-compatibility';
import {BehaviorSubject, of, ReplaySubject, throwError} from 'rxjs';
import {Token} from '../models/token';
import {AuthInterceptor} from '../interceptors/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public static readonly SKIP_AUTH_HEADER = 'X-COA-SKIP-AUTH';


  static readonly TOKEN_KEY = 'coa_token';
  token$ = new BehaviorSubject(null);
  currentToken = null;
  _currentUser = null;
  currentUser = new ReplaySubject(1);


  constructor(
    private httpClient: HttpClient,
    private storage: Storage
  ) {

    this.token$.next(null);
    this.fetchToken().subscribe(token => {
      this.currentToken = this.makeToken(token);
      this.token$.next(token);
      this.getCurrentUser().subscribe(console.log);
    });


  }



  authenticate(username, password) {
    return this.httpClient
      .post(
        environment.auth_url,
        {
          username: username,
          password: password,
        },
        {
          observe: 'response',
          headers: {
            [AuthService.SKIP_AUTH_HEADER]: 'true'

          }
        }
      )
      .pipe(
        catchError(err => throwError(err)),
        switchMap(
          (response: HttpResponse<any>) => {

            if (response.status === 255) {
              return of(response.body);
            }

            return this.saveToken(response.body);
          }
        )
      );
  }


  validateLogin(username, code) {
    return this.httpClient
      .post(
        environment.browser_verify_url,
        {
         username: username,
         code: code,
        }
      )
      .pipe(
        switchMap(
          token => {
            return this.saveToken(token);
          }
        )
      );
  }

  fetchToken() {
    return fromPromise(
      this.storage.get(AuthService.TOKEN_KEY).then(t => this.makeToken(t))
    );
  }

  getCurrentUser(tapUser = true) {

    return this.httpClient.get(environment.base_url + `/api/user`).pipe(
      tap((user: any) => {
        if (tapUser) {
          this.currentUser.next(user.current_user);
        }
      })
    );
  }


  logout() {

    return fromPromise(
      this.storage.remove(AuthService.TOKEN_KEY)
    ).pipe(
      tap(res => {
        this.currentToken = null;
        this.token$.next(null);
      })
    );
  }


  isAuthenticated() {
    return !!this.currentToken;
  }

  saveToken(token) {
    this.currentToken = this.makeToken({...token, created_at: new Date().getTime()});
    this.token$.next(this.currentToken);
    this.getCurrentUser();
    return fromPromise(this.storage.set(AuthService.TOKEN_KEY, this.currentToken).then(res => this.currentToken));
  }

  makeToken(t) {
    if (!t) {
      return null;
    }
    const {access_token, created_at, token_type, expires_in} = t;
    return new Token(access_token, expires_in, token_type, created_at);
  }


  register(data) {
    return this.httpClient
      .post(environment.register, data, {observe: 'response'})
      .pipe(
        switchMap(
          (response) => {

            if (response.status === 255) {
              return of(response.body);
            }

            return this.saveToken(response.body);
          }
        )
      );
  }


  validatePhone(phone, code) {
    return this.httpClient
      .post(environment.confirm_phone_url, {phone, code});
  }


  verifyPone(phone) {
    return this.httpClient
      .post(environment.validate_phone_url, {phone});
  }


  verifyEmail(token) {
    return this.httpClient.post(environment.confirm_email_url, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
        [AuthService.SKIP_AUTH_HEADER]: 'true'
      }
    });
  }


  uploadProfilePicture(file) {
    const data = new FormData();
    data.append('picture', file, file.name);
    return this.httpClient.post(environment.uploadAvatar, data).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  getProfilePicture(fileName) {
    return this.httpClient.get(environment.avatars.replace('{filename}',fileName)).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }


  updateProfile(data) {
    return this.httpClient.post(environment.update_profile, data,).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  updateNote(data)
  {
    return this.httpClient.post(environment.store_user_note,data,).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  updateNotificationStatus(data)
  {
    return this.httpClient.post(environment.store_notification_status,data,).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }


  requestPasswordReset(email) {
    return this.httpClient.post(
      environment.request_password_reset,
      {
        email
      },
      {
        headers: {
          [AuthService.SKIP_AUTH_HEADER]: 'true'

        }
      }
    );
  }


  changePassword(token, data) {
    const url = environment.change_password;
    return this.httpClient
      .post(
        url,
        data,
        {
          headers: {
            [AuthService.SKIP_AUTH_HEADER]: 'true',
            Authorization: 'Bearer ' + token
          }
        }
      );
  }


  change_old_password(data){
    const url = environment.change_old_password;
    return this.httpClient.post(url, data).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }


  deleteAccount() {
    const url = environment.delete_account;
    return this.httpClient.post(url, null).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }


  /*********************** ws should be migrated later to the correct service file ****************** */
  getUserEvaluations(module) {
    return this.httpClient
    .get((environment.get_user_evaluations).replace('{module}', module), {})
    .pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  get_course_result(courseId) {
    return this.httpClient
    .get((environment.get_course_result).replace('{course}', courseId), {})
    .pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  get_certificate_path(courseId) {
    return this.httpClient
    .get((environment.get_certificate_path).replace('{course}', courseId), {})
    .pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  /******************************************************** */


  /*********************** organisation logic************************************** */
  get_invite_organisation(code_invite) {
    return this.httpClient
    .get((environment.get_invite_organisation).replace('{code_invite}', code_invite), {})
    .pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  get_organisation(organisation_id) {
    return this.httpClient
    .get((environment.get_organisation).replace('{organisation}', organisation_id), {})
    .pipe(
      tap((res: any) => {
        return res;
      })
    );
  }
  /******************************************************** */
}
