import { Injectable } from '@angular/core';
import {Service} from 'ngx-jsonapi';
import {Question} from '../models/question';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService extends Service<Question> {
  public resource = Question;
  public type = 'questions';


  constructor() {
    super();
    this.register();
  }
}
