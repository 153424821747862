import {Component, Input, OnInit} from '@angular/core';
import {Course} from '../../models/course';
import {Subscription, of} from 'rxjs';
import {CoursesService} from '../../services/courses.service';
import {Module} from '../../models/module';
import {ModulesService} from '../../services/modules.service';
import {Lesson} from '../../models/lesson';
import {Router} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2'
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-more-lessons-block',
  templateUrl: './more-lessons-block.component.html',
  styleUrls: ['./more-lessons-block.component.scss']
})
export class MoreLessonsBlockComponent implements OnInit {

  @Input('currentLesson') current = null;
  module: Module;
  lessonss:[];
  image : String = '/assets/img/document-2.svg';
  subscription: Subscription = null;
  loading = true;
  c: any;
  c1: any;
  test : any ;
  lessonbyid : any ;
  currentUser = null;
  public filtre: any ;
  testformation;
  constructor(
    private modulessService: ModulesService,
    private router: Router,
    private httpClient: HttpClient ,
    private httpClient1: HttpClient,private httpClient2: HttpClient,
    private authService: AuthService,
    private translateService: TranslateService,


  ) {
  }

  ngOnInit(): void {
    this.authService.currentUser
    .pipe(
      switchMap(user => {
        this.currentUser = user;
        //console.log('current user', user);
        return of(user);
        // return this.fetchParticipation();
      })
    ).subscribe(res => {
    //console.log('loaded', res);
  });
    const url = environment.base_url ;
    this.httpClient.get<any>(url+'/api/v1/lessons').subscribe(res => {
    this.lessonss = res.data.sort((x,y) => x.attributes['created-at'] < y.attributes['created-at']).slice(0,3);})

    // this.httpClient1.get<any>(environment.base_url+'/api/filter').subscribe(re => {
    //   this.filtre = re;

    //  })

  }

  onmouse(img : String , id : String)
  {
    const imgE1 = document.getElementById('teaser-img-' +id);
    imgE1.setAttribute('src' , String(img));
  }
/*
  @Input('module')
  set _course(course: Module) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.loading = true;
    this.subscription = this.modulessService
      .get(course.id, {
        include: ['lessons'],
        ttl: 60
      }).subscribe(c => {
        this.loading = false;
        this.module = c;
        //console.log('courrrrrsaaaaaa :::: ', c);
      });
  }


  get lessons(): Lesson[] {
    return this.module.relationships.lessons.data ?? [];
  }


  get course() {
    return this.module?.relationships?.course.data?.id;
  }

  get moduleId() {
    return this?.module.id;
  }

  navigate(lesson) {




    const url = environment.base_url ;
    this.httpClient1.get<any>(url+'/api/v1/lessons/'+lesson.id+'/relationships/module').subscribe(res => {
      this.c = res.data;
      //console.log(this.c);

    this.httpClient2.get<any>(url+'/api/v1/modules/'+this.c.id+'/relationships/course').subscribe(res1 => {
      this.c1 = res1.data;
      this.router.navigate(['/modules/' + this.c1.id  + '/matieres/' + this.c.id +'/cours/' + lesson.id]);
    })

    })


    //this.router.navigate([`/modules/${this.course}/matieres/${this.moduleId}/lessons/${lesson.id}`]);
  }
*/

// veriFormation (lesson)
// {
//   var test= false;
//   for (let fil of this.filtre)
//       {
//         if (fil.title==lesson.attributes.title)
//           {
//             if (fil.course_formation==this.currentUser.formation_id)
//             {
//               test=true;
//             }
//           }
//       }
//     return test ;
// }
  navigate(lesson)
  {
    //this.testformation = this.veriFormation(lesson);
    this.test = this.authService.isAuthenticated();
    if (this.test == true)
      {
        const url = environment.base_url ;
        this.httpClient.get<any>(url+'/api/v1/lessons/'+lesson.id).subscribe(re => {
          this.lessonbyid = re.data;
          if (this.lessonbyid.attributes.grades.includes(this.currentUser.grade_id)==true)
          {
            if(this.currentUser.formation_id==this.lessonbyid.attributes.formation_id)
              {
                const url = environment.base_url ;
                this.httpClient1.get<any>(url+'/api/v1/lessons/'+lesson.id+'/relationships/module').subscribe(res => {
                  this.c = res.data;
                  //console.log(this.c);

                this.httpClient2.get<any>(url+'/api/v1/modules/'+this.c.id+'/relationships/course').subscribe(res1 => {
                  this.c1 = res1.data;
                  this.router.navigate(['/modules/' + this.c1.id  + '/matieres/' + this.c.id +'/cours/' + lesson.id]);
                })

                })
              }else{
                Swal.fire({
                  title: this.translateService.instant("You do not have access to this training"),
                  text:  '',
                  icon: 'error',
                  //showCancelButton: true,
                  confirmButtonText: 'OK',

                })
              }


          }
          else {
                Swal.fire({
                  title: this.translateService.instant("You can't access to this lesson"),
                  text:  '',
                  icon: 'error',
                  //showCancelButton: true,
                  confirmButtonText: 'OK',

                })

          }

        });
      }else{
        Swal.fire({
          title: this.translateService.instant('Not Connected'),
          text:  "",//this.translateService.instant('Please Login First!'),
          icon: 'warning',
          //showCancelButton: true,
          confirmButtonText: 'OK',

        })

      }
  }

}
