import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Channel} from 'twilio-chat/lib/channel';
import {Message} from 'twilio-chat/lib/message';
import {TwilioV2Service} from '../../../services/twilio-v2.service';
import {User} from 'twilio-chat';

@Component({
  selector: 'app-message-window',
  templateUrl: './message-window.component.html',
  styleUrls: ['./message-window.component.scss']
})
export class MessageWindowComponent implements OnInit {

  @ViewChild('box') box;
  channel: Channel;
  messages: Message[] = [];
  room = '';
  message = '';
  replySelected = null;
  professor = null;
  loading = true;
  listener = null;
  member: {
    loading: boolean,
    data: User | null
  } = {
    loading: true,
    data: null
  };

  constructor(
    private twilioV2Service: TwilioV2Service
  ) {
  }

  ngOnInit(): void {
  }


  @Input('channel')
  set _channel(channel) {
    const old = this.channel;
    if (old && this.listener) {
      old.removeListener('messageAdded', this.listener);
      //console.log('OLD', old);
      this.listener = null;
      this.member = {
        data: null,
        loading: true
      };
    }
    const listener = (msg) => this.listenToMessage(msg);

    this.channel = channel;
    if (this.channel) {
      this.messages = [];
      this.fetchMessage();
      this.listener = listener;
      this.channel.on('messageAdded', this.listener);
      this.getMember();

    }
  }


  fetchMessage() {
    this.loading = true;
    this.channel.getMessages(100).then(messages => {
      this.messages = messages.items;
      this.loading = false;
      this.scroll();
    });
  }


  listenToMessage(msg) {
    this.messages.push(msg);
    this.scroll();
  }


  send(box) {
    if (!this.message) {
      return;
    }

    const attributes: { parent?: string | null } = {};

    if (this.replySelected) {
      attributes.parent = this.replySelected.sid;
    }

    this.channel.sendMessage(this.message, attributes).then(res => {
      this.message = '';
      this.replySelected = null;
      this.scroll();
    });
  }


  scroll() {
    setTimeout(() => {
      if (this.box) {
        this.box.nativeElement.scrollTop = this.box.nativeElement.scrollHeight + 300;
      }
    }, 333);
  }

  private getMember() {
    if (!this.channel) {
      return;
    }

    this.twilioV2Service.getMembers(this.channel, true)
      .subscribe((res: any[]) => {
        this.member = {
          loading: false,
          data: res.length ? res[0] : null
        };
      });
  }


  get title() {
    if (this.channel.uniqueName.startsWith('private')) {
      return this?.member?.data?.friendlyName;
    }
    return this.channel.friendlyName;
  }
}
