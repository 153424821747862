import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Course} from '../../models/course';
import {ParticipationService} from '../../services/participation.service';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {marker, marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {throwError} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {MatDialog} from '@angular/material';
import {DatePipe} from '@angular/common';
import {NotAuthenticatedDialogComponent} from '../not-authenticated-dialog/not-authenticated-dialog.component';

@Component({
  selector: 'app-participation-button',
  templateUrl: './participation-button.component.html',
  styleUrls: ['./participation-button.component.scss'],
  providers: [DatePipe]
})
export class ParticipationButtonComponent implements OnInit {


  course: Course = null;
  participation = null;
  user = null;
  loading = false;
  disabled = false;
  newDate = new Date();
  dateNow = '';
  @Input() withCancel = true;

  constructor(
    private participationService: ParticipationService,
    private authService: AuthService,
    private router: Router,
    private toastrService: ToastrService,
    private dialogService: MatDialog,
    private datePipe: DatePipe
  ) {
    this.authService.currentUser.subscribe(user => {
      this.user = user;
    });
    this.dateNow = this.datePipe.transform(this.newDate, 'yyyy-MM-dd');
  }

  ngOnInit(): void {

  }

  @Input('course')
  set _course(course: Course) {
    //console.log('setting course', course);
    this.course = course;
    this.getParticipation();
  }


  getParticipation() {
    if (!this.course) {
      return;
    }
    this.loading = true;
    this.participationService
      .getParticipation(this.course.id)
      .subscribe(({participation}: any) => {
        //console.log('fetched participation', participation);
        this.loading = false;
        this.participation = participation;
        if ((this.course.attributes.application_start_date > this.dateNow) && (this.dateNow > this.course.attributes.application_end_date)
          //|| (this.course.relationships.students.data.length = this.course.attributes.max_participants)
        ) {
          this.disabled = true;
        }
      }, err => {
        this.loading = false;
        //console.log('error fetching participation', err);
      });


  }


  get isParticipated() {

    if (!this.course || !this.participation) {
      return false;
    }


    return !!this.participation && this.participation.status !== 3;
  }


  participate() {
    if (!this.course) {
      throwError('Course not ready');
      return;
    }

    /*if (!this.user) {
      this.router.navigate(['secure']).then(res => {
        this.toastrService.error(_('You need to be logged in'));
      });
    }*/

    this.loading = true;
    this.participationService
      .participate(this.course.id)
      .subscribe(
        (res: any) => {
          this.participation = res.participation;
          this.loading = false;
        },
        err => {
          //console.log('error participating', err);
          this.loading = false;
          if (err.status === 401) {
            this.dialogService.open(NotAuthenticatedDialogComponent, {data: {message: marker('To Apply in this course, Please login')}});
          }
        }
      );
  }

  cancel() {
    if (!this.course) {
      return;
    }

    if (!this.user) {

    }

    this.loading = true;
    this.authService
      .getCurrentUser()
      .pipe(
        switchMap(
          res => {
            if (!res) {
              return this.participationService
                .getParticipation(this.course.id);
            }
            return throwError('You\'re not logged in');
          }
        )
      ).subscribe(
      (res: any) => {
        this.participation = res.participation;
        this.loading = false;
      },
      err => {
        //console.log('error participating', err);
        this.loading = false;
        this.dialogService.open(NotAuthenticatedDialogComponent);
      }
    );
  }

}
