import { Pipe, PipeTransform } from '@angular/core';
import { fil } from 'date-fns/locale';

@Pipe({
    name: 'myfilter'
})
export class CourseFilterPipe implements PipeTransform {

    transform(items: any[], filter: any,filter1 : any,filter2 : any  ): any {

        if(filter && !filter1 && !filter2){

          let m =items.filter(
            function(item) {
              return item.attributes.grades.indexOf(parseInt(filter)) !== -1
          });
          //console.log(m);
          if(m.length === 0) {
            return [-1];
          }
          return m ;
        }
        else if (!filter && filter1  && !filter2){

          let m =items.filter(
            function(item) {
              var d = new Date(item.attributes.course_start_date)
              return (d.getFullYear()==filter1)
          });
          //console.log(m);
          if(m.length === 0) {
            return [-1];
          }
          return m ;

        }
        else if (!filter && !filter1  && filter2)
        {   var monthNames = ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin","Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
          let m =items.filter(
            function(item) {
              var d = new Date(item.attributes.course_start_date)
              return (monthNames[d.getMonth()]==filter2)
          });
          //console.log(m);
          if(m.length === 0) {
            return [-1];
          }
          return m ;
        }
        else if (filter && !filter1  && filter2)
        {   var monthNames = ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin","Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
          let m =items.filter(
            function(item) {
              var d = new Date(item.attributes.course_start_date)
              return item.attributes.grades.indexOf(parseInt(filter)) !== -1  && (monthNames[d.getMonth()]==filter2)
          });
          //console.log(m);
          if(m.length === 0) {
            return [-1];
          }
          return m ;
        }
        else if (filter && filter1  && !filter2)
        {
          let m =items.filter(
            function(item) {
              var d = new Date(item.attributes.course_start_date)
              return item.attributes.grades.indexOf(parseInt(filter)) !== -1   && (d.getFullYear()==filter1)
          });
          //console.log(m);
          if(m.length === 0) {
            return [-1];
          }
          return m ;
        }
        else if (!filter && filter1  && filter2)
        {
          var monthNames = ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin","Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
          let m =items.filter(
            function(item) {
              var d = new Date(item.attributes.course_start_date)
              return (d.getFullYear()==filter1) && (monthNames[d.getMonth()]==filter2)
          });
          //console.log(m);
          if(m.length === 0) {
            return [-1];
          }
          return m ;
        }
        else if (filter && filter1 && filter2){
          var monthNames = ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin","Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
          let m =items.filter(
            function(item) {
              var d = new Date(item.attributes.course_start_date)
              return item.attributes.grades.indexOf(parseInt(filter)) !== -1  && (d.getFullYear()==filter1) && (monthNames[d.getMonth()]==filter2)
          });
          //console.log(m);
          if(m.length === 0) {
            return [-1];
          }
          return m ;

        }else{
          return items;

        }


    }


}
