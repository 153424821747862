<ng-container *ngIf="question">
  <div class="tail-p-4">
    <h4 class="tail-text-blue-500 tail-mb-2" [innerHTML]="question.attributes.question"></h4>
    <ng-container *ngIf="!uploading; else loading">
      <div class="input-group" *ngIf="!selectedFile">
        <div class="custom-file">
          <input (change)="change($event)" type="file" class="custom-file-input" id="answer-upload">
          <label class="custom-file-label" for="answer-upload">Choose file</label>
        </div>
      </div>
      <div class="uploaded-file d-flex " *ngIf="selectedFile">
        <label *ngIf="!uploaded">{{selectedFile.name}}</label>
        <a class="tail-break-words pr-2" *ngIf="uploaded" href="{{publicLink}}" target="_blank">{{selectedFile.name}}</a>
        <div class="btn-group">
          <button class="btn btn-outline-secondary" type="button" *ngIf="!uploaded" (click)="uploadFile()">upload</button>
          <button class="btn btn-outline-secondary" type="button" (click)="clear()">clear</button>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #loading>
  <div class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
