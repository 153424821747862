import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../services/auth.service';
import {environment} from '../../../environments/environment';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-verification-email-block',
  templateUrl: './verification-email-block.component.html',
  styleUrls: ['./verification-email-block.component.scss']
})
export class VerificationEmailBlockComponent implements OnInit {

  current_user;
  data;
  isHidden = false;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService
  ) {
    if (this.router && this.router.url.startsWith('/secure/verify-email')) {
      this.isHidden = true;
    }
  }

  ngOnInit(): void {
    this.authService.getCurrentUser().subscribe(({current_user}) => {
        this.current_user = current_user;
      },
      () => //console.log('error'),
      () => {
      });
  }

  verifyEmail() {
    let url = environment.base_url + '/api/email/resend';
    this.authService.fetchToken().subscribe(
      (token) => {
        //console.log('token :::: ', token);
        this.httpClient.post(url, {}, {
          /*headers: {
            Authorization: `Bearer ${token.access_token}`,
            [AuthService.SKIP_AUTH_HEADER]: 'true'
          }*/
        }).subscribe(
          (message) => {
            //console.log('message form backend  ::: ', message);
            if (message === 'The notification has been resubmitted') {
              this.toastr.success('Email verification link was successfully sent');
            } else {
              this.toastr.error('Error while sending email verification link');
            }
          }
        );
      }
    );

  }
}
