import { Component, OnInit } from '@angular/core';
import {ModulesService} from '../../services/modules.service';
import {DocumentCollection} from 'ngx-jsonapi';
import {Module} from '../../models/module';
import {CoursesService} from '../../services/courses.service';
import {ProfessorsService} from '../../services/professors.service';
import {LessonsService} from '../../services/lessons.service';

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss']
})
export class ModulesComponent implements OnInit {

  constructor() {
  }
  ngOnInit() {
  }

}
