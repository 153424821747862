import {DocumentCollection, DocumentResource, Resource} from 'ngx-jsonapi';
import {Lesson} from './lesson';

export class LinkLesson extends Resource {
  public attributes = {
    urls: []
  };
  public relationships = {
    lesson: new DocumentResource<Lesson>(),
    files: new DocumentCollection<Lesson>(),
  };
}
