import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PublicCalendarComponent} from "./public-calendar/public-calendar.component";


const routes: Routes = [
  {path: 'calendar', component:PublicCalendarComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CalendarPageRoutingModule { }
