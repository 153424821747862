import {DocumentCollection, Resource} from 'ngx-jsonapi';
import {Module} from './module';

export class Professor extends Resource {
  public attributes = {
    first_name: '',
    last_name: '',
    email: '',
    gender: '',
    phone: '',
    age: '',
    photo: '',
    access: '',
    description: ''
  };
  public relationships = {
    modules: new DocumentCollection<Module>(),
  };
}

