import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UsersService} from '../../services/users.service';
import {concat, fromEvent, interval, of} from 'rxjs';
import {debounce, map, switchMap} from 'rxjs/operators';
import {User} from '../../models/user';
import {TwilioService} from '../../services/twilio.service';
import {fromPromise} from 'rxjs/internal-compatibility';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-create-private-channel',
  templateUrl: './create-private-channel.component.html',
  styleUrls: ['./create-private-channel.component.scss']
})
export class CreatePrivateChannelComponent implements OnInit, AfterViewInit {


  @ViewChild('textInput', {static: true}) input: ElementRef;

  options = [];
  selected = [];
  textValue = '';


  constructor(
    private userService: UsersService,
    private twilioService: TwilioService,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {


  }


  fetchOptions() {
    //console.log(this.textValue);
    if (!this.textValue) {
      this.options = [];
      return;
    }

    this.userService.all({
      remotefilter: {
        name: this.textValue
      }
    })
      .pipe(map(collection => collection.data.filter(u => !(this.selected.map(s => s.id).includes(u.id)))))
      .subscribe((data) => {
        this.options = data;
      });

  }

  ngAfterViewInit(): void {
    //console.log(this.input);
    fromEvent(this.input.nativeElement, 'keyup').pipe(debounce(() => interval(1000))).subscribe(() => {
      this.fetchOptions();
    });
  }


  name(option: User) {
    return `${option.attributes.first_name} ${option.attributes.last_name}`;
  }

  addSelection($event) {
    //console.log($event);
    this.textValue = '';
    this.selected.push($event.option.value);
    this.input.nativeElement.value = null;
  }


  remove(user: User) {
    this.selected = this.selected.filter(u => {
      return u.id !== user.id;
    });
  }


  create() {
    this.authService
      .currentUser.pipe(
      switchMap((user: any) => {


        const userIds = [...this.selected.map(user => user.id), user.id + '']
          .sort((a: string, b: string) => a.localeCompare(b));

        const name = 'private.' + userIds.join('.');
        //console.log({userIds, name});
        return this.twilioService.createChannel(name)
          .pipe(
            switchMap((channel: any) => {
              //console.log('resultant channel', channel);
              const obs = userIds.map(id => {
                return fromPromise(channel.invite(id));
              });
              return concat(
                ...obs
              );
            })
          );
      })
    ).subscribe(console.log);

  }
}
