import { Injectable } from '@angular/core';
import {Service} from "ngx-jsonapi";
import {LinkLesson} from "../models/link-lesson";

@Injectable({
  providedIn: 'root'
})
export class LinkLessonService extends Service<LinkLesson> {
  public resource = LinkLesson;
  public type = 'link-lessons';
}

