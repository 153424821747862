import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import {CoursesService} from "../../../services/courses.service";
import {colors} from "../../../components/calendar/calendar-utils/colors";
import { LanguageService } from 'src/app/services/language.service';
import { PageService } from 'src/app/services/page.service';
import { Page } from 'src/app/models/page';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2'
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-public-calendar',
  templateUrl: './public-calendar.component.html',
  styleUrls: ['./public-calendar.component.scss']
})
export class PublicCalendarComponent implements OnInit {
 eventLessons;
 pageCalendar: Page;
  l ;
  ll;
  m;
  click
  c : any ;
  c1 :any;
  test : any ;
  lessonbyid : any ;
  testformation;
  public filtre: any ;
  coursesfiltred  ;
  currentUser = null;
  constructor(
    private coursesService : CoursesService,
    public languageService: LanguageService,
    private pageService: PageService,
    private router: Router,
    private httpClient: HttpClient,
    private httpClient1: HttpClient,
    private cd: ChangeDetectorRef,
    private httpClient2: HttpClient,
    private httpClient3: HttpClient,
    private authService: AuthService,
    private translateService: TranslateService,

  ) { }

  ngOnInit(): void {
    this.authService.currentUser
    .pipe(
      switchMap(user => {
        this.currentUser = user;
        //console.log('current user', user);
        return of(user);
        // return this.fetchParticipation();
      })
    ).subscribe(res => {
    //console.log('loaded', res);
  });

  // this.httpClient3.get<any>(environment.base_url+'/api/filter').subscribe(re => {
  //   this.filtre = re;

  //  })
    this.pageService.all()
      .subscribe(result => {
        if (result.data) {
          if (this.languageService.currentLang.startsWith('fr')) {
            if (result.data.filter(p => p.attributes.title === 'Programme Candid Academy').length > 0) {
              this.pageCalendar = result.data.filter(p => p.attributes.title === 'Programme Candid Academy')[0];
            }
          } else if (this.languageService.currentLang.startsWith('en')) {
            if (result.data.filter(p => p.attributes.title === 'Candid Academy Program').length > 0) {
              this.pageCalendar = result.data.filter(p => p.attributes.title === 'Candid Academy Program')[0];
            }
          }
          else if (this.languageService.currentLang.startsWith('ar')) {
            if (result.data.filter(p => p.attributes.title === 'برنامج كانديد أكاديمي').length > 0) {
              this.pageCalendar = result.data.filter(p => p.attributes.title === 'برنامج كانديد أكاديمي')[0];
            }
          }
        }
      });
      // this.click = new Date(localStorage.getItem("dateclick"));
      ////console.log("haw date eli clickina 3lih " , this.click );


      const url = environment.base_url ;
      this.httpClient.get<any>(url+'/api/v1/lessons').subscribe(res => {
        this.l = res.data;
        //console.log("its the lessons ",this.l);

        this.eventLessons=this.getEventLessons(this.l);
        this.m=this.getEventModules(this.l);

      })
   /* this.coursesService.all().subscribe(
      (courses) => (this.courses = courses),
      () => //console.log('errors'),
      () => {
        this.eventCourses = this.getEventCourses(this.courses);
    }
    );*/
    this.cd.detach();
    setInterval(() => {
     // this has no effect at all
      this.cd.detectChanges();
    }, 2000);
  }
  getEventModules(lessons){
    const url = environment.base_url ;
    let m =[];
    for( let lesson of lessons){
      this.httpClient1.get<any>(url+'/api/v1/lessons/'+ lesson.id+'/module').subscribe(res1 => {
        m.push( res1.data.attributes.title);
        //console.log("ahwma les modules " ,this.m);
      })
      }
      return m ;
  }
  getEventLessons(lessons){

    let eventLessons =[]
    for( let lesson of lessons){
      let styledTitle=(lesson.attributes.title[0].toUpperCase()+lesson.attributes.title.slice(1).toLowerCase()).bold();
      for (let det of lesson.attributes.lesson_dates)
      {
        eventLessons.push(
          {
            title: 'Début : '+styledTitle, start : new Date(det.date_begin) , color : colors.blue, allDay: true ,

          },
          {
            title: 'Fin : '+styledTitle, start : new Date(det.date_end) , color : colors.red, allDay: true
          },

        );
      }
    }
    return eventLessons;
  }

  public doSomething(date: any):void {
    this.click = date ;
    //console.log('Picked date ahya: ', date);
    this.cd.detectChanges();

  }


 
  // veriFormation (lesson)
  // {
  //   var test= false;
  //   for (let fil of this.filtre)
  //       {
  //         if (fil.title==lesson.attributes.title)
  //           {
  //             if (fil.course_formation==this.currentUser.formation_id)
  //             {
  //               test=true;
  //             }
  //           }
  //       }
  //     return test ;
  // }
  navigateLesson(lesson)
  {
    //this.testformation = this.veriFormation(lesson);

    this.test = this.authService.isAuthenticated();
    if (this.test == true)
      {
        const url = environment.base_url ;
        this.httpClient.get<any>(url+'/api/v1/lessons/'+lesson.id).subscribe(re => {
          this.lessonbyid = re.data;
          if (this.lessonbyid.attributes.grades.includes(this.currentUser.grade_id)==true)
          {
            if(this.currentUser.formation_id==this.lessonbyid.attributes.formation_id)
            {
              const url = environment.base_url ;
              this.httpClient1.get<any>(url+'/api/v1/lessons/'+lesson.id+'/relationships/module').subscribe(res => {
                this.c = res.data;
                //console.log(this.c);

              this.httpClient2.get<any>(url+'/api/v1/modules/'+this.c.id+'/relationships/course').subscribe(res1 => {
                this.c1 = res1.data;
                this.router.navigate(['/modules/' + this.c1.id  + '/matieres/' + this.c.id +'/cours/' + lesson.id]);
              })

              })
            }else{
              Swal.fire({
                title: this.translateService.instant("You do not have access to this training"),
                text:  '',
                icon: 'error',
                //showCancelButton: true,
                confirmButtonText: 'OK',

              })
            }


          }
          else {
                Swal.fire({
                  title: this.translateService.instant("You can't access to this lesson"),
                  text:  '',
                  icon: 'error',
                  //showCancelButton: true,
                  confirmButtonText: 'OK',

                })

          }

        });
      }else{
        Swal.fire({
          title: this.translateService.instant('Not Connected'),
          text:  "",//this.translateService.instant('Please Login First!'),
          icon: 'warning',
          //showCancelButton: true,
          confirmButtonText: 'OK',

        })/*.then((result) => {
          if (result.value) {
            Swal.fire(
              'Deleted!',
              'Your imaginary file has been deleted.',
              'success'
            )
          // For more information about handling dismissals please visit
          // https://sweetalert2.github.io/#handling-dismissals
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Your imaginary file is safe :)',
              'error'
            )
          }
        })*/


      }
  }
}
