<div class="channels-list" *ngIf="!loading; else l">
  <ng-container *ngFor="let item of channels" >
    <app-channel-item
      [channel]="item"
      (click)="this.itemClicked.emit(item)"
      [selected]="selected && item.sid === selected.sid"
    ></app-channel-item>
  </ng-container>
  <div *ngIf="!channels.length" class="d-flex h-100 align-items-center justify-content-center">
    <div class="flex-grow-0">
      {{'You have no discussions'|translate}}
    </div>
  </div>
</div>

<ng-template #l>
  <app-loading-dialog></app-loading-dialog>
</ng-template>
