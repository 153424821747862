<div class="row">
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
          
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Are you sure you want to delete your account ?</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          this operation is not reversible
            <div class="modal-footer">
                <button type="button" class="btn btn-primary-yes">yes</button>
                <button type="button" class="btn btn-secondary-no" data-dismiss="modal">no</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>