<div class="tail-flex tail-h-full">
  <div class="channels-list tail-w-1/4 tail-h-full tail-overflow-scroll">
    <ng-container *ngFor="let item of (channels)">
      <app-chat-list-item [channel]="item" (click)="this.selectedChannel=item"></app-chat-list-item>
    </ng-container>
  </div>
  <div class="message-container tail-w-3/4 tail-h-full">
    <!--<app-chat-window class="tail-h-full" *ngFor="let channel of channels" [channel]="channel"
                     [hidden]="!selectedChannel  || channel.sid !== selectedChannel.sid">

    </app-chat-window>-->
    <app-private-chat-window
      class="tail-h-full"
      *ngFor="let channel of channels" [channel]="channel"
      [hidden]="!selectedChannel  || channel.sid !== selectedChannel.sid">

    </app-private-chat-window>
  </div>

</div>
