import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {environment} from '../../environments/environment';
import {tap} from 'rxjs/operators';
import {of} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ZoomService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
  }


  generateSignature(meetingId) {
    const time = Date.now();
    return this.httpClient
      .post(
        environment.zoom.signatureUrl + '?time=' + time,
        {
          meetingId: meetingId,
          role: 0
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
      .pipe(tap(console.log));
  }

  getUrlVideo(meetingId) {
    return this.httpClient
      .get(
        environment.zoom.video + `?meetingId=${meetingId}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        })
      .pipe(tap(console.log));
  }
}
