import {Component, OnInit, Input} from '@angular/core';
import {VideosService} from '../../../services/videos.service';
import {FilesService} from '../../../services/files.service';
import {Video} from '../../../models/video';
import { LessonsService } from 'src/app/services/lessons.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-video-detail',
  templateUrl: './video-detail.component.html',
  styleUrls: ['./video-detail.component.scss']
})
export class VideoDetailComponent implements OnInit {
  @Input('index') index;


  video: Video;

  constructor(
    private videosService: VideosService,
    filesService: FilesService,
    private lessonsService: LessonsService,
    private toastr: ToastrService
    ) {
    filesService.register();
  }

  ngOnInit(): void {
   this.getVideo();
  }

  getVideo(){
    this.videosService.get(this.index, {include: ['file']}).subscribe(
      video => {
        this.video = video;
        //console.log('video', video);
      }
    );
  }

  get url() {
    return this.video?.relationships.file.data?.attributes.url;
  }

  get title() {
    return this.video?.relationships.file.data?.attributes.name;
  }

  get id() {
    return this.video?.relationships.file.data?.id;
  }
  
  get isFavorite() {
    return this.video?.relationships.file.data?.attributes.isFavorite;
  }

  addFavorite(id){
    this.lessonsService.addFavorite({file_id : id})
    .subscribe((res: any) => {
        if (res) {
          this.getVideo();
          this.toastr.success('Video Favorite successfully added');
        } 
      },
      err => {
        this.toastr.error('Error while adding video favorite');
        //console.log(err);
      });
  }

  deleteFavorite(id){
    this.lessonsService.deleteFavorite({file_id : id})
    .subscribe((res: any) => {
        if (res) {
          this.getVideo();
          this.toastr.success('Video Favorite successfully deleted');
        } 
      },
      err => {
        this.toastr.error('Error while deleting video favorite');
        //console.log(err);
      });
  }

}
