import {Resource} from 'ngx-jsonapi';

export class User extends Resource {
  public attributes = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    birthday: '',
    photo: '',
    email_verified_at:''
  };
  public relationships = {};
}
