<!--<div class="row">-->
<!--  <div class="col-xs-12">-->
<!--<app-course-item-->
<!--      *ngFor="let courseEl of courses.data;"-->
<!--      [course]="courseEl"-->
<!--      [index]="courseEl.id"></app-course-item>-->
<!--  </div>-->
<!--</div>-->
<div >
<!--
  <div class="bloc-searching">
    <div class="row">
      <div class="col-md-2">
          <div class="form-group select_box">
              <select name="namecourse" id="namecourse" class="form-control">
                  <option class="checked-option" value="">select</option>
                  <option value="course71">course 71</option>
                  <option value="course75">course 75</option>
                  <option value="course90">course 90</option>
              </select>
          </div>
      </div>
      <div class="col-md-2">
          <div class="form-group select_box">
              <select name="saveduploaded" id="saveduploaded" class="form-control">
                  <option class="checked-option" value="">saved </option>
                  <option value="xxxxx">lorem ipsum</option>
                  <option value="yyyyy">lorem ipsum</option>
                  <option value="zzzzz">lorem ipsum</option>
              </select>
          </div>
      </div>
      <div class="col-md-4">
        <div class="form-group select_box">
          <select name="saveduploaded" id="saveduploaded" class="form-control">
              <option class="checked-option" value="">saved uploaded</option>
              <option value="xxxxx">lorem ipsum</option>
              <option value="yyyyy">lorem ipsum</option>
              <option value="zzzzz">lorem ipsum</option>
          </select>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group select_box">
            <select name="saveduploaded" id="saveduploaded" class="form-control">
                <option class="checked-option" value="">saved</option>
                <option value="xxxxx">lorem ipsum</option>
                <option value="yyyyy">lorem ipsum</option>
                <option value="zzzzz">lorem ipsum</option>
            </select>
        </div>
      </div>
      <div class="col-md-2">
          <div class="search mb-3">
              <button class="btn btn-search-course">search</button>
          </div>
          <div class="reset">
              <button class="btn btn-reset-course">reset</button>
          </div>
      </div>
    </div>
  </div>
-->

<div class="container-fluid mb-5 mt-3">
  <h1 class="banner-title mt-5 bt-5" style="margin-top:150px;">LA FORMATION D'OSTÉOPATHIE SE DÉCOMPOSE EN UNITÉS<br>D'ENSEIGNEMENT DANS LES DOMAINES SUIVANTS</h1>

  <!-- <div class="row mt-3">
    <div class="col-sm mt-3">
      <div class="form-group">
        <select class="form-control search-course" id="exampleFormControlSelect1" [value]="pipeFilter"
        (input)="pipeFilter = $event.target.value">
          <option   label="TOUS LES NIVEAUX" ></option>
          <option>1</option>
          <option>2</option>
          <option>3</option >
          <option>4</option>
          <option>5</option>
        </select>
      </div>
    </div>
    <div class="col-sm mt-3">
      <div class="form-group">

        <select class="form-control search-course" id="exampleFormControlSelect1" [value]="pipeFilter1"
        (input)="pipeFilter1 = $event.target.value">
          <option label="TOUTES LES ANNÉES" ></option>
          <option>2022</option>
          <option>2021</option>
          <option>2020</option>
          <option>2019</option>

        </select>
      </div>

    </div>

    <div class="col-sm mt-3">
      <div class="form-group">
        <select class="form-control search-course" id="exampleFormControlSelect1" [value]="pipeFilter2"
        (input)="pipeFilter2 = $event.target.value">
          <option label="TOUS LES MOIS"></option>
          <option>Septembre</option>
          <option>Octobre</option>
          <option>Novembre</option>
          <option>Décembre</option>
          <option>Janvier</option>
          <option>Fevrier</option>
          <option>Mars</option>
          <option>Avril</option>
          <option>Mai</option>
          <option>Juin</option>
          <option>Juillet</option>
          <option>Août</option>

        </select>
      </div>
    </div>

         <div class="col-sm mt-3">
        <button class="btn-search-course">{{'SEARCH'|translate}}</button>
    </div>

  </div> -->
</div>

<!--

  <div *ngIf="courses && courses.data && courses.data.length >0" class="row">
    <div class="test" *ngFor="let courseEl of courses.data| paginate: { itemsPerPage: 9, currentPage: p } |  myfilter: pipeFilter : pipeFilter1 : pipeFilter2">
      <div class="col-sm-12 col-md-4 item-course" >
        {{ok(courseEl !== -1 )}}
        <div *ngIf="courseEl !== -1 ">
          <app-course-item [course]="courseEl" [index]="courseEl.id"></app-course-item>
        </div>

      </div>
    </div>

      <div *ngIf="isItOk" class="row">
        <div  class="col-sm-12 col-md-4 item-course">

        </div>
        <div  class="col-sm-12 col-md-4 item-course">
          {{'No Courses listed yet'|translate}}
        </div>
        <div  class="col-sm-12 col-md-4 item-course">

        </div>
      </div>
|  myfilter: pipeFilter : pipeFilter1 : pipeFilter2
  </div> -->

  <!-- <div *ngIf="courses && courses.data && courses.data.length >0" class=" row">
    <ng-container   *ngFor="let courseEl of courses.data| paginate: { itemsPerPage: 9, currentPage: p } ">
      <div class="col-sm-12 col-md-4 item-course" >


        <div *ngIf="courseEl !== -1 ">
          <app-course-item [course]="courseEl" [index]="courseEl.id"></app-course-item>
        </div>

      </div>
      <div *ngIf="courseEl === -1"  class="col-4 d-flex justify-content-center" >
        {{'No Courses listed yet'|translate}}
      </div>
    </ng-container >

  </div> -->

  <div *ngIf="courses && courses.data && courses.data.length >0 else alert" class="row">
    <div class="col-sm-12 col-md-4 item-course" *ngFor="let courseEl of courses.data.reverse()| paginate: { itemsPerPage: 9, currentPage: p };">
      <app-course-item [course]="courseEl" [index]="courseEl.id"></app-course-item>
    </div>
  </div>
  <div *ngIf="courses && courses.data && courses.data.length > 9" class="row position-control">
    <pagination-controls   (pageChange)="p = $event"></pagination-controls>
  </div>
</div>



<ng-template #alert>

    <div class=" alert "  >
      {{'No Courses listed yet'|translate}}
    </div>

</ng-template>
