
  <div class="chat-block-princ" *ngIf="messages; else l">
    <div class="all-content mt-2">
      <div class="header-title row align-items-center" *ngIf="professor">
        <div class="col col-2">
          <img #i (error)="i.src='/assets/img/profil photo messages.svg'" [src]="professor.attributes.photo" alt="">
        </div>
        <div class="col col-10">
          <div class="head">
            <div class="who">
              {{'Professor'|translate}}
            </div>
            <div class="names-prof">
              <!--
                            {{professor.attributes.first_name}} {{professor.attributes.last_name}}

              -->
            </div>
          </div>
        </div>
      </div>
      <div class="list-messages" id="style-2" #box>
        <ng-container *ngFor="let message of messages.reverse()">
          <div
            [class.message-student]="false"
            [class.message-professor]="true"
          >
            <div class="row">
  <!--            <div
                [class.ml-auto]="false"
                class="col-6 mb-3">-->
                <app-message-support
                  class="w-100"
                  [children]="getChildren(message.sid)"
                  cdkDragLockAxis="x"
                  cdkDrag
                  (cdkDragEnded)="dragEnded($event,message)"
                  [message]="message"></app-message-support>
              </div>
  <!--
            </div>
  -->
          </div>
        </ng-container>
      </div>
      <div class="zone-saisie row">
        <div class="col col-10">
            <textarea
              [(ngModel)]="message"
              name="message" placeholder="{{'Type Your Message Here'|translate}}" class="form-control"></textarea>
          <!--<a routerLink="#"><img src="/assets/img/attach.svg" class="pos-file attach-file" alt="undo attach"></a>-->
        </div>
        <div class="col col-2">
          <a (click)="send(box)"><img class="sent-file mt-1" src="/assets/img/send.svg" alt=""></a>
        </div>
      </div>
    </div>
  </div>


  <ng-template #l>
    <app-loading-dialog></app-loading-dialog>
  </ng-template>
