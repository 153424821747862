<footer class="page-footer font-small pt-1 mt-3">

  <!-- Footer Links -->
  <div class="container-fluid text-center text-md-left">

    <!-- Footer links -->
    <div class="row text-center text-lg-left  mt-3 pb-3">

      <!-- Grid column -->
      <div class="col-md-2 col-lg-2 col-xl-2 mt-3 auto">
        <img class="footer-logo" src="../../../assets/img/logo-footer.svg">
      </div>
      <!-- Grid column -->

      <hr class="w-100 clearfix d-md-none">

      <!-- Grid column -->
      <div class="col-md-2 col-lg-2 col-xl-2  mt-3 auto">

        <p class="nav-item">
          <a class="nav-link" [routerLink]="'/modules'">{{'COURSE'|translate}}</a>
        </p>
        <!--<p class="nav-item">
          <a class="nav-link" routerLink="/agenda">{{'AGENDA'|translate}}</a>
        </p>-->
        <p class="nav-item">
          <a class="nav-link" [routerLink]="'/how-it-works'">{{'HOW IT WORKS'|translate}}</a>
        </p>
        <!--<p class="nav-item">
          <a class="nav-link" [routerLink]="'/about-us'">{{'ABOUT'|translate}}</a>
        </p>
        <p class="nav-item">
          <a class="nav-link" [routerLink]="'/contact'">{{'CONTACT'|translate}}</a>
        </p>-->
      </div>
      <!-- Grid column -->



      <!-- Grid column -->
      <!-- <div class="col-md-2 col-lg-2 col-xl-2  mt-3">
        <p class="nav-item">
          <a class="nav-link" [routerLink]="'/contact'">{{'CONTACT'|translate}}</a>
        </p>
        <p class="nav-item">
          <a class="nav-link" [routerLink]="'/imprint'">{{'IMPRINT'|translate}}</a>
        </p>
      </div> -->


      <!-- Grid column -->
      <!-- <div class="col-md-3 col-lg-3 col-xl-3  mt-3">
        <p class="nav-item">
          <a class="nav-link" [routerLink]="'/terms-conditions'">{{'TERMS & CONDITIONS'|translate}}</a>
        </p>
        <p class="nav-item">
          <a class="nav-link" [routerLink]="'/cookies-policy'">{{'COOKIES POLICY'|translate}}</a>
        </p>
      </div> -->

      <!-- Grid column -->
      <hr class="w-100 clearfix d-md-none">

      <!-- Grid column -->
      <!-- <div class="media-footer col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
        <div class="row">
        <h6 class="form-title text-uppercase mb-2 ">{{'Subscribe to our newsletter'|translate}}</h6>
        <form class="input-group">
          <input type="text" class="form-input form-control form-control-sm" placeholder="{{'Email Address'|translate}}"
                 aria-label="Your email" aria-describedby="basic-addon2">
          <div class="input-group-append">
            <button class="form-submit btn btn-sm btn-dark mx-2" type="button">{{'OK'|translate}}</button>
          </div>
        </form>
      </div>
      <div class="row d-block footer-social">
        <div class="">
        <ul class="list-unstyled list-inline ">
          <li class="list-inline-item">
            <a class="btn-floating btn-fb">
             <i class="icon-facebook"></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a class="btn-floating btn-tw mx-1">
              <i class="icon-twitter"></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a class="btn-floating btn-li mx-1">
              <i class="icon-instagram"></i>
            </a>
          </li>
        </ul>
        </div>
      </div>
      </div> -->
      <!-- Grid column -->

    </div>
    <!-- Footer links -->

    <hr>

    <!-- Grid row -->

    <!-- Grid row -->

  </div>

  <!-- Footer Links -->

</footer>

<div id="copyright-section">
  <div class="container-fluid align-items-center copyright-footer">

    <!-- Grid column -->
    <div class="row position-copyright">
      <div class="col-md-12 col-lg-12 text-center">
        <!--Copyright-->
        <p class="block-copyright">{{'Copyright © 2020 All Rights Reserved Developed by'|translate}}
          <a class="xenius-link" target="_blank" href="http://www.xeniusconsulting.com/">
            <img class="xenius-link__img" src="../../../assets/img/copyright.png">
          </a>
        </p>
      </div>
    </div>
  </div>
</div>
