import {Component, Input, OnInit} from '@angular/core';
import {LessonsService} from '../../../../services/lessons.service';
import {Lesson} from '../../../../models/lesson';

@Component({
  selector: 'app-lesson-item',
  templateUrl: './lesson-item.component.html',
  styleUrls: ['./lesson-item.component.scss']
})
export class LessonItemComponent implements OnInit {

  lesson: Lesson;
  @Input() index: number;
  constructor(private lessonsService: LessonsService) {
  }

  ngOnInit() {
    this.lessonsService.get(String(this.index), {include: ['lessonable']}).subscribe(
      lesson => (this.lesson = lesson),
    );
  }

}
