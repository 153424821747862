import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CalendarPageRoutingModule } from './calendar-page-routing.module';
import {PublicCalendarComponent} from "./public-calendar/public-calendar.component";
import {SharedModule} from "../../components/shared.module";
import {CalendarModele} from "../../components/calendar/calendar.modele";
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
  declarations: [
    PublicCalendarComponent,
  ],
  imports: [
    CommonModule,
    CalendarPageRoutingModule,
    SharedModule,
    CalendarModele,
    TranslateModule
  ]
})
export class CalendarPageModule { }
