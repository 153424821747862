import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractQuestionComponent, QuestionType} from '../abstract-question';
import {TestsService} from '../../../services/tests.service';
import {TestSubmissionService} from '../../../services/test-submission.service';
import {Question} from '../../../models/question';
import {$e} from 'codelyzer/angular/styles/chars';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-file-question',
  templateUrl: './file-question.component.html',
  styleUrls: ['./file-question.component.scss'],
  providers: [{
    provide: AbstractQuestionComponent,
    useExisting: FileQuestionComponent
  }]
})
export class FileQuestionComponent extends AbstractQuestionComponent implements OnInit {

  @Input() question = null;

  selectedFile: File = null;
  uploadedFile: string = null;
  uploading = false;
  uploaded = false;

  constructor(
    private testSubmissionService: TestSubmissionService
  ) {
    super();
  }

  ngOnInit(): void {
  }


  @Input('value')
  set _value(value) {
    // @ts-ignore
    super._value = value;
    //console.log('setting file', value);
    if (value) {
      this.uploaded = true;
      this.uploadedFile = value;
      //console.log('setting file', value);
      this.selectedFile = {name: value} as File;
    } else {
      //console.log('what', value);
    }
  }

  uploadFile() {
    if (!this.selectedFile) {
      return;
    }
    this.uploading = true;
    this.testSubmissionService.uploadAnswerFile(this.selectedFile)
      .subscribe((res: any) => {
        this.uploading = false;
        this.uploaded = true;
        this.uploadedFile = res.url;
      }, err => {
        this.uploading = false;
        this.uploaded = false;
        this.uploadedFile = null;
        //console.log(err);
      });

  }


  clear() {
    this.uploadedFile = null;
    this.selectedFile = null;
    this.uploaded = false;
  }

  selectedValue() {
    return {
      question_id: this.question.id,
      type: 'file' as QuestionType,
      value: this.uploadedFile,
      drupal_reference: this.question.attributes.old_id,
      is_correct: false,
      note: '',
      score: 0
    };
  }


  change($event) {
    //console.log($event);
    this.selectedFile = $event.target.files[0];
  }

  get publicLink() {
    return this.uploadedFile;
  }
}
