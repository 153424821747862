import {Component, ChangeDetectionStrategy, OnInit, Input, ChangeDetectorRef, Output, EventEmitter} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {CalendarEvent, CalendarView} from 'angular-calendar';
import {
  isSameMonth,
  isSameDay,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  format,
} from 'date-fns';
import {Observable} from 'rxjs';
import {colors} from './calendar-utils/colors';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {LanguageService} from '../../services/language.service';

interface Film {
  id: number;
  title: string;
  release_date: string;
}

function getTimezoneOffsetString(date: Date): string {
  const timezoneOffset = date.getTimezoneOffset();
  const hoursOffset = String(
    Math.floor(Math.abs(timezoneOffset / 60))
  ).padStart(2, '0');
  const minutesOffset = String(Math.abs(timezoneOffset % 60)).padEnd(2, '0');
  const direction = timezoneOffset > 0 ? '-' : '+';

  return `T00:00:00${direction}${hoursOffset}:${minutesOffset}`;
}


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  @Input('events') eventdata;
  view: CalendarView = CalendarView.Month;
  @Output() onDatePicked: EventEmitter<any> = new EventEmitter<any>();

  viewDate: Date = new Date();

  events$: Observable<Array<CalendarEvent<{ film: Film }>>>;

  activeDayIsOpen = false;

  constructor(
    private http: HttpClient,
    private locale: LanguageService,
    private cd: ChangeDetectorRef
  ) {
    //console.log('date with our', new Date());
  }

  ngOnInit(): void {
    //console.log('event data : ', this.eventdata);
//    this.fetchEvents();
//     this.eventdata = [{
//       title : 'event 1',
//       // start : new Date(new Date() + getTimezoneOffsetString(this.viewDate)),
//       start : new Date() ,
//       color :  colors.blue,
//       allDay : true,
//     },
//       {
//         title : 'event 2',
//         // start : new Date('2020-04-10' + getTimezoneOffsetString(this.viewDate)),
//         start : new Date('010 April, 2020 09:00:00 Universal Time (UTC)'),
//         color :  colors.blue,
//         allDay : true,
//       },
//       {
//         title : 'event 3',
//         start : new Date('2020-04-10' + getTimezoneOffsetString(this.viewDate)),
//         color :  colors.blue,
//         allDay : true,
//       },
//       {
//         title : 'event 4',
//         start : new Date('2020-04-09' + getTimezoneOffsetString(this.viewDate)),
//         color :  colors.blue,
//         allDay : true,
//       },
//       {
//         title : 'event 5',
//         start : new Date('2019-04-09' + getTimezoneOffsetString(this.viewDate)),
//         color :  colors.blue,
//         allDay : true,
//       },
//       {
//         title : 'event 6',
//         start : new Date('2019-04-09' + getTimezoneOffsetString(this.viewDate)),
//         color :  colors.blue,
//         allDay : true,
//       }];
  }

  // fetchEvents(): void {
  //   const getStart: any = {
  //     month: startOfMonth,
  //     week: startOfWeek,
  //     day: startOfDay,
  //   }[this.view];
  //   const getEnd: any = {
  //     month: endOfMonth,
  //     week: endOfWeek,
  //     day: endOfDay,
  //   }[this.view];
  //
  //   const params = new HttpParams()
  //     .set(
  //       'primary_release_date.gte',
  //       format(getStart(this.viewDate), 'yyyy-MM-dd')
  //     )
  //     .set(
  //       'primary_release_date.lte',
  //       format(getEnd(this.viewDate), 'yyyy-MM-dd')
  //     )
  //     .set('api_key', '0ec33936a68018857d727958dca1424f');
  //   this.events$ = this.http
  //     .get('https://api.themoviedb.org/3/discover/movie', {params})
  //     .pipe(
  //       map(({results}: { results: Film[] }) => {
  //         return results.map((film: Film) => {
  //           //console.log('film data :: ', film);
  //           return {
  //             title: film.title,
  //             start: new Date(
  //               film.release_date + getTimezoneOffsetString(this.viewDate)
  //             ),
  //             color: colors.blue,
  //             allDay: true,
  //             meta: {
  //               film,
  //             },
  //           };
  //         });
  //       })
  //     );
  // }

  dayClicked({
               date,
               events,
             }: {
    date: Date;
    events: Array<CalendarEvent<{ film: Film }>>;
  }): void {
    //console.log('select film by day :', date);
    // if (isSameMonth(date, this.viewDate)) {
    if (
      (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
      events.length === 0
    ) {
      this.activeDayIsOpen = false;
    } else {
      this.activeDayIsOpen = true;
      this.viewDate = date;
      this.onDatePicked.emit(date);
      localStorage.setItem('dateclick', ""+date);
      this.cd.detectChanges();

    }
    // }

  }

  eventClicked(event: CalendarEvent<{ film: Film }>): void {
    //console.log('film selected  :', event);
  }

  get currentLang(){
    return this.locale.currentLang;
  }
}
