import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractQuestionComponent, QuestionType} from '../abstract-question';
import {MatInput} from '@angular/material';
import {Question} from '../../../models/question';

@Component({
  selector: 'app-input-question',
  templateUrl: './input-question.component.html',
  styleUrls: ['./input-question.component.scss'],
  providers: [{provide: AbstractQuestionComponent, useExisting: InputQuestionComponent}]
})
export class InputQuestionComponent extends AbstractQuestionComponent implements OnInit {

  @Input() question: Question;
  @ViewChild(MatInput) input: MatInput;

  constructor() {
    super();
  }


  ngOnInit(): void {
  }

  selectedValue() {
    return {
      question_id: this.question.id,
      drupal_reference: this.question.attributes.old_id,
      value: this.input.value,
      type: 'input' as QuestionType,
      is_correct: false,
      note: '',
      score: 0
    };
  }


}
