<div class="list-group seen-sm1" id="list-tab" role="tablist">
  <a class="list-group-item list-group-item-action show bloch-center" [routerLink]="'/profile/my-information'"
     [routerLinkActive]="'active'"
  >
    <img src="/assets/img/icon-user-tab.svg" class="mx-auto" alt="information">
    <div class="mt-1">{{'my information'|translate}}</div>

  </a>

  <a class="dropbtn dropdown list-group-item list-group-item-action"  (click)="myFunction()" [routerLinkActive]="'active'">
    <img src="/assets/img/icon-academic-tab.svg" class="mx-auto" alt="academy">
    <div class="mt-1">{{'courses'|translate}}</div>
       <div class="dropdown-content" id="myDropp">
         <a [routerLink]="'/profile/mesmodules'" class="item">{{'courses'|translate}}</a><hr>
         <a [routerLink]="'/profile/mesmatieres'" class="item">{{'Modules'|translate}}</a><hr>
         <a [routerLink]="'/profile/mescours'" class="item">{{'lessons'|translate}}</a>
       </div>
   </a>

     <!--
    <a class="list-group-item dropdown-menu list-group-item-action dropbutton" [routerLink]="'/profile/mesmodules'" [routerLinkActive]="'active'">
      <img src="/assets/img/icon-academic-tab.svg" class="mx-auto" alt="academy">
      <div class="mt-1">{{'Module'|translate}}</div>
    </a>-->

  <a class="list-group-item list-group-item-action " [routerLink]="'/profile/my-favorites'"
     [routerLinkActive]="'active'"
  >
    <img src="/assets/img/icon-star2-tab.svg" class="mx-auto" alt="star">
    <div class="mt-1">{{'my favorites'|translate}}</div>
  </a>
  <a class="list-group-item list-group-item-action " [routerLink]="'/profile/my-calendar'"
     [routerLinkActive]="'active'"
  >
    <img src="/assets/img/icon-calendar-tab.svg" class="mx-auto" alt="star">
    <div class="mt-1">{{'my calendar'|translate}}</div>
  </a>
  <a class="list-group-item list-group-item-action " [routerLink]="'/profile/my-results'"
     [routerLinkActive]="'active'"
  >
    <img src="/assets/img/icon-testing-tab.svg" class="mx-auto" alt="results">
    <div class="mt-1">{{'my results'|translate}}</div>
  </a>

</div>

<div class="list-group seen-sm" id="list-tab" role="tablist">
  <a class="list-group-item list-group-item-action " [routerLink]="'/profile/mesmodules'"
     [routerLinkActive]="'active'"
  >
    <img src="/assets/img/icon-star2-tab.svg" class="mx-auto" alt="star">
    <div class="mt-1">{{'courses'|translate}}</div>
  </a>
  <a class="list-group-item list-group-item-action " [routerLink]="'/profile/mesmatieres'"
     [routerLinkActive]="'active'"
  >
    <img src="/assets/img/icon-star2-tab.svg" class="mx-auto" alt="star">
    <div class="mt-1">{{'Modules'|translate}}</div>
  </a>
  <a class="list-group-item list-group-item-action " [routerLink]="'/profile/mescours'"
     [routerLinkActive]="'active'"
  >
    <img src="/assets/img/icon-star2-tab.svg" class="mx-auto" alt="star">
    <div class="mt-1">{{'lessons'|translate}}</div>
  </a>
</div>
