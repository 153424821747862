import {Component, OnInit} from '@angular/core';
import {ChatWindowComponent} from '../chat-window/chat-window.component';

@Component({
  selector: 'app-private-chat-window',
  templateUrl: './private-chat-window.component.html',
  styleUrls: ['./private-chat-window.component.scss']
})
export class PrivateChatWindowComponent extends ChatWindowComponent {

  constructor() {
    super();
  }

  protected parseMessage(m) {
    return m;
  }

}
