<div class="container live-comp" *ngIf="live && !live.is_loading ; else loading">
  <div *ngIf="ended ">
    <div class="play">
      <video  *ngFor="let videoUrl of videoUrls" class="library" controls preload="auto" #vid>
        <source src="{{videoUrl}}">
      </video>
<!--
      <i (click)="vid.play()" class="fa fa-play" *ngIf="vid.paused"></i>
-->
    </div>
    <div *ngIf="!videoUrls.length">
      {{'The VOD is still processing... visit later'|translate}}
    </div>
    <div class="row mt-2">
      <div class="col-6"></div>
      <div class="col-6 links">
        <a><img src="/assets/img/025---Live-Recording.svg" class="live-icon" alt="live recording"></a>
        <a *ngIf="!isFavorite" (click)="addFavorite(id)"><img src="/assets/img/empty-star.png" class="button-favorite" alt="add favorite"></a>
        <a *ngIf="isFavorite" (click)="deleteFavorite(id)"><img src="/assets/img/star.svg" class="button-favorite" alt="delete favorite"></a>
      </div>
    </div>

  </div>
  <div *ngIf="startDate && !started && !ended">
    <div class="jumbotron">
      <p>
        <span class="d-block text-center py-3">{{'The meeting will start in'|translate}}</span>
        <span  class="d-block text-center font-weight-bolder py-3"><countdown [config]="{
        leftTime:timeLeft
        }"></countdown></span>
      </p>
    </div>
  </div>
  <div *ngIf="startDate && started && !ended">
<!--
    {{'Loading your meeting'|translate}}
-->
    <iframe   [src]="url" width="100%" height="500px" ></iframe>
  </div>

</div>
<ng-template #loading>
  <app-loading-dialog></app-loading-dialog>
</ng-template>



