import { Component, OnInit } from '@angular/core';
import {Course} from '../../models/course';
import {DocumentCollection} from 'ngx-jsonapi';
import {CoursesService} from '../../services/courses.service';
import {ModulesService} from '../../services/modules.service';
@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {
  public constructor() {

  }
  ngOnInit(): void {

  }

}
