import {Component, OnInit, Input} from '@angular/core';
import {DocumentCollection} from 'ngx-jsonapi';
import {Course} from '../../../models/course';
import {ModulesService} from '../../../services/modules.service';
import {StudentService} from '../../../services/student.service';
import {CoursesService} from '../../../services/courses.service';
import {LanguageService} from 'src/app/services/language.service';
import { Router } from '@angular/router';
import {CourseFilterPipe} from './coursefilter'
  import { from } from 'rxjs';

@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.scss']
})
export class CourseListComponent implements OnInit {
  @Input() isArchived;
  public courses: DocumentCollection<Course>;
  p: number = 1;
  pipeFilter = '';
  pipeFilter1 = '';
  pipeFilter2 ='';
  isItOk:boolean=false;

  anne: any[] = [
    { id: 1, name: 'one' },
    { id: 2, name: '2020' },
    { id: 3, name: '2019' },
    { id: 4, name: '2018' },
    { id: 5, name: '2017' }
  ];

  ok(cond){
    //console.log(cond,this.isItOk);

    this.isItOk=cond
    //console.log(cond,this.isItOk);
  }
  public constructor(
    private coursesService: CoursesService,
    modulesServices: ModulesService,
    studentService: StudentService,
    public languageService: LanguageService,
    private router: Router

  ) {
    modulesServices.register();
    studentService.register();

  }

  ngOnInit() {



    if (this.isArchived === "true") {
      this.coursesService.all({include: ['modules', 'students'], remotefilter: {
        closed: 1
      }}).subscribe(
        courses => {
          //console.log('ahwma les modules ' ,courses);
          if(courses && courses.data && courses.data.length >0) {
          let ressourceCourses = courses;
          let filteredCourses = [];
          if (this.languageService.currentLang.startsWith('en')) {
            filteredCourses = courses.data.filter(c => c.attributes.language === 'en');
          } else if (this.languageService.currentLang.startsWith('fr')) {
            filteredCourses = courses.data.filter(c => c.attributes.language === 'fr');
          } else if (this.languageService.currentLang.startsWith('ar')) {
            filteredCourses = courses.data.filter(c => c.attributes.language === 'ar');
          }
          ressourceCourses['data'] = filteredCourses;
          this.courses = ressourceCourses;
          //console.log("cours", this.courses);

        }},
        error => {
        },
        () => {
        });
      }

      else if(this.isArchived === "false") {
        this.coursesService.all({include: ['modules', 'students'], remotefilter: {
          notclosed: 1
        }}).subscribe(
          courses => {
            if(courses && courses.data && courses.data.length >0) {
            let ressourceCourses = courses;
            let filteredCourses = [];
            if (this.languageService.currentLang.startsWith('fr')) {
              filteredCourses = courses.data.filter(c => c.attributes.language === 'fr');
            } else if (this.languageService.currentLang.startsWith('en')) {
              filteredCourses = courses.data.filter(c => c.attributes.language === 'en');
            } else if (this.languageService.currentLang.startsWith('ar')) {
              filteredCourses = courses.data.filter(c => c.attributes.language === 'ar');
            }
            ressourceCourses['data'] = filteredCourses;
            this.courses = ressourceCourses;
            //console.log("cours", this.courses);

          }},
          error => {
          },
          () => {
          });
      }
  }

}
