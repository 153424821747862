export class Token {
  [x: string]: any;
  constructor(
    // tslint:disable-next-line:variable-name
    public readonly access_token: string,
    // tslint:disable-next-line:variable-name
    public readonly expires_in: number,
    // tslint:disable-next-line:variable-name
    public readonly token_type: string,
    // tslint:disable-next-line:variable-name
    public readonly created_at: number
  ) {
  }


  public get isValid() {
    const now = (new Date()).getTime();
    return !!this.created_at && !!this.expires_in && (now < (this.expires_in * 1000 + this.created_at));
  }
}
