<!--<a-->
<!--  style="cursor: pointer;"-->
<!--  [routerLink]="[index]"-->
<!--  routerLinkActive="active"-->
<!--  class="list-group-item clearfix">-->
<!--  <div class="pull-left">-->
<!--    <h4 class="list-group-item-heading">{{ course.id }}</h4>-->
<!--    <h4 class="list-group-item-text">{{ course.attributes.title }}</h4>-->
<!--    <p class="list-group-item-text">{{ course.attributes.title }}</p>-->
<!--  </div>-->
<!--</a>-->

<!--<div class="col-sm-12 col-md-4">-->
<a class="link-details-course" >
  <div class="card"  (click)="doStuff(course.id)">
    <div class="picture-special">
      <div class="play">
        <img *ngIf="!course.attributes.video" #i [src]="course.attributes.photo" (error)="i.src = '/assets/img/close-up.png'" class="card-img-top" alt="picture course">
        <video *ngIf="course.attributes.video"  [src]="course.attributes.video" autoplay loop [muted]="true" class="card-img-top" alt="picture course" ></video>
      </div>
    </div>

      <div class="card-body">
        <button *ngFor="let item  of [1,2,3,4,5] ; let i = index " class="btn btn-category mb-3 ml-2">
          <div *ngIf ="test(item,course.attributes.grades); else emptygrade" >
            {{ item }}
          </div>
          <ng-template #emptygrade >
           <p style="color: transparent;">A</p>
          </ng-template>
        </button>
        <pre></pre>

      <button class="btn btn-category mb-3">{{course.attributes.formation_name}}</button>
      <h5 class="card-title-course" [line-truncation]="2">{{ course.attributes.title | uppercase}}</h5>
      <p class="card-text-course"
      [line-truncation]="3"
      [innerHTML]="course.attributes.description">
      </p><div class="text-right mt-3">

        <div   >
          <div class="i-more">
            <i class="category-icon icon-certificate-black  "  *ngIf="course.attributes.certificate"></i>
            <i class="category-icon ">
              <div>
                <div class="circle">
                  <p style="font-size: 13px;">
                  {{countModules}}
                  </p>
                </div>
                <p class="label-type">{{'UE'| translate}}</p>
              </div>

            </i>
            <i class="category-icon ">
              <div>
                <div class="circle">
                  <p style="font-size: 13px;">
                  {{countLessons}}
                  </p>
                </div>
                <p class="label-type">{{'Lessons'| translate}}</p>
              </div>
            </i>
          </div>
          <div class="position-bt" *ngIf="course">
            <button class="btn btn-apply mt-1 btn-more" [routerLink]="[index]">{{'MORE'|translate}}</button>
          </div>
        </div>


      </div>
    </div>
  </div>
</a>
