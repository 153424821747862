import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usersfilter'
})
export class UsersfilterPipe implements PipeTransform {

  transform(value: any,searchValue): unknown {

    ////console.log("ahwa les value" , value )
    if (!searchValue) return value;
    return value.filter((v) =>
    v.first_name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
    v.last_name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)

  }

}
