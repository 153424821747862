import { Injectable } from '@angular/core';
import {Service} from 'ngx-jsonapi';
import {McqQuestion} from '../models/mcq-question';
import {FileQuestion} from '../models/file-question';

@Injectable({
  providedIn: 'root'
})
export class FileQuestionsService extends Service<FileQuestion> {
  public resource = FileQuestion;
  public type = 'file-questions';

  constructor() {
    super();
    this.register();
  }
}
