import {Resource, DocumentCollection, DocumentResource} from 'ngx-jsonapi';
import {Lesson} from './lesson';


export class LiveLesson extends Resource {
  public attributes = {
    'meeting_auto_recording': '',
    'meeting_id': '',
    'meeting_duration': '',
    'meeting_join_url': '',
    'meeting_password': '',
    'meeting_start_date': '',
    'meeting_start_url': '',
    'meeting_timezon': ''
  };
  public relationships = {
    lesson: new DocumentResource<Lesson>(),
  };
}
