import { Injectable } from '@angular/core';
import {Autoregister, Service} from 'ngx-jsonapi';
import {Lesson} from '../models/lesson';
import {ModulesService} from './modules.service';
import {PodcastsService} from './podcasts.service';
import {LiveLessonsService} from './live-lessons.service';
import {VideosService} from './videos.service';
import {DocumentsService} from './documents.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
// @Autoregister()
export class LessonsService  extends Service<Lesson> {
  public resource = Lesson;
  public type = 'lessons';


  constructor(
    private httpClient: HttpClient
  ) {
     super();
    this.register();
   }

  addFavorite(data) {
    const url = environment.add_file_favorite;
    return this.httpClient.post(url, data).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  deleteFavorite(data) {
    const url = environment.delete_file_favorite;
    return this.httpClient.post(url, data).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  getFavorites() {
    const url = environment.get_files_favorites;
    return this.httpClient.get(url).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  getLesson(id) {
    const url = environment.base_url + '/api/v1/';
    return this.httpClient.get(url + 'lessons/' + id)
  }

}
