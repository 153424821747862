import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-newsletter-recommendation-popup',
  templateUrl: './newsletter-recommendation-popup.component.html',
  styleUrls: ['./newsletter-recommendation-popup.component.scss']
})
export class NewsletterRecommendationPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<NewsletterRecommendationPopupComponent>
  ) { }

  ngOnInit(): void {

  }

  closeDialog(accept) {
    this.dialogRef.close(accept);
  }

}
