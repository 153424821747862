import {Resource, DocumentCollection, DocumentResource} from 'ngx-jsonapi';
import {Lesson} from './lesson';
import {File} from './file';

export class Video extends Resource {
  public attributes = {
    url : '',
    time_requirement: ''
  };
  public relationships = {
    lesson: new DocumentResource<Lesson>(),
    file: new DocumentResource<File>(),
  };

}
