import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { UsersService } from "./services/users.service";
import { Storage } from "@ionic/storage";
import { TwilioService } from "./services/twilio.service";
import { TranslateService } from "@ngx-translate/core";
import { ExamsService } from "./services/exams.service";
import { TestsService } from "./services/tests.service";
import { CoursesService } from "./services/courses.service";
import { LessonsService } from "./services/lessons.service";
import { GroupWorksService } from "./services/group-works.service";
import { FilesService } from "./services/files.service";
import { MatIconRegistry } from "@angular/material";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { LiveLessonsService } from "./services/live-lessons.service";
import { DocumentsService } from "./services/documents.service";
import { PodcastsService } from "./services/podcasts.service";
import { VideosService } from "./services/videos.service";
import { LinkLessonService } from "./services/link-lesson.service";
import { LanguageService } from "./services/language.service";
import { catchError } from "rxjs/internal/operators/catchError";
import { of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AuthService } from "./services/auth.service";
import { TwilioV2Service } from "./services/twilio-v2.service";
import Swal from "sweetalert2";
import { error } from "protractor";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "online-academy-front";
  channel = null;
  currentUser = null;
  public random;
  public us;
  ran;
  public sid = null;
  public once = 0;
  show: any;
  current_ticket;
  subject = "";
  creating: boolean = false;
  solvedMessage = "Merci pour votre coopération, votre sujet à été résolu!";
  suspendedMessage = "Ce sujet à été marqué suspendu!";
  lastMessage = "";
  conversationEndedForHotline = false;

  constructor(
    private userService: UsersService,
    private storage: Storage,
    private chatService: TwilioV2Service,
    private twilioService: TwilioService,
    private translate: TranslateService,
    private examsService: ExamsService,
    private testsService: TestsService,
    private coursesService: CoursesService,
    private lessonsService: LessonsService,
    private groupWorksService: GroupWorksService,
    private filesService: FilesService,
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private liveLessonsService: LiveLessonsService,
    private documentsService: DocumentsService,
    private podcastsService: PodcastsService,
    private videoService: VideosService,
    private linksService: LinkLessonService,
    private titleService: Title,
    private languageService: LanguageService,
    public authService: AuthService,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    console.log("awel storage", localStorage.getItem("randomId"));

    this.storage.ready().then(console.log);
    translate.setDefaultLang("fr");

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.iconRegistry.addSvgIcon(
      "testing",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/img/testing.svg"
      )
    );
  }

  ngOnInit(): void {
    this.show = false;
    this.random = localStorage.getItem("randomId");
    if (!this.random) {
      this.random = this.uuidv4();
      //document.cookie = "randomId="+this.random
      localStorage.setItem("randomId", this.random);
      console.log("randomoset", this.random);
    }
  }

  ngAfterViewInit(): void {
    console.log("randomoget", this.random);
    this.authService.getCurrentUser().subscribe(
      ({ current_user }) => {
        console.log(current_user);
        this.currentUser = current_user;
        console.log("current user", current_user);
        console.log(this.authService.isAuthenticated());
        this.getHotlineUser(current_user, this.random, false);
        console.log(current_user);
      },
      (error) => {
        console.log("not connected");
        console.log(error);
        this.getHotlineUser(null, this.random, true);
      }
    );
  }

  getHotlineUser(userr, anonym_id, status) {
    console.log(this.currentUser);
    if (status == false) {
      console.log("d5alt lel connecté");
      this.chatService.getHotlineUser(userr.id, anonym_id).subscribe(
        (res) => {
          console.log(res["anonym_id"]);
          localStorage.setItem("randomId", res["anonym_id"]);
          this.getTicket(res["anonym_id"]);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      console.log("d5alt lel non connecté");
      this.chatService.getHotlineUser(null, anonym_id).subscribe(
        (res) => {
          localStorage.setItem("randomId", res["anonym_id"]);
          this.getTicket(res["anonym_id"]);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  async getTicket(anonym_id) {
    this.chatService.getTicket(anonym_id, this.currentUser?.id).subscribe(
      (ans) => {
        console.log(ans["ticket"]);
        this.current_ticket = ans["ticket"];
        this.conversationEndedForHotline = this.current_ticket["type"] == 1;
        if (this.current_ticket != null) {
          console.log(
            "getting into channel ",
            this.current_ticket["unique_name"]
          );

          var user = { user: { anonym_id: localStorage.getItem("randomId") } };
          this.getChannelAnonym(this.current_ticket["unique_name"], user);
        }
      },
      (error) => {
        console.log("error getting user ticket", error);
      }
    );
  }

  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  createTicket() {
    var vide: any = "";
    var ticket;
    this.creating = true;

    if (this.currentUser != null) {
      ticket = {
        user: {
          anonym_id: localStorage.getItem("randomId"),
          user_id: this.currentUser.id,
          isAnonym: false,
          firstName: this.currentUser.first_name,
          lastName: this.currentUser.last_name,
          email: this.currentUser.email,
        },
        subject: this.subject,
      };
      console.log("creation channel with authenticated user: ", ticket);
      this.chatService.createTicket(ticket).subscribe(
        (res) => {
          console.log("ahwa ress", res);
          this.getTicket(localStorage.getItem("randomId"));
        },
        (error) => {

          console.log(error.error.text);
          this.sid = error.error.text;
          var user = { user: { anonym_id: localStorage.getItem("randomId") } };
          this.getChannel(this.sid);
        },
        () => {
          // 'onCompleted' callback.
          // No errors, route to new page here
        }
      );
    } else {
      ticket = {
        user: {
          anonym_id: localStorage.getItem("randomId"),
          user_id: null,
          isAnonym: true,
          firstName: null,
          lastName: null,
          email: null,
        },
        subject: this.subject,
      };
      console.log("creation channel with anonym user: ", ticket);
      this.chatService.createTicket(ticket).subscribe(
        (res) => {
          console.log("ahwa ress", res);
          this.getTicket(localStorage.getItem("randomId"));
        },
        (error) => {

          console.log(error.error.text);
          this.sid = error.error.text;
          var user = { user: { anonym_id: localStorage.getItem("randomId") } };
          this.getChannelAnonym(this.sid, user);
        },
        () => {
          // 'onCompleted' callback.
          // No errors, route to new page here
        }
      );
    }
  }
  openForm() {
    this.creating = false;
    this.subject = "";
    this.show = !this.show;
  }
  closeForm() {
    this.creating = false;
    this.subject = "";
    this.show = !this.show;
  }

  getChannel(sid) {
    console.log("d5alt lel get channel app", sid);
    this.twilioService
      .getChannel(sid)
      .pipe(
        catchError((err) => {
          console.log("erreur get channel", err);
          return err;
        })
      )
      .subscribe((channel) => {
        this.channel = channel;

        console.log("la chaine", this.channel);
        this.fetchLastMessage();
        this.listenForChanges();
        this.creating = false;
        this.subject = "";
      });
  }

  getChannelAnonym(sid, us) {

    this.twilioService
      .getChannelAnonyme(sid, us)
      .pipe(
        catchError((err) => {
          console.log("erreur get channel", err);
          return err;
        })
      )
      .subscribe((channel) => {
        this.channel = channel;
        console.log("la chaine", this.channel);
        this.fetchLastMessage();
        this.listenForChanges();
        this.creating = false;
        this.subject = "";
      });
  }

  fetchLastMessage() {

    if (this.channel?.lastMessage?.index > -1) {

      this.channel
        .getMessages(1, this.channel.lastMessage.index)
        .then((page) => {

          if (page.items.length) {
            this.lastMessage = page.items[0]["body"];
            if (
              this.lastMessage == this.suspendedMessage ||
              this.lastMessage == this.solvedMessage
            ) {
              this.conversationEndedForHotline = true;
            }
          }
        });
    }
  }

  listenForChanges() {
    this.channel.on("messageAdded", (message) => {
      this.lastMessage = message;
      this.lastMessage = message["body"];
      console.log(this.lastMessage);
      if (
        this.lastMessage == this.suspendedMessage ||
        this.lastMessage == this.solvedMessage
      ) {
        this.conversationEndedForHotline = true;
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  restart() {
    this.channel = null;
    this.current_ticket = null;
  }
}
