<div class="bg-block">
  <div class="header-block" >
    <h4>{{'notifications'|translate}}</h4>
    <div class="btn-notif">{{'new'|translate}}
      <span class="num">{{countUnreadNotification }}</span>
    </div>
  </div>
  <ng-template #loading>
    <div class="text-center">
      <i class="fa fa-spin fa-spinner fa-5x"></i> <br />
      {{'Loading notification...'|translate}}
    </div>
  </ng-template>

  <div *ngIf="notifications else loading; ">
    <div class="personnel-info mt-2">
      <ul class="list-group" *ngFor="let notification of notifications | slice:0:12">
        <a (click)="makeAsViewed(notification)" style="cursor: pointer">
        <li class="list-group-item notif-not-read" *ngIf="!notification.read">
          <div class="title-item mb-2"> {{notification.title}}</div>
          <div class="content-item">{{notification.message}}</div>
        </li>
        <li class="list-group-item" *ngIf="notification.read">
          <div class="title-item mb-2">{{notification.title}}</div>
          <div class="content-item">{{notification.message}}</div>
        </li>
        </a>
      </ul>
      <div class="no-notifs">
        <p *ngIf="!notifications || notifications.length === 0">{{'No notifications yet'| translate}}</p>
      </div>
    </div>
    <div class="col col-md-12 center-btn-mb">
      <button class="btn btn-show-notif" routerLink="/profile/my-notifications">{{'show all'|translate}}</button>
    </div>
  </div>
</div>
