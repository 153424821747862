<!-- <div class="row course-teaser-block">
  <div class="desktop-background"></div>
  <h1 class="courses-title">{{'COURSES'|translate}}</h1>
  <app-course-teaser
    #teaser
    (mouseenter)="teaser.active = true"
    (mouseleave)="teaser.active = false"
    class="col-md-6 col-lg-3"
    *ngFor="let course of courseList; let first = first"
    [class.offset]="first"
    [course]="course"
  ></app-course-teaser>
</div> -->

<div class="container-fluid " >
<div class="how-participate">
<h1 class=" course-title head-title title">{{'courses'|translate}}</h1>
</div>
    <div class="row contain"style="background-color:white">
      <div class="col-sm content " *ngFor="let courses of coursesss" >
        <div  class="course-teaser">
          <div class="card">
            <div class="picture-special">
              <div class="play">
                <img *ngIf="!courses.attributes.video" #i [src]="courses.attributes.photo" (error)="i.src = '/assets/img/close-up.png'" class="card-img-top" alt="picture course">
                <video *ngIf="courses.attributes.video"  [src]="courses.attributes.video" autoplay loop [muted]="true" class="card-img-top" alt="picture course" ></video>
              </div>
            </div>
            <div class="card-body">

              <button *ngFor="let item  of [1,2,3,4,5] ; let i = index " class="btn btn-category mb-3 ml-2">
                <div *ngIf ="test(item,courses.attributes.grades); else emptygrade" >
                  {{ item }}
                </div>
                <ng-template #emptygrade >
                 <p style="color: transparent;">A</p>
                </ng-template>
              </button>
              <pre></pre>
              <div class="" *ngIf="courses.attributes.field_annee">
                  <p  >{{courses.attributes.field_annee}}</p>
              </div>
              <h5 class="card-body-title" [line-truncation]="2">{{courses.attributes.title}}</h5>

              <div class="row mt-3 btn-more"  >
                <div class="col col-9 display-icon">
                  <i class="category-icon icon-certificate-black" *ngIf="courses.attributes.certificate"></i>
                  <i class="category-icon icon-five-modules"></i>
                  <span class="module-number"><span>{{courses.length}}</span> {{'courses'|translate}}</span>
                </div>
                <div class="col col-3 " *ngIf="coursesss">
                  <button class="btn btn-apply mt-1 " (click)="navigate(courses.id)" >{{'More'|translate}}</button>
                </div>
              </div>
            </div>
            <div class="line-card-bottom"></div>
          </div>
          <div class="line-cards-bottom"></div>
        </div>
      </div>
    </div>
  </div>
