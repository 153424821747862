import {Injectable} from '@angular/core';
import {BehaviorSubject, fromEvent, merge, of} from 'rxjs';
import Client from 'twilio-chat';
import {AuthService} from './auth.service';
import {filter, map, switchMap, take, tap, toArray} from 'rxjs/operators';
import {fromPromise} from 'rxjs/internal-compatibility';
import {Storage} from '@ionic/storage';
import {environment} from '../../environments/environment';
import {TwilioToken} from '../models/twilio-token';
import {HttpClient} from '@angular/common/http';
import {Channel} from 'twilio-chat/lib/channel';
import {User} from '../models/user';

declare var Twilio: any;

@Injectable({
  providedIn: 'root'
})
export class TwilioV2Service {
  static readonly CHAT_TOKEN_KEY = 'chat-token';
  static readonly GENERAL_CHANNEL_UNIQUE_NAME = 'general';
  static readonly GENERAL_CHANNEL_NAME = 'General Channel';
  public Twilio = Twilio;
  //public users = Map()

  private readonly client$: BehaviorSubject<Client>;
  private readonly channels$: BehaviorSubject<Channel[]>;
  private updateChannels: BehaviorSubject<boolean>;

  constructor(
    private storage: Storage,
    private authService: AuthService,
    private httpClient: HttpClient
  ) {

    this.client$ = new BehaviorSubject<Client>(null);
    this.channels$ = new BehaviorSubject<Channel[]>([]);
    this.updateChannels = new BehaviorSubject<boolean>(true);

    this.fetchClient();
    this.getChannels();
    this.listenToChanges();
  }

  private _updateChannels() {
    return (() => this.updateChannels.next(true))();
  }

  private listenToChanges() {
    this.client$
      .subscribe(client => {
        /*fromEvent(client, 'channelRemoved').subscribe(this._updateChannels);
        fromEvent(client, 'channelInvited').subscribe(this._updateChannels);
        fromEvent(client, 'channelAdded').subscribe(this._updateChannels);
        fromEvent(client, 'channelUpdated').subscribe(this._updateChannels);
        fromEvent(client, 'channelJoined').subscribe(this._updateChannels);*/
      });
  }

  private getToken() {
    return fromPromise(this.storage.get(TwilioV2Service.CHAT_TOKEN_KEY)).pipe(
      switchMap((token: TwilioToken) => {
        if (token?.isValid) {
          return of(token);
        }
        return this.fetchFreshToken().pipe(tap(t => this.saveToken(t)));
      })
    );
  }

  private fetchFreshToken() {
    return this.httpClient.get(environment.authenticated.chat_auth).pipe(map((res: any) => {
      return new TwilioToken(res.token, res.identity, (new Date().getTime()));
    }));
  }

  private fetchClient() {
    this.getToken().pipe(
      switchMap(
        token => {
          return fromPromise(this.Twilio.Chat.Client.create(token.token));
        }
      )
    ).subscribe((client: Client) => {
      this.client$.next(client);
    });

  }

  private saveToken(token: TwilioToken) {
    return fromPromise(this.storage.set(TwilioV2Service.CHAT_TOKEN_KEY, token));
  }

  public getChannels() {
    this.client$
      .pipe(
        switchMap(client => {
          if (client) {
            return this.updateChannels.pipe(
              switchMap(res => {
                return fromPromise(client.getSubscribedChannels())
                  .pipe(
                    map(paginator => paginator.items)
                  );
              })
            );
          }
          return of([] as Channel[]);
        })
      ).subscribe(list => {
      this.channels$.next(this.sortChannels(list.filter(l => l.uniqueName.startsWith('custom'))));
    });
  }

  public get channels() {
    return this.channels$;
  }

  public get client() {
    return this.client$;
  }

  public getChannel(sid) {
    return this.client$
      .pipe(
        filter(client => !!client),
        switchMap(client => {
          return fromPromise(client.getChannelByUniqueName(sid));
        }),
      );
  }

  public getUser(identity) {
    return this.client$
      .pipe(
        filter(client => !!client),
        take(1),
        switchMap(client => {
          return fromPromise(client.getUser(identity));
        })
      );
  }

  sortChannels(items: Channel[] = []) {
    return items.sort((a, b) => {
      return a?.friendlyName?.localeCompare(b?.friendlyName);
    });
  }

  createChannelApi(user) {
    return this.httpClient.get(environment.create_chat.replace('{user}', user));
  }

  createChannel(data) {
    return this.httpClient.post(environment.create_channel, data,).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  createOrUpdateHotlineUser(data) {
    return this.httpClient.post(environment.createOrUpdate_Hotline_User, data,).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  createTicket(data) {
    return this.httpClient.post(environment.create_Ticket, data,).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }

  verifCh(channel)
  { console.log(environment.getChannel.replace('{friendlyname}', channel));
    return this.httpClient.get(environment.getChannel.replace('{friendlyname}', channel));

  }

  getMembers(channel, f = false) {
    let obs = fromPromise(channel.getMembers());
    console.log('channel', channel.members);
    if (f) {
      obs = obs.pipe(
        switchMap((list: any[]) => {
          return this.authService.currentUser
            .pipe(
              map((user: User) => {
                return list.filter(member => {
                  return member.identity !== user.id.toString();
                });
              }));
        }));

    }
    obs = obs.pipe(
      switchMap((list: any[]) => {
        const l = list.map(m => {
          return this.getUser(m.identity);
        });
        return merge(...l).pipe(toArray());
      })
    );


    return obs;
  }


  public getPrivateChannel(id) {
    return this.authService
      .getCurrentUser()
      .pipe(
        filter(({current_user}) => !!current_user),
        switchMap(({current_user}) => {
          const uniqueName = 'private.' + ([current_user.id * 1, id * 1].sort().join('.'));
          console.log('fetching', uniqueName, current_user);
          return this.getChannel(uniqueName);
        })
      );
  }

  public getHotlineUser(user_id,anonym_id){
    return this.httpClient.get(environment.base_url+'/api/hotline/getHotlineUser/'+user_id+'/'+anonym_id);
  }
  
  public getTicket(anonym_id,user_id){
    return this.httpClient.get(environment.base_url+'/api/hotline/getTicketHotlineUser/'+anonym_id+'/'+user_id);
  }
}
