<div class="content-result">
  <div class="result-back">
    <img src="/assets/img/result-test.svg" alt="result">
  </div>
  <div class="message-result">
    <h2 class="my-3"> {{ participation }}</h2>
    <h3 *ngIf="auto">{{'Evaluation Time Has expired'|translate}}</h3>
    <div class="plus-details my-2">
      <h3 class="text-center my-2">{{ mention }}</h3>
      <p class="text-message my-2">{{ detailsmention | lowercase }}</p>
      <div class="note-result my-3" [class.success]="success" *ngIf="note !== null">
        <p>{{'Your Results'|translate}}</p>
        <p class="note">{{note}}/20</p>
      </div>
      <!-- <button class="btn btn-repeat my-3" *ngIf="link" [routerLink]="link">{{'Back to module'|translate}}</button> -->
      <button class="btn btn-details-result my-3" [routerLink]="'/profile/my-results'">{{'My Results'|translate}}</button>
      <button *ngIf="repeat && !canRepeat" class="btn btn-details-result my-3"
      >{{'Repeat After:'|translate}}  {{repeatAt|date:'dd/MM/yyyy HH:mm:ss'}}</button>
      <button *ngIf="repeat && canRepeat" (click)="repeatExam.emit($event)" class="btn btn-details-result my-3"
      >{{'Repeat Now'|translate}}</button>
    </div>
  </div>
</div>


