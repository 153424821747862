import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParticipationService {

  constructor(
    private httpClient: HttpClient
  ) {
  }


  getParticipation(course) {
    const url = environment.participation.replace('{course}', course);
    return this.httpClient.get(url);
  }

  getParticipations() {
    const url = environment.participation_list_user;
    return this.httpClient.get(url);
  }

  participate(course) {
    const url = environment.participate_in_course.replace('{course}', course);
    return this.httpClient.post(url, {});
  }

  cancel(course) {
    const url = environment.cancel_participate_in_course.replace('{course}', course);
    return this.httpClient.post(url, {});
  }


  getParticipants(course) {
    const url = environment.participation_list.replace('{course}', course);
    return this.httpClient.get(url);
  }

  getModuleStatus(module) {
    const url = environment.participation_module_status.replace('{module}', module);
    return this.httpClient.get(url);
  }

}
