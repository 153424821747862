import {Resource} from "ngx-jsonapi";

export class Page extends Resource {

    public attributes = {
        title: '',
        language: '',
        body: Object,
        banner: '',
        status: 0,
        promote: 0
      };
}
