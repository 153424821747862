<div class="block-professors block" *ngIf="professor">
  <div class="slide-element">
    <img class="rounded-picture" #i (error)="i.src='/assets/img/prof-pic.png'" src="{{professor.attributes.photo}}">
    <h1 class="prof-role description">{{'Professor'|translate}}</h1>
    <div class="prof-info">
      {{professor.attributes.first_name}}
      {{professor.attributes.last_name}} </div>
    <div class="prof-description description" [line-truncation]="3" [innerHTML]="professor.attributes.description">
    </div>
   <!-- <button class="chat my-3">{{'chat'|translate}}</button>-->
  </div>
</div>
