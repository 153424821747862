import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Token} from '../../models/token';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {LanguageService} from '../../services/language.service';
import { ChangeDetectorRef } from '@angular/core';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  showMenu = false;
  connected = false;
  current_user = null;
  rtl = false;
  typeBlock = 'verification-email';

  constructor(
    private authService: AuthService,
    private router: Router,
    public languageService: LanguageService,
   // private cd: ChangeDetectorRef,
  ) {
   // this.changeColor();
    if(this.router && this.router.url.startsWith('/courses/')) {
      this.typeBlock = 'application-course';
    }

  }

  ngOnInit() {
    ////console.log("ahwa l url" ,this.router.url);

    this.authService.token$.subscribe((res: Token) => {
      this.connected = !!(res?.isValid);
    });
    this.authService.getCurrentUser().subscribe(({current_user}) => {
      this.current_user = current_user ? current_user : null;
    });


  }

  // changeColor()
  // {
  //   if (this.router.url=="/modules")
  //   {
  //     document.getElementById("color").style.color = "#1da9c2";
  //     this.cd.detectChanges;
  //   }
  // }

  toggle() {
    this.showMenu = !this.showMenu;
  }

  get photo() {
    let avatarUrl = null;
    if (this.current_user && this.current_user.photo) {
      avatarUrl = environment.avatars + '/' + this.current_user.photo;
    }
    return avatarUrl;
  }

  logout() {
    this.authService.logout().subscribe(console.log);
    //this.deleteCookies();
    ////console.log("nookok" , document.cookie)
    localStorage.setItem('randomId', "");
    location.reload();
    this.router.navigate(['/secure/login']);
  }

  change(lang) {
    this.languageService.changeLanguage(lang);
  }
  deleteCookies() {
    var allCookies = document.cookie.split(';');
    for (var i = 0; i < allCookies.length; i++)
        document.cookie = allCookies[i] + "=;expires=" +
        new Date(0).toUTCString();
  }
  navigate()
  {
    //his.router.isRouteActive(this.router.generate(instruction));

    this.router.navigate(['/profile/chat/'+this.current_user.id]);
  }
}
