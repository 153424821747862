
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import {CalendarUtilsModule} from './calendar-utils/CalendarUtilsModule';
import {CalendarComponent} from "./calendar.component";
import {TranslateModule} from "@ngx-translate/core";
@NgModule({
  declarations: [
    CalendarComponent,
  ],
  imports: [
    CommonModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    CalendarUtilsModule,
    TranslateModule,
  ],
  providers: [],
  exports: [
    CalendarComponent
  ]
})
export class CalendarModele { }
