import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {ZoomService} from '../../services/zoom.service';
import {environment} from '../../../environments/environment';
import {Location} from '@angular/common';


@Component({
  selector: 'app-zoom-meeting',
  templateUrl: './zoom-meeting.component.html',
  styleUrls: ['./zoom-meeting.component.scss']
})
export class ZoomMeetingComponent implements OnInit, AfterViewInit {

  @Input() meetingId;
  @Input() password;

  ZoomMtg: any;

  constructor(
    private  zoomService: ZoomService,
    private location: Location
  ) {
    // @ts-ignore
    this.ZoomMtg = (require('@zoomus/websdk')).ZoomMtg;
    this.ZoomMtg.preLoadWasm();
    this.ZoomMtg.prepareJssdk();
    //console.log('z', this.ZoomMtg);
    //console.log('z', location);
  }

  ngOnInit(): void {
  }


  ngAfterViewInit(): void {
    this.initMeeting();

  }

  initMeeting(): void {
    const urlParts = this.location.path().split('/');
    urlParts.pop();
    urlParts.pop();
    const url = urlParts.join('/');
    this.zoomService
      .generateSignature(this.meetingId)
      .subscribe(res => {

        this.ZoomMtg.init({
          leaveUrl: url,
          debug: true,
          isSupportAV: true,
          success: () => {
            // //console.log('initialized', arguments);
            this.join(res);
          }, error: (error) => {
            //console.log(error);
          }
        });
      });
  }


  join(res) {
    this.ZoomMtg.join({
      signature: res.signature,
      meetingNumber: this.meetingId,
      userName: res.user.email,
      apiKey: environment.zoom.key,
      userEmail: res.user.first_name + res.user.last_name + res.user.id + '@candidfoundation.org',
      passWord: this.password,
      success: (success) => {
        //console.log(success);
      },
      error: (error) => {
        //console.log(error);
      }
    });

  }


}
