
<div class="row">
<swiper fxFlex="auto" [config]="config">
  <div *ngFor="let card of cards; let index = index" class="swiper-slide">
<!--    <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill class="mx-2">-->
      {{card.title}}
    <img src="{{card.img}}">
<!--    </div>-->
  </div>
  <div class="swiper-pagination"></div>
</swiper>
</div>

