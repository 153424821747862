import {Component, Input, OnInit} from '@angular/core';
import {Professor} from '../../models/professor';

@Component({
  selector: 'app-professor-teaser',
  templateUrl: './professor-teaser.component.html',
  styleUrls: ['./professor-teaser.component.scss']
})
export class ProfessorTeaserComponent implements OnInit {

  @Input() professor: Professor = null;

  constructor() {
  }

  ngOnInit(): void {
  }

}
