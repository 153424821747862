import {Component, Input, OnInit} from '@angular/core';
import {LinkLesson} from '../../models/link-lesson';
import {LinkLessonService} from '../../services/link-lesson.service';
import {FilesService} from '../../services/files.service';
import {File} from '../../models/file';


@Component({
  selector: 'app-link-detail',
  templateUrl: './link-detail.component.html',
  styleUrls: ['./link-detail.component.scss']
})
export class LinkDetailComponent implements OnInit {
  @Input() index;
  link: LinkLesson;

  constructor(private documentsService: LinkLessonService, filesService: FilesService) {
    filesService.register();
  }

  ngOnInit(): void {
    this.documentsService.get(this.index, {}).subscribe(
      document => {
        //console.log('links', this.link);
        this.link = document;
      },
      () => {
      },
      () => {
        //console.log('document lesson ::', this.link.relationships.files.data);
      }
    );


  }

  get files() {
    return this.link?.attributes.urls ?? [];
  }


  favorite(file: File) {

  }


  navigate(url) {
    window.open(url, '_blank');
  }
}
