import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogModuleComponent} from '../modules/dialog-module/dialog-module.component';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-not-authenticated-dialog',
  templateUrl: './not-authenticated-dialog.component.html',
  styleUrls: ['./not-authenticated-dialog.component.scss']
})
export class NotAuthenticatedDialogComponent implements OnInit {

  message = marker('To continue, please log in or register');

  constructor(
    public dialogRef: MatDialogRef<DialogModuleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data?.message) {
      this.message = data.message;
    }
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

}
