import {Component, Input, OnInit} from '@angular/core';
import {PodcastsService} from '../../../services/podcasts.service';
import {FilesService} from '../../../services/files.service';
import {Podcast} from '../../../models/podcast';
import {ToastrService} from 'ngx-toastr';
import {LessonsService} from 'src/app/services/lessons.service';

@Component({
  selector: 'app-podcast-detail',
  templateUrl: './podcast-detail.component.html',
  styleUrls: ['./podcast-detail.component.scss']
})
export class PodcastDetailComponent implements OnInit {
  @Input('index')
  index;
  podcast: Podcast;

  constructor(
    private podcastsService: PodcastsService,
    filesService: FilesService,
    private lessonsService: LessonsService,
    private toastr: ToastrService
  ) {
    filesService.register();
  }

  ngOnInit(): void {
    this.getPodcast();
  }

  getPodcast() {
    this.podcastsService.get(this.index, {include: ['file']}).subscribe(
      podcast => (this.podcast = podcast)
    );
  }

  get url() {
    return this.podcast?.relationships.file.data?.attributes.url;
  }

  get id() {
    return this.podcast?.relationships.file.data?.id;
  }

  get title() {
    return this.podcast?.relationships.file.data?.attributes.name;
  }

  get isFavorite() {
    return this.podcast?.relationships.file.data?.attributes.isFavorite;
  }

  addFavorite(id) {
    this.lessonsService.addFavorite({file_id: id})
      .subscribe((res: any) => {
          if (res) {
            this.getPodcast();
            this.toastr.success('Podcast Favorite successfully added');
          }
        },
        err => {
          this.toastr.error('Error while adding podcast favorite');
          //console.log(err);
        });
  }

  deleteFavorite(id) {
    this.lessonsService.deleteFavorite({file_id: id})
      .subscribe((res: any) => {
          if (res) {
            this.getPodcast();
            this.toastr.success('Podcast Favorite successfully deleted');
          }
        },
        err => {
          this.toastr.error('Error while deleting Podcast favorite');
          //console.log(err);
        });
  }


  getTime(a) {
    return (a.currentTime * 100 / a.duration) + '%'
  }
}
