import {Autoregister, Service, Resource, DocumentCollection, DocumentResource} from 'ngx-jsonapi';
import {Module} from './module';
import { Student} from './student';

export class Course extends Resource {
  public attributes = {
    title: '',
    description: '',
    difficulty: '',
    max_participants: '',
    certificate: '',
    category: '',
    goals: '',
    prerequisites: '',
    application_start_date: '',
    application_end_date: '',
    course_start_date: '',
    course_end_date: '',
    status: 0,
    grades: '',
    benefits: '',
    hasParticipated: '',
    photo: '',
    video: '',
    language: '',
    formation_id: '' ,
    formation_name : '',
    closed: ''
  };
  public relationships = {
    modules: new DocumentCollection<Module>(),
    students: new DocumentCollection<Student>()
  };
}

