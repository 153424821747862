import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Storage} from '@ionic/storage';
import {v1 as uuidv1} from 'uuid';
import {environment} from '../../environments/environment';
import {fromPromise} from 'rxjs/internal-compatibility';
import {switchMap} from 'rxjs/operators';
import {LanguageService} from "../services/language.service";

@Injectable()
export class BrowserInterceptor implements HttpInterceptor {

  private static readonly BROWSER_ID_KEY = 'browser';
  browserId = null;


  constructor(private storage: Storage) {

  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let lang = localStorage.getItem('lang') as 'fr' | 'en' | 'ar' ?? 'fr';
    return this.getBrowserId().pipe(
      switchMap(code => {
        request = request.clone({
          setHeaders: {
            [environment.BROWSER_ID_HEADER]: `${code}`,
            'X-LOCALE': lang ,
          }
        });
        return next.handle(request);
      })
    );
  }


  getBrowserId(): Observable<string> {
    return fromPromise(this.storage.ready())
      .pipe(
        switchMap(readyResult => {
          return fromPromise(this.storage.get(BrowserInterceptor.BROWSER_ID_KEY)
            .then((id: string) => {
              let code = '';
              if (!id) {
                code = uuidv1();
                return code;
              }
              return id;
            }));
        }),
        switchMap((code) => {
          if (!code) {
            return of(code);
          }
          return fromPromise(this.storage.set(BrowserInterceptor.BROWSER_ID_KEY, code).then((res) => {
            return code;
          }));
        })
      );
  }

}
