import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {McqQuestion} from '../../../models/mcq-question';
import {MatRadioGroup} from '@angular/material';
import {AbstractQuestionComponent, QuestionType} from '../abstract-question';


@Component({
  selector: 'app-multichoice-question',
  templateUrl: './multichoice-question.component.html',
  styleUrls: ['./multichoice-question.component.scss'],
  providers: [{provide: AbstractQuestionComponent, useExisting: MultichoiceQuestionComponent}]

})
export class MultichoiceQuestionComponent extends AbstractQuestionComponent implements OnInit {


  @Input() question: McqQuestion = null;

  @ViewChild(MatRadioGroup) radio: MatRadioGroup;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  get answers() {
    if (!this.question) {
      return [];
    }

    return Object.values(this.question.attributes.answers);
  }


  public selectedValue() {

    if (!this.radio) {
      return {
        question_id: this.question.id,
        drupal_reference: this.question.attributes.old_id,
        value: null,
        type: 'mcq' as QuestionType,
        is_correct: false,
        note: '',
        score: 0
      };
    }

    return {
      question_id: this.question.id,
      drupal_reference: this.question.attributes.old_id,
      value: this.radio.value,
      type: 'mcq' as QuestionType,
      is_correct: false,
      note: '',
      score: 0
    };

  }
}
