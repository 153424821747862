import {DocumentCollection, DocumentResource, Resource} from 'ngx-jsonapi';
import {Module} from './module';
import {File} from './file';
import {Question} from './question';
import {McqQuestion} from './mcq-question';
import {FileQuestion} from './file-question';

export class Exam extends Resource {
  public attributes = {
    title : '',
    description : '',
    start_date : '',
    end_date : '',
    typeExam : '',
    rules : '',
    duration : 0
  };
  public relationships = {
    module: new DocumentResource<Module>(),
    files : new DocumentCollection<File>(),
    fileQuestions : new DocumentCollection<FileQuestion>(),
    questions : new DocumentCollection<Question>(),
    mcqQuestions : new DocumentCollection<McqQuestion>(),
  };
}
