import {DocumentResource, Resource} from 'ngx-jsonapi';

import {Test} from './test';
import {Exam} from './exam';
import {GroupWork} from "./group-work";

export class McqQuestion  extends Resource {
  public attributes = {
    question : '',
    correct_answer : '',
    answers : '',
    old_id : ''
  };
  public relationships = {
    test: new DocumentResource<Test>(),
    exam: new DocumentResource<Exam>(),
    groupWork: new DocumentResource<GroupWork>(),
  };
}
