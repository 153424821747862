import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-channel-window',
  templateUrl: './channel-window.component.html',
  styleUrls: ['./channel-window.component.scss']
})
export class ChannelWindowComponent implements OnInit {

  selected = null;
  constructor() { }

  ngOnInit(): void {
  }

  itemClicked(channel){
    this.selected = channel;
  }

}
