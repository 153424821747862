<app-calendar [events]="events1"></app-calendar>
<div class="col-md-4 description-section" >
  <div class="side-description my-3">
    <h1 class="title">DETAILS</h1>
    <div *ngFor="let detail of lessons ;let i = index"   >
      <div *ngIf="(detail.attributes.date_begin | date:'yMMdd') == (click | date:'yMMdd')">

          <p class="title1"  >
            <!--[innerHtml]="pageCalendar && pageCalendar.attributes && pageCalendar.attributes.body ? pageCalendar.attributes.body['value'] : ''"-->
            {{'Lesson'|translate}} {{i}}  : {{  detail.attributes.title }}
          </p>
          <p class="module-description">
            {{'Module'|translate}} :  {{ m[i] }}
          </p>
          <p class="side-description1 ">{{'Start Date'|translate}} : {{ detail.attributes.date_begin | date:'dd/MM/yyyy HH:mm' }}</p>
          <p class="side-description1 ">
            {{'End Date'|translate}} : {{ detail.attributes.date_end | date:'dd/MM/yyyy HH:mm'}}
          </p>
          <p class="side-description1 ">
            {{'Salle'|translate}} : {{ detail.attributes.room }}
          </p>

            <!--
            <mat-chip-list>
              <mat-chip    color="blue" >
                <p class="side-description1 ">
                  {{ detail.attributes.date_begin | date:'yyyy-MM-dd' }}
                </p>
              </mat-chip>
              <mat-chip    color="red" >
                <p class="side-description1 ">
                  {{  detail.attributes.date_end | date:'yyyy-MM-dd'}}
                </p>
              </mat-chip>
          </mat-chip-list>
          -->
          <hr>
      </div>

    </div>
  </div>
</div>
